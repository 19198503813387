import { addressManager } from '../../services/AddressManager'
import { saveAddressApi } from '../../api/address.api'
import { analytics } from '../../services/Analytics/Analytics'

export default async function syncAddress(api, currentAccount) {
  let localAddress = addressManager.getCurrentNormalizedAddress()
  if (currentAccount.address?.country && !localAddress) {
    analytics.track('Restored address')
    addressManager.setAddressFromServer(currentAccount.address)
  }

  addressManager.removeAddressIfNotSupported()

  localAddress = addressManager.getCurrentNormalizedAddress()

  if (
    localAddress &&
    (!currentAccount.address ||
      JSON.stringify(
        currentAccount.address,
        Object.keys(localAddress).sort()
      ) !== JSON.stringify(localAddress, Object.keys(localAddress).sort()))
  ) {
    await api.call(saveAddressApi(localAddress))
  }
}
