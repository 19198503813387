import { API_METHODS, createApiFunction } from './apiProvider'

const PATH = 'v1/auth'

export const getCurrentAccountApi = (pricingCountry) =>
  createApiFunction(
    API_METHODS.GET,
    `${PATH}/currentAccount?pricingCountry=${pricingCountry}`,
    null,
    true
  )
export const activateAccountApi = () =>
  createApiFunction(API_METHODS.POST, `${PATH}/activateAccount`, null, true)
export const updateAccountApi = (account) =>
  createApiFunction(API_METHODS.PUT, 'accounts', account, true)
export const passwordlessStart = (email) =>
  createApiFunction(API_METHODS.POST, `${PATH}/passwordless/start`, email)
export const passwordlessLogin = ({ email, code }) =>
  createApiFunction(API_METHODS.POST, `${PATH}/passwordless/token`, {
    email,
    code,
  })
