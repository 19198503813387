import StorageManager from './StorageManager'
import { getPricing } from './PricingUtils'
import {
  COUNTRY_TO_CENTERPIECE_PRICE,
  CURRENCIES,
  usdToCurrency,
} from '../config/pricing'
import { countryManager } from './CountryManager'
import isNumber from 'lodash/isNumber'
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'
import {
  BookSize,
  MATERIAL_TYPES,
  TILE_SIZES,
  hashTilePricingProduct,
} from '@mixtiles/web-backend-shared'
import { MIXED_SIZES } from '../pages/PhotoStyler/TileDesignerConsts'
import { logger } from './logger'
import { translateManager as t } from './TranslateManager'
import { EURO_COUNTRIES } from '../config/countries-data'
import memoize from 'lodash/memoize'
import { CANVAS_PRICING } from './canvasPricing'
import { SELECTED_SIZE_KEY } from '../hooks/tileStyling/utils'

export const SIZES_PRICING_TYPE = 'WEB_SIZES_FLAT'

const _mixtilesClassicSupportedSizes = [
  TILE_SIZES.SQUARE_4X4,
  TILE_SIZES.SQUARE_8X8,
  TILE_SIZES.RECTANGLE_8X11,
  TILE_SIZES.RECTANGLE_11X8,
  TILE_SIZES.RECTANGLE_12X16,
  TILE_SIZES.RECTANGLE_16X12,
  TILE_SIZES.RECTANGLE_20X27,
  TILE_SIZES.RECTANGLE_27X20,
  TILE_SIZES.RECTANGLE_27X36,
  TILE_SIZES.RECTANGLE_36X27,
  TILE_SIZES.RECTANGLE_22X44,
  TILE_SIZES.RECTANGLE_44X22,
  TILE_SIZES.SQUARE_12X12,
  TILE_SIZES.SQUARE_20X20,
  TILE_SIZES.RECTANGLE_25X8,
  TILE_SIZES.RECTANGLE_6X8,
  TILE_SIZES.RECTANGLE_8X6,
  TILE_SIZES.RECTANGLE_16X20,
  TILE_SIZES.RECTANGLE_20X16,
  TILE_SIZES.RECTANGLE_22X44,
  TILE_SIZES.RECTANGLE_44X22,
]

export const SIZES_SHORT_NAMES = mapValues(TILE_SIZES, getShortSizeName)

// sub-map of supported sizes types for Mixtiles classic
export const CLASSIC_SIZE_TYPES = pickBy(TILE_SIZES, (sizeTypeValue) =>
  _mixtilesClassicSupportedSizes.includes(sizeTypeValue)
)

const convertTilePrices = (tilePrices, conversionFunc) => {
  const convertedPrices = {}
  for (const [key, value] of Object.entries(tilePrices)) {
    convertedPrices[key] = {
      regularTilePrice: conversionFunc(value.regularTilePrice),
    }
  }

  return convertedPrices
}

const pricingKey = (frameSize, materialType) =>
  hashTilePricingProduct({ frameSize, materialType })

const SIZES_PRICING = memoize(() => {
  const canvasPricing = CANVAS_PRICING()

  const usdTilePrices = {
    [pricingKey(TILE_SIZES.SQUARE_4X4)]: { regularTilePrice: 6 },
    [pricingKey(TILE_SIZES.SQUARE_8X8)]: { regularTilePrice: 15 },
    [pricingKey(TILE_SIZES.RECTANGLE_11X8)]: { regularTilePrice: 23 },
    [pricingKey(TILE_SIZES.RECTANGLE_8X11)]: { regularTilePrice: 23 },
    [pricingKey(TILE_SIZES.RECTANGLE_12X16)]: { regularTilePrice: 39 },
    [pricingKey(TILE_SIZES.RECTANGLE_16X12)]: { regularTilePrice: 39 },
    [pricingKey(TILE_SIZES.RECTANGLE_27X20)]: { regularTilePrice: 99 },
    [pricingKey(TILE_SIZES.RECTANGLE_20X27)]: { regularTilePrice: 99 },
    [pricingKey(TILE_SIZES.RECTANGLE_27X36)]: { regularTilePrice: 159 },
    [pricingKey(TILE_SIZES.RECTANGLE_36X27)]: { regularTilePrice: 159 },
    [pricingKey(TILE_SIZES.RECTANGLE_22X44)]: { regularTilePrice: 159 },
    [pricingKey(TILE_SIZES.RECTANGLE_44X22)]: { regularTilePrice: 159 },
    [pricingKey(TILE_SIZES.SQUARE_12X12)]: { regularTilePrice: 35 },
    [pricingKey(TILE_SIZES.SQUARE_20X20)]: { regularTilePrice: 95 },
    [pricingKey(TILE_SIZES.RECTANGLE_25X8)]: { regularTilePrice: 35 },
    [pricingKey(TILE_SIZES.SQUARE_30X30, MATERIAL_TYPES.STICKER)]: {
      regularTilePrice: 59,
    },
    [pricingKey(TILE_SIZES.SQUARE_40X40, MATERIAL_TYPES.STICKER)]: {
      regularTilePrice: 99,
    },
    ...canvasPricing,
  }

  return {
    us: {
      currency: CURRENCIES.USD,
      tilePrices: usdTilePrices,
    },
    ca: {
      currency: CURRENCIES.CAD,
      tilePrices: {
        ...convertTilePrices(usdTilePrices, (usd) =>
          usdToCurrency(usd, CURRENCIES.CAD)
        ),
        [pricingKey(TILE_SIZES.SQUARE_8X8)]: { regularTilePrice: 19 },
      },
    },
    gb: {
      currency: CURRENCIES.GBP,
      tilePrices: {
        ...convertTilePrices(usdTilePrices, (usd) =>
          usdToCurrency(usd, CURRENCIES.GBP)
        ),
        [pricingKey(TILE_SIZES.SQUARE_8X8)]: { regularTilePrice: 15 },
        [pricingKey(TILE_SIZES.RECTANGLE_25X8)]: {
          regularTilePrice: COUNTRY_TO_CENTERPIECE_PRICE.gb,
        },
      },
    },
    il: {
      currency: CURRENCIES.ILS,
      tilePrices: {
        ...convertTilePrices(usdTilePrices, (usd) =>
          usdToCurrency(usd, CURRENCIES.ILS)
        ),
        [TILE_SIZES.SQUARE_8X8]: { regularTilePrice: 49 },
        [TILE_SIZES.RECTANGLE_25X8]: {
          regularTilePrice: COUNTRY_TO_CENTERPIECE_PRICE.il,
        },
      },
    },
    ...Object.fromEntries(
      EURO_COUNTRIES.map((code) => [
        code.toLowerCase(),
        {
          currency: CURRENCIES.EUR,
          tilePrices: {
            ...convertTilePrices(usdTilePrices, (usd) =>
              usdToCurrency(usd, CURRENCIES.EUR)
            ),
            [pricingKey(TILE_SIZES.SQUARE_8X8)]: { regularTilePrice: 15 },
            [pricingKey(TILE_SIZES.RECTANGLE_25X8)]: {
              regularTilePrice: COUNTRY_TO_CENTERPIECE_PRICE.de,
            },
          },
        },
      ])
    ),
  }
})

export function isSizesPricingCountry(country) {
  return country.toLowerCase() in SIZES_PRICING()
}

export function getSizesPricing() {
  const country = countryManager.getPricingCountry().toLowerCase()

  if (isSizesPricingCountry(country)) {
    return SIZES_PRICING()[country].tilePrices
  }

  const currency = getPricing().currency

  return {
    ...convertTilePrices(SIZES_PRICING().us.tilePrices, (usd) =>
      usdToCurrency(usd, currency)
    ),
  }
}

export function getSizesCurrency() {
  const country = countryManager.getPricingCountry().toLowerCase()
  if (isSizesPricingCountry(country)) {
    return SIZES_PRICING()[country].currency
  }

  return getPricing().currency
}

export function getSizesData() {
  return {
    [TILE_SIZES.SQUARE_4X4]: {
      displayName: " 4''",
      longDisplayName: "4''x4''",
      longDisplayNameCms: '10x10 cm',
      trackingName: TILE_SIZES.SQUARE_4X4,
    },
    [TILE_SIZES.RECTANGLE_4X6]: {
      displayName: "4''x6''",
      longDisplayName: "4''x6''",
      longDisplayNameCms: '10x15 cm',
      trackingName: TILE_SIZES.RECTANGLE_4X6,
    },
    [TILE_SIZES.RECTANGLE_6X4]: {
      displayName: "6''x4''",
      longDisplayName: "6''x4''",
      longDisplayNameCms: '15x10 cm',
      trackingName: TILE_SIZES.RECTANGLE_6X4,
    },
    [TILE_SIZES.SQUARE_8X8]: {
      displayName: " 8''",
      longDisplayName: "8''x8''",
      longDisplayNameCms: '21x21 cm',
      trackingName: TILE_SIZES.SQUARE_8X8,
    },
    [TILE_SIZES.RECTANGLE_6X8]: {
      displayName: "6''x8''",
      longDisplayName: "6''x8''",
      longDisplayNameCms: '20x15 cm',
      trackingName: TILE_SIZES.RECTANGLE_6X8,
    },
    [TILE_SIZES.RECTANGLE_8X6]: {
      displayName: "8''x6''",
      longDisplayName: "8''x6''",
      longDisplayNameCms: '15x20 cm',
      trackingName: TILE_SIZES.RECTANGLE_8X6,
    },
    [TILE_SIZES.RECTANGLE_11X8]: {
      displayName: "11''x8''",
      longDisplayName: "11''x8''",
      longDisplayNameCms: '28x21 cm',
      trackingName: TILE_SIZES.RECTANGLE_11X8,
    },
    [TILE_SIZES.RECTANGLE_8X11]: {
      displayName: "8''x11''",
      longDisplayName: "8''x11''",
      longDisplayNameCms: '21x28 cm',
      trackingName: TILE_SIZES.RECTANGLE_8X11,
    },
    [TILE_SIZES.HONEYCOMB_10X11]: {
      displayName: "10''x11''",
      longDisplayName: "10''x11''",
      longDisplayNameCms: '25x29 cm',
      trackingName: TILE_SIZES.HONEYCOMB_10X11,
    },
    [TILE_SIZES.HONEYCOMB_11X10]: {
      displayName: "11''x10''",
      longDisplayName: "11''x10''",
      longDisplayNameCms: '29x25 cm',
      trackingName: TILE_SIZES.HONEYCOMB_11X10,
    },
    [TILE_SIZES.SQUARE_12X12]: {
      displayName: " 12''",
      longDisplayName: "12''x12''",
      longDisplayNameCms: '32x32 cm',
      trackingName: TILE_SIZES.SQUARE_12X12,
    },
    [TILE_SIZES.SQUARE_20X20]: {
      displayName: " 20''",
      longDisplayName: "20''x20''",
      longDisplayNameCms: '50x50 cm',
      trackingName: TILE_SIZES.SQUARE_20X20,
    },
    [TILE_SIZES.SQUARE_30X30]: {
      displayName: " 30''",
      longDisplayName: "30''x30''",
      longDisplayNameCms: '76x76 cm',
      trackingName: TILE_SIZES.SQUARE_30X30,
    },
    [TILE_SIZES.SQUARE_40X40]: {
      displayName: " 40''",
      longDisplayName: "40''x40''",
      longDisplayNameCms: '101x101 cm',
      trackingName: TILE_SIZES.SQUARE_40X40,
    },
    [TILE_SIZES.RECTANGLE_16X12]: {
      displayName: "16''x12''",
      longDisplayName: "16''x12''",
      longDisplayNameCms: '42x32 cm',
      trackingName: TILE_SIZES.RECTANGLE_16X12,
    },
    [TILE_SIZES.RECTANGLE_12X16]: {
      displayName: "12''x16''",
      longDisplayName: "12''x16''",
      longDisplayNameCms: '32x42 cm',
      trackingName: TILE_SIZES.RECTANGLE_12X16,
    },
    [TILE_SIZES.RECTANGLE_16X20]: {
      displayName: "16''x20''",
      longDisplayName: "16''x20''",
      longDisplayNameCms: '50x40 cm',
      trackingName: TILE_SIZES.RECTANGLE_16X20,
    },
    [TILE_SIZES.RECTANGLE_20X16]: {
      displayName: "20''x16''",
      longDisplayName: "20''x16''",
      longDisplayNameCms: '40x50 cm',
      trackingName: TILE_SIZES.RECTANGLE_12X16,
    },
    [TILE_SIZES.RECTANGLE_20X27]: {
      displayName: "20''x27''",
      longDisplayName: "20''x27''",
      longDisplayNameCms: '50x69 cm',
      trackingName: TILE_SIZES.RECTANGLE_20X27,
    },
    [TILE_SIZES.RECTANGLE_27X20]: {
      displayName: "27''x20''",
      longDisplayName: "27''x20''",
      longDisplayNameCms: '69x50 cm',
      trackingName: TILE_SIZES.RECTANGLE_27X20,
    },
    [TILE_SIZES.RECTANGLE_27X36]: {
      displayName: "27''x36''",
      longDisplayName: "27''x36''",
      longDisplayNameCms: '69x91 cm',
      trackingName: TILE_SIZES.RECTANGLE_27X36,
    },
    [TILE_SIZES.RECTANGLE_36X27]: {
      displayName: "36''x27''",
      longDisplayName: "36''x27''",
      longDisplayNameCms: '91x69 cm',
      trackingName: TILE_SIZES.RECTANGLE_36X27,
    },
    [TILE_SIZES.RECTANGLE_25X8]: {
      displayName: "25''x8''",
      longDisplayName: "25''x8''",
      longDisplayNameCms: '64x21 cm',
      trackingName: TILE_SIZES.RECTANGLE_25X8,
    },
    [TILE_SIZES.RECTANGLE_22X44]: {
      displayName: "22''x44''",
      longDisplayName: "22''x44''",
      longDisplayNameCms: '56x112 cm',
      trackingName: TILE_SIZES.RECTANGLE_22X44,
    },
    [TILE_SIZES.RECTANGLE_44X22]: {
      displayName: "44''x22''",
      longDisplayName: "44''x22''",
      longDisplayNameCms: '112x56 cm',
      trackingName: TILE_SIZES.RECTANGLE_44X22,
    },
    [BookSize.BOOK_8X8]: {
      displayName: " 8''",
      longDisplayName: "8''x8''",
      longDisplayNameCms: '21x21 cm',
      trackingName: BookSize.BOOK_8X8,
    },
    [BookSize.BOOK_10X10]: {
      displayName: " 10''",
      longDisplayName: "10''x10''",
      longDisplayNameCms: '25x25 cm',
      trackingName: BookSize.BOOK_10X10,
    },
    [BookSize.BOOK_12X12]: {
      displayName: " 12''",
      longDisplayName: "12''x12''",
      longDisplayNameCms: '32x32 cm',
      trackingName: BookSize.BOOK_12X12,
    },
  }
}

export function getSizeName(size) {
  if (size === MIXED_SIZES) {
    return t.get('order.styles.mixed_styles')
  } else {
    const isSizesInInches = ['us', 'ca'].includes(
      countryManager.getPricingCountry().toLowerCase()
    )
    if (isSizesInInches) {
      return getSizesData()[size]?.longDisplayName
    }
    return getSizesData()[size]?.longDisplayNameCms
  }
}

const _sizesPricing = memoize(() => getSizesPricing())
const _sizesCurrency = memoize(() => getSizesCurrency())

export function getPricingItemPrice({ size, materialType }) {
  const identifier = pricingKey({ tileSize: size, materialType })
  return {
    value: _sizesPricing()[identifier].regularTilePrice,
    currency: _sizesCurrency(),
  }
}

export function getSelectedSize() {
  return StorageManager.get(SELECTED_SIZE_KEY) || TILE_SIZES.SQUARE_8X8
}

export function getOrderSummarySizeText(tileSize, hideComma = false) {
  if (Array.isArray(tileSize)) {
    return tileSize.map((size) => getSizeName(size)).join(' / ')
  }
  let sizeText =
    tileSize === MIXED_SIZES
      ? t.get('designer.mixed_sizes').toLowerCase()
      : getSizeName(tileSize)
  if (!hideComma) {
    sizeText = `, ${sizeText}`
  } else {
    sizeText = ` ${sizeText}`
  }
  return sizeText
}

export function getShortSizeName(name) {
  const shouldKeepPrefix = name === MIXED_SIZES
  if (shouldKeepPrefix) {
    return name.toLowerCase()
  }
  if (name) {
    const nameParts = name.split('_')
    const shortName = nameParts[nameParts.length - 1]
    return isNumber(shortName) ? name : shortName
  }
  return 'none'
}

/**
 * Determine whether Sizes feature is available or not.
 * @returns {boolean}
 */
export function isSizes() {
  return getPricing().pricingType === SIZES_PRICING_TYPE
}

export function getMostSimilarSize(tileSize, hasXLSizes = true) {
  switch (tileSize) {
    case TILE_SIZES.SQUARE_4X4:
    case TILE_SIZES.RECTANGLE_4X6:
    case TILE_SIZES.RECTANGLE_6X4:
      return TILE_SIZES.SQUARE_8X8
    case TILE_SIZES.RECTANGLE_6X8:
      return TILE_SIZES.RECTANGLE_8X11
    case TILE_SIZES.RECTANGLE_8X6:
      return TILE_SIZES.RECTANGLE_11X8
    case TILE_SIZES.RECTANGLE_16X20:
      return TILE_SIZES.RECTANGLE_20X27
    case TILE_SIZES.RECTANGLE_20X16:
      return TILE_SIZES.RECTANGLE_27X20
    case TILE_SIZES.RECTANGLE_27X36:
      return TILE_SIZES.RECTANGLE_20X27
    case TILE_SIZES.RECTANGLE_36X27:
      return TILE_SIZES.RECTANGLE_27X20
    case TILE_SIZES.RECTANGLE_22X44:
      return hasXLSizes
        ? TILE_SIZES.RECTANGLE_27X36
        : TILE_SIZES.RECTANGLE_20X27
    case TILE_SIZES.RECTANGLE_44X22:
      return hasXLSizes
        ? TILE_SIZES.RECTANGLE_36X27
        : TILE_SIZES.RECTANGLE_27X20
    case TILE_SIZES.SQUARE_30X30:
    case TILE_SIZES.SQUARE_40X40:
      return TILE_SIZES.SQUARE_20X20
    case TILE_SIZES.HONEYCOMB_10X11:
    case TILE_SIZES.HONEYCOMB_11X10:
      return TILE_SIZES.SQUARE_12X12
  }
  logger.error('Similar size is not defined, ignoring', null, { tileSize })
}
