import { styled } from 'styled-components'
import { Drawer as MuiDrawer } from '@mui/material'
import { withStyles } from '@mui/styles'
import { XIcon } from '../../icons'
import { onLargeScreen } from 'mixtiles-web-common'

// Using withStyles instead of styled-components with classnames (such as .MuiDrawer-paper),
// because MUI adds random id to the classnames (for example .MuiDrawer-paper-830) and it doesn't work
export const Drawer = withStyles({
  paper: {
    width: '320px',
    maxWidth: '85vw',
    boxShadow: '0 3px 14px 0 rgba(0, 0, 0, 0.21) !important',
    overflowX: 'hidden',
    borderRadius: '0 10px 0 0',
  },
})(MuiDrawer)

export const NavContainer = styled.nav``

export const TopContainer = styled.div`
  height: 75px;
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  border-bottom: 1px solid #ededed;

  ${onLargeScreen()} {
    height: 88px;
  }
`

export const MainNavContainer = styled.div`
  padding-inline: 20px;
`

export const Logo = styled.div`
  width: calc(100% - 52px);
  align-items: center;
  display: flex;
  opacity: 1;
  transition: opacity 0.5s ease;
`

export const CloseButton = styled(XIcon)`
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 32px;
  width: 32px;
  cursor: pointer;
  border-radius: 50%;
  --svg-stroke-width: 1.5;
  color: ${({ theme }) => theme.colors.grayMidDark};

  &:hover {
    background-color: ${({ theme }) => theme.colors.grayMedium};
  }
`

export const NavsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
