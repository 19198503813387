import React, { useEffect, useState } from 'react'
import LinkButton from '../../elements/LinkButton/LinkButton'
import { ELEMENT_SIZE, Flex } from 'mixtiles-web-common'
import * as S from './LoginBox.styles'
import { useProductType } from '../../services/ProductTypesManager'
import { MIXTILES_ART_URL } from '../../config/config'
import { useAuth } from '../../services/AuthProvider/AuthProvider'
import { translateManager as t } from '../../services/TranslateManager'
import PaymentManager from '../../services/PaymentManager'
import { addressManager } from '../../services/AddressManager'
import UserManager from '../../services/UserManager'
import StorageManager from '../../services/StorageManager'
import {
  ACTIVE_ORDERS_COUNT_KEY,
  getAuthRequestConfig,
  IS_NEW_ACCOUNT_KEY,
  LOGIN_POPUP_SHOWN_KEY,
} from '../../services/LoginUtills'
import { giftNoteManager } from '../../services/GiftNoteManager'
import { promoCodeManager } from '../../services/PromoCodeManager'
import { analytics, EVENT_NAMES } from '../../services/Analytics/Analytics'
import { logger } from '../../services/logger'
import fallbackPhotoUrl from '../../images/account.png'
import { mixtilesAxios } from '../../utils/ApiUtils'
import { STORAGE_KEYS as TILES_STORAGE_KEYS } from '../TilesProvider/TilesProvider.consts'
import { STORAGE_KEY as PHOTO_BANK_STORAGE_KEY } from '../PhotoBank/PhotoBank.consts'
import { PRODUCT_TYPES } from '../../services/ProductTypeState'

export default function LoggedInContent() {
  const { productType } = useProductType()
  const { user, getAccessToken, logout } = useAuth()
  const [photoUrl, setPhotoUrl] = useState(
    (user && user.picture) || fallbackPhotoUrl
  )
  const username = user ? user.name : 'username'

  useEffect(() => {
    if (user?.picture) {
      setPhotoUrl(user.picture)
    }
  }, [user?.picture])

  const onImageLoadError = () => {
    setPhotoUrl(fallbackPhotoUrl)
  }

  const getReturnLocation = () => {
    if (productType === PRODUCT_TYPES.ART) {
      return window.location.origin + MIXTILES_ART_URL
    }
    return window.location.origin
  }

  const onLogoutClicked = async () => {
    try {
      const token = await getAccessToken()
      const config = getAuthRequestConfig(token)
      const response = await mixtilesAxios.get('v1/auth/loginSuccess', config)
      const { analyticsInfo } = response.data
      const { previousOrdersCount } = analyticsInfo
      analytics.track(EVENT_NAMES.SIGN_OUT_TAPPED, {
        Email: user.email,
        'Number of Previous Orders': previousOrdersCount,
      })
    } catch (e) {
      logger.error('Failed to get analytics info', e)
    }

    const returnTo = getReturnLocation()
    clearLocalData()
    logout(returnTo)
  }

  const clearLocalData = () => {
    PaymentManager.clearData()
    addressManager.clearData()
    UserManager.clearData()
    Object.values(TILES_STORAGE_KEYS).forEach((key) =>
      StorageManager.remove(key)
    )
    StorageManager.remove(ACTIVE_ORDERS_COUNT_KEY)
    StorageManager.remove(LOGIN_POPUP_SHOWN_KEY)
    StorageManager.remove(IS_NEW_ACCOUNT_KEY)
    giftNoteManager.clearData()
    promoCodeManager.clearDiscountCoupon()
    StorageManager.remove(PHOTO_BANK_STORAGE_KEY)
  }

  return (
    <Flex data-testid="menu-logged-in-content">
      <S.UserPhoto>
        <img src={photoUrl} onError={onImageLoadError} />
      </S.UserPhoto>
      <S.Content>
        <S.Username>{username}</S.Username>
        <LinkButton
          size={ELEMENT_SIZE.small}
          color="black"
          onClick={onLogoutClicked}
          data-testid="menu-logout-button"
        >
          {' '}
          {t.get('login.sign_out')}{' '}
        </LinkButton>
      </S.Content>
    </Flex>
  )
}
