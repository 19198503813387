import React from 'react'
import useScreenSize from '../../hooks/useScreenSize'
import * as S from './Popup.styles'

export default function Popup({
  children,
  maxHeight,
  onClose = () => {},
  open = true,
  fullHeight = false,
  noBorderRadius = false,
  dialogStyles = {},
  slotProps = {},
}) {
  const isMediumScreen = useScreenSize('md')
  return isMediumScreen ? (
    <S.Dialog
      sx={{ ...dialogStyles }}
      open={open}
      onClose={onClose}
      maxWidth="md"
      slotProps={slotProps}
    >
      {children}
    </S.Dialog>
  ) : (
    <S.Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      $maxHeight={maxHeight}
      $fullHeight={fullHeight}
      $noBorderRadius={noBorderRadius}
    >
      {children}
    </S.Drawer>
  )
}
