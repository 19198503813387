import storageManager from './StorageManager'
import { logger } from './logger'

const PROMO_CODE_STORAGE_KEY = 'savedDiscountCoupon'

export function storePromoCode(promoCode) {
  logger.info('storing promo code', promoCode)
  storageManager.set(PROMO_CODE_STORAGE_KEY, promoCode)
}

export function hasStoredPromoCode() {
  return !!storageManager.get(PROMO_CODE_STORAGE_KEY)
}

export function getStoredPromoCode() {
  return storageManager.get(PROMO_CODE_STORAGE_KEY)
}

export function removeStoredPromoCode() {
  logger.info('clearing promo code')
  storageManager.remove(PROMO_CODE_STORAGE_KEY)
}
