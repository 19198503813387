import * as S from '../EmbeddedLogin.styles'
import { translateManager as t } from '../../../services/TranslateManager'
import React, { useState } from 'react'
import { analytics, EVENT_NAMES } from '../../../services/Analytics/Analytics'
import { passwordlessStart } from '../../../api/account.api'
import { extractApiError } from '../../../utils/ApiUtils'
import useApi from '../../../api/apiProvider'

const MAX_EMAIL_LENGTH = 80

export function EmbeddedLoginForm({
  showError,
  email,
  setEmail,
  setShowCode,
}: {
  showError: (error: any) => void
  email: string
  setEmail: (value: string) => void
  setShowCode: (value: boolean) => void
}) {
  const api = useApi()
  const [isLoading, setIsLoading] = useState(false)

  const handleEmailSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true)
    e.preventDefault()
    analytics.track(EVENT_NAMES.LOGIN_EMAIL_ENTERED)

    try {
      await api.call(passwordlessStart({ email }))
      setShowCode(true)
    } catch (e) {
      const error = extractApiError(e) || e
      showError(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleEmailChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setEmail(value)
  }

  return (
    <S.EmailForm onSubmit={handleEmailSubmit}>
      <S.LoginInput
        type="email"
        name="email"
        value={email}
        placeholder={t.get('login.email_capture_placeholder')}
        maxLength={MAX_EMAIL_LENGTH}
        required
        onChange={handleEmailChanged}
        data-testid="embedded-login-email-input"
      />
      <S.Continue
        data-testid="embedded-login-email-submit-button"
        loading={isLoading}
        disabled={isLoading}
      >
        {t.get('new_email_capture.continue')}
      </S.Continue>
    </S.EmailForm>
  )
}
