import React, { createContext, useContext, useState } from 'react'
import AlertPopup from '../../components/AlertPopup/AlertPopup'
import ActionPopover from '../../components/ActionPopover/ActionPopover'
import NoSSR from 'utils/NoSSR'

const DialogContext = createContext()

DialogContext.displayName = 'DialogContext'

export default function useDialog() {
  const context = useContext(DialogContext)

  if (context === undefined) {
    throw new Error('useDialog must be used within a dialogContextProvider')
  }

  return context
}

export function withDialog(Component) {
  return function Comp(props) {
    return (
      <DialogContext.Consumer>
        {(contexts) => <Component {...props} dialog={contexts} />}
      </DialogContext.Consumer>
    )
  }
}

export function DialogProvider({ children }) {
  const [alertDialog, setAlertDialog] = useState({})
  const [dialog, setDialog] = useState(null)
  const [popoverMessage, setPopoverMessage] = useState({
    visible: false,
    message: '',
  })
  const [isOpen, setIsOpen] = useState(false)

  const showAlertDialog = (title, subtitle, buttonText, handleClose) => {
    setAlertDialog({
      title,
      subtitle,
      buttonText,
      handleClose,
    })
    setIsOpen(true)
  }

  // Show a popover message for a specified duration
  const showMessagePopover = ({ message, duration }) => {
    setPopoverMessage({ visible: true, message })

    setTimeout(() => {
      setPopoverMessage({ visible: false, message })
    }, duration)
  }

  const showDialog = (dialog) => {
    setDialog(dialog)
  }

  const closeDialog = () => {
    setAlertDialog({})
    setDialog(null)
    setIsOpen(false)
  }

  const dismissDialog = () => {
    handleClose && handleClose()
    closeDialog()
  }

  const isDialogOpen = (dialogClass) => {
    return dialog?.type?.name === dialogClass?.name
  }

  const { title, subtitle, buttonText, handleClose } = alertDialog
  return (
    <DialogContext.Provider
      value={{
        showAlert: showAlertDialog,
        open: showDialog,
        showMessagePopover,
        close: closeDialog,
        isOpen: isDialogOpen,
        isAnyDialogOpen: dialog !== null || isOpen,
      }}
    >
      {alertDialog.title && (
        <AlertPopup
          open={isOpen}
          onClick={dismissDialog}
          title={title}
          subtitle={subtitle}
          buttonText={buttonText}
        />
      )}
      {dialog}
      <NoSSR>
        <ActionPopover
          visible={popoverMessage.visible}
          message={popoverMessage.message}
        />
      </NoSSR>
      {children}
    </DialogContext.Provider>
  )
}
