const MINIMUM_QUALITY_EDGE_SIZE = 500
const MINIMUM_MAX_ZOOM = 1.1
const MAX_PHOTO_AGE_DAYS = 29
const MAX_PHOTO_AGE = MAX_PHOTO_AGE_DAYS * 24 * 60 * 60 * 1000

export const shouldWarnLowQualityDefault = ({
  width,
  height,
}: {
  width: number
  height: number
}): boolean => {
  return Math.min(width, height) < MINIMUM_QUALITY_EDGE_SIZE
}

export function calcMaxZoom({
  width,
  height,
}: {
  width: number
  height: number
}) {
  return Math.max(
    Math.min(width, height) / MINIMUM_QUALITY_EDGE_SIZE,
    MINIMUM_MAX_ZOOM
  )
}

export function isExpiredPhoto({ uploadedAt = 0, isStatic = false }) {
  return (!uploadedAt || Date.now() - uploadedAt > MAX_PHOTO_AGE) && !isStatic
}
