import { createContext, useContext } from 'react'

export type DeviceType = 'mobile' | 'tablet' | 'desktop'
export type DeviceContextValue = { type: DeviceType }
export const DeviceContext = createContext<DeviceContextValue | null>(null)

export const useDevice = () => {
  const device = useContext(DeviceContext)

  if (!device) {
    throw new Error('Nothing not found in DeviceContext')
  }

  return device
}
