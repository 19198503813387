import React from 'react'
import { useAuth } from '../../services/AuthProvider/AuthProvider'
import { LoaderContainer, LoginStyledBox } from './LoginBox.styles'
import { Spinner } from 'mixtiles-web-common'
import LoggedInContent from './LoggedInContent'
import GuestContent from './GuestContent'
import { useEmbeddedLogin } from '../EmbeddedLogin/EmbeddedLoginProvider'

function LoginBoxWrapper({ source, onLoginStart }) {
  const { isAuthenticated, isLoading } = useAuth()
  const { open } = useEmbeddedLogin()

  const handleLoginClicked = () => {
    open({ source })
    onLoginStart()
  }

  return (
    <>
      <LoginStyledBox $isLoggedIn={isAuthenticated}>
        {isLoading ? (
          <LoaderContainer>
            <Spinner grey />
          </LoaderContainer>
        ) : isAuthenticated ? (
          <LoggedInContent />
        ) : (
          <GuestContent handleLoginClicked={handleLoginClicked} />
        )}
      </LoginStyledBox>
    </>
  )
}

export default LoginBoxWrapper
