import {
  MATERIAL_TYPES,
  TILE_SIZES,
  CANVAS_SIZES,
} from '@mixtiles/web-backend-shared'
import { experimentManager } from '../../services/ExperimentManager/ExperimentManager'
import { TILES_SOURCE } from '../../components/TilesProvider/TilesProvider.consts'

const ALL_SIZES = [
  TILE_SIZES.SQUARE_8X8,
  TILE_SIZES.RECTANGLE_8X11,
  TILE_SIZES.RECTANGLE_11X8,
  TILE_SIZES.SQUARE_12X12,
  TILE_SIZES.RECTANGLE_12X16,
  TILE_SIZES.RECTANGLE_16X12,
  TILE_SIZES.SQUARE_20X20,
  TILE_SIZES.RECTANGLE_20X27,
  TILE_SIZES.RECTANGLE_27X20,
]

// Position new sizes after the given size in the array
function insertAfterSize(
  previousSizes: TILE_SIZES[],
  size: TILE_SIZES,
  newSizes: TILE_SIZES[]
): TILE_SIZES[] {
  const sizes = [...previousSizes]
  const position = sizes.indexOf(size)
  sizes.splice(position + 1, 0, ...newSizes)
  return sizes
}

export const getOrderedSizes = (
  materialType = MATERIAL_TYPES.CLASSIC,
  tilesSource = TILES_SOURCE.PHOTO_STYLER
) => {
  if (tilesSource === TILES_SOURCE.ART) {
    const artSizes = [
      TILE_SIZES.RECTANGLE_8X11,
      TILE_SIZES.RECTANGLE_12X16,
      TILE_SIZES.RECTANGLE_20X27,
    ]

    if (experimentManager.isEnabled('xl-sizes')) {
      artSizes.push(TILE_SIZES.RECTANGLE_27X36)
    }
    return artSizes
  }
  if (materialType === MATERIAL_TYPES.STICKER) {
    return [TILE_SIZES.SQUARE_30X30, TILE_SIZES.SQUARE_40X40]
  }
  if (materialType === MATERIAL_TYPES.CANVAS) {
    return experimentManager.isEnabled('canvas-16x20')
      ? insertAfterSize(CANVAS_SIZES, TILE_SIZES.RECTANGLE_16X12, [
          TILE_SIZES.RECTANGLE_16X20,
          TILE_SIZES.RECTANGLE_20X16,
        ])
      : CANVAS_SIZES
  }

  const sizes: TILE_SIZES[] = []
  if (
    experimentManager.isEnabled('size-4x4') &&
    materialType === MATERIAL_TYPES.FRAMELESS
  ) {
    sizes.push(TILE_SIZES.SQUARE_4X4)
  }

  sizes.push(...ALL_SIZES)

  if (experimentManager.isEnabled('xl-sizes')) {
    sizes.push(TILE_SIZES.RECTANGLE_27X36, TILE_SIZES.RECTANGLE_36X27)
  }

  if (experimentManager.isEnabled('size-22x44')) {
    sizes.push(TILE_SIZES.RECTANGLE_22X44, TILE_SIZES.RECTANGLE_44X22)
  }

  return sizes
}
