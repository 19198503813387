import { styled } from 'styled-components'
import { ReactComponent as LogoSVG } from '../logo.svg'
import { onMediumScreen } from 'mixtiles-web-common'
import { ReactComponent as SeasonalLogoSVG } from '../halloweenLogo.svg'

export const LogoContainer = styled.div<{
  $halloween?: boolean
}>`
  padding: 15px 0;
  gap: 1px;
  cursor: pointer;
  width: ${({ $halloween }) => ($halloween ? 121 : 98)}px;

  ${onMediumScreen()} {
    width: ${({ $halloween }) => ($halloween ? 128 : 108)}px;
  }
`

export const LogoHalloween = styled(SeasonalLogoSVG)<{
  $primaryColor?: string
  $secondaryColor?: string
}>`
  display: block;
  --primary-color: ${({ $primaryColor }) => $primaryColor || '#2B0514'};
  --secondary-color: ${({ $secondaryColor }) => $secondaryColor || '#FF9100'};
`

export const Logo = styled(LogoSVG)<{
  $color?: string
}>`
  display: block;
  color: ${({ theme, $color }) => $color || theme.colors.secondaryDark};
`
