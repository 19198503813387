import SplashSpinner from './SplashSpinner'
import PageOverlay from '../PageOverlay/PageOverlay'

export default function SplashSpinnerOverlay() {
  return (
    <PageOverlay>
      <SplashSpinner />
    </PageOverlay>
  )
}
