import {
  DEFAULT_MAT_COLOR,
  FILTERS,
  FRAME_COLORS,
  MAT_COLORS,
  MATTING,
} from '@mixtiles/web-backend-shared'

export const MIXED_SIZES = 'Mixed Sizes'
export const MIXED_COLORS = 'Mixed Colors'
export const MIXED_MATERIALS = 'Mixed Materials'

export const OUT_OF_STOCK = 'Out of Stock'

export const BASE_STYLING = 'baseStyling'
export const FALLBACK_4X4_STYLE = {
  frameColor: FRAME_COLORS.NONE,
  matting: MATTING.NONE,
  filter: FILTERS.NONE,
  mattingColor: DEFAULT_MAT_COLOR,
}
export const FALLBACK_STYLE = {
  frameColor: FRAME_COLORS.BLACK,
  matting: MATTING.SHALLOW,
  filter: FILTERS.NONE,
  mattingColor: DEFAULT_MAT_COLOR,
}

export const FALLBACK_NO_MAT_STYLE = {
  frameColor: FRAME_COLORS.BLACK,
  matting: MATTING.NONE,
  filter: FILTERS.NONE,
  mattingColor: MAT_COLORS.NONE,
}

export const FALLBACK_NOT_CLASSIC_MATERIAL_STYLE = {
  frameColor: FRAME_COLORS.NONE,
  matting: MATTING.NONE,
  filter: FILTERS.NONE,
  mattingColor: MAT_COLORS.NONE,
}

export const AUTO_SAVE_POPUP_KEY = 'auto-save-popup-shown'

export const EXCELLENT_QUALITY_BANNER_SHOWN = 'excellent-quality-banner-shown'
