import { API_METHODS, createApiFunction } from '../../api/apiProvider'

export const PRICING_ITEMS_ENDPOINT = 'v2/pricing-items'
export const DISCOUNTS_ENDPOINT = 'discounts'
export const getPricingItems = ({ productType, pricingCountry, variantsIds }) =>
  createApiFunction(API_METHODS.GET, PRICING_ITEMS_ENDPOINT, {
    productType,
    pricingCountry,
    variantsIds,
  })
export const getDiscounts = ({
  productType,
  pricingCountry,
  variantsIds,
  promocode,
}) =>
  createApiFunction(API_METHODS.GET, DISCOUNTS_ENDPOINT, {
    productType,
    pricingCountry,
    variantsIds,
    promocode,
  })
export const calculateCheckoutSummary = ({
  productType,
  pricingCountry,
  variantsIds,
  promocode,
  giftCodes,
  products,
  address,
  discountId,
  checkoutId,
  purchasingMixtilesPlus,
  timestamp,
}) => {
  return createApiFunction(
    API_METHODS.POST,
    'checkout-summary',
    {
      productType,
      pricingCountry,
      variantsIds,
      promocode,
      giftCodes,
      products,
      address,
      discountId,
      checkoutId,
      purchasingMixtilesPlus,
      timestamp,
    },
    false
  )
}

export const calculateBatchProductsPricing = ({
  productsCollections,
  productType,
  pricingCountry,
  variantsIds,
  promocode,
}) => {
  return createApiFunction(API_METHODS.POST, 'checkout-summary/batch-pricing', {
    productsCollections,
    productType,
    pricingCountry,
    variantsIds,
    promocode,
    ignoreFreeTiles: true,
  })
}
