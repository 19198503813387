import React, { useState } from 'react'
import * as S from './PromoCodeEditor/PromoCodeSelector.styles'
import PromoCodeForm from './PromoCodeForm/PromoCodeForm'
import './PromoCodeDialog.scss'
import PromoCodeSelector from './PromoCodeEditor/PromoCodeSelector'
import BrowserThemeColor from '../ThemeColor/BrowserThemeColor'
import { useTheme } from 'styled-components'
import { useProductType } from '../../services/ProductTypesManager'
import { PRODUCT_TYPES } from '../../services/ProductTypeState'

function shouldShowSelector(productType) {
  return productType !== PRODUCT_TYPES.ART
}

function PromoCodeDialog({ open, onDismiss, onSuccess, source }) {
  const { productType } = useProductType()
  const [showPromoCodeForm, setShowPromoCodeForm] = useState(
    !shouldShowSelector(productType)
  )
  const theme = useTheme()

  function clearPromoCodeDialog() {
    setShowPromoCodeForm(false)
    onDismiss()
  }

  return (
    <S.Dialog open={open} onDismiss={clearPromoCodeDialog}>
      {open && <BrowserThemeColor color={theme.colors.grayBackgroundWhite} />}
      {shouldShowSelector(productType) && !showPromoCodeForm ? (
        <PromoCodeSelector
          onDismiss={clearPromoCodeDialog}
          onSuccess={onSuccess}
          setShowPromoCodeForm={setShowPromoCodeForm}
          source={source}
        />
      ) : (
        <PromoCodeForm
          onDismiss={clearPromoCodeDialog}
          onSuccess={onSuccess}
          onClose={() =>
            shouldShowSelector(productType)
              ? setShowPromoCodeForm(false)
              : clearPromoCodeDialog()
          }
          source={source}
        />
      )}
    </S.Dialog>
  )
}

export default PromoCodeDialog
