import { getCurrency } from './PricingUtils'

let _currency = null

export const currencyState = {
  getCurrency: () => {
    return _currency || getCurrency()
  },
  setCurrency: (currency) => {
    _currency = currency
  },
}
