import {
  ARTWORK_VIEW_URL,
  isXL,
  PHOTO_ORIENTATIONS,
  TILE_SIZES,
} from '@mixtiles/web-backend-shared'

export const SET_BASE_URL = '/art/artset'
export const DISCOVERY_URL = '/art/browse'

export const ART_VIEW_SUB_ROUTES = {
  CUSTOMIZE: 'customize',
  FRAME_SELECTION: 'frame',
  SIZE_SELECTION: 'size',
  ARTWORKS_SELECTION: 'suggestions',
  LAYOUT_SELECTION: 'layout',
  PRODUCT_FLOW: 'product',
}

export const ART_API_PATH = '/art-api'
export function getMt3dAssetsUrl() {
  return `https://${window.KEYS.mixtiles3DAssetsDomain}/artwork`
}

export const ART_PRODUCT_TYPES = {
  ARTWORK: 'Artwork',
  SET: 'Set',
}

export const ART_ANALYTICS_PRODUCT_TYPES = {
  SINGLE_ARTWORK: 'Single-Artwork',
  MULTI_SELECT: 'Multi-Select',
  PRE_MADE: 'Pre-Made',
}

export const ART_SCREENS = {
  HOME: 'Art Pitch',
  ONBOARDING: 'Art Onboarding',
  DISCOVERY: 'Art Discovery',
  CATEGORY: 'Art Category',
  ARTIST: 'Art Artist',
  SEARCH: 'Art Search',
  PRODUCT_ONBOARDING: 'Art Product Onboarding',

  SET_PREVIEW_WORKSPACE: 'Set Preview Workspace',

  ARTWORK_PRODUCT_PREVIEW: 'Artwork Product Preview',
  ARTWORK_FRAME_SELECTION: 'Artwork Frame Selection',
  ARTWORKS_SELECTION: 'Artworks Selection',
  LAYOUT_SELECTION: 'Layout Selection',
  ARTWORK_SIZE_SELECTION: 'Artwork Size Selection',
  ARTWORK_PRODUCT_DETAILS: 'Artwork Product Details',
  ARTWORK_PRODUCT_EMAIL_CAPTURE: 'Artwork Product Email Capture',

  // Should be transition to new Art Set Gradually
  ART_SET_PRODUCT_PREVIEW: 'Art Set Product Preview',
  ART_SET_FRAME_SELECTION: 'Art Set Frame Selection',
  ART_SET_SIZE_SELECTION: 'Art Set Size Selection',
  ART_SET_PRODUCT_DETAILS: 'Art Set Product Details',
  ART_SET_PRODUCT_EMAIL_CAPTURE: 'Art Set Product Email Capture',

  // New Art Set
  ART_SET: 'Art Set',
  CATEGORY_SETS: 'Category Sets',
  ART_SET_PRODUCT: 'Art Set Product',
}

export const ART_SOURCES = {
  DISCOVERY: 'Discovery',
  PRODUCT: 'Product',
  ARTIST: 'Artist',
  CATEGORY: 'Category',
  SEARCH: 'Search',
  OTHER: 'Other',
}

export const SELECTED_ARTWORK_SOURCE_KEY = 'selectedArtworkSource'
export const LAST_SELECTED_ARTWORK_KEY = 'lastSelectedArtwork'
export const SUGGESTED_CATEGORY_NAME = 'Suggested'
export const RECENTLY_VIEWED_CATEGORY_NAME = 'Recently Viewed'
export const NO_CATEGORY_NAME = 'Uncategorized'
export const ALL_CATEGORY_NAME = 'All'
export const CART_FLOW_STEPS_KEY = 'cartFlowSteps'

export const ART_CATEGORIES = {
  BESTSELLERS: 'Bestsellers',
  ABSTRACT: 'Abstract',
  PHOTOGRAPHY: 'Photography',
  MINIMALIST: 'Minimalist',
  NATURE: 'Nature',
  TYPOGRAPHY: 'Typography',
  ANIMALS: 'Animals',
  PEOPLE: 'People',
  TRAVEL: 'Travel',
  RETRO_AND_VINTAGE: 'Retro and Vintage',
  BOTANICAL: 'Botanical',
  URBAN: 'Urban',
  BATHROOM: 'Bathroom Art',
  BLACK_ARTISTS: 'Black Artists',
  SCIFI_AND_FANTASY: 'Sci-Fi and Fantasy',
  KIDS_AND_NURSERY: 'Art for Kids and Nursery',
}

export const BESTSELLERS_CATEGORY_NAME = ART_CATEGORIES.BESTSELLERS
export const ART_MENU_CATEGORIES = [
  ART_CATEGORIES.BESTSELLERS,
  ART_CATEGORIES.ABSTRACT,
  ART_CATEGORIES.PHOTOGRAPHY,
  ART_CATEGORIES.MINIMALIST,
  ART_CATEGORIES.NATURE,
  ART_CATEGORIES.TYPOGRAPHY,
  ART_CATEGORIES.ANIMALS,
  ART_CATEGORIES.PEOPLE,
  ART_CATEGORIES.TRAVEL,
  ART_CATEGORIES.RETRO_AND_VINTAGE,
  ART_CATEGORIES.BOTANICAL,
  ART_CATEGORIES.URBAN,
  ART_CATEGORIES.BATHROOM,
  ART_CATEGORIES.BLACK_ARTISTS,
  ART_CATEGORIES.SCIFI_AND_FANTASY,
  ART_CATEGORIES.KIDS_AND_NURSERY,
]

export const SCREEN_DISPLAY_TYPES = { DESKTOP: 'desktop', MOBILE: 'mobile' }

export const ART_ORIENTATION_TO_SIZES = {
  [PHOTO_ORIENTATIONS.SQUARE]: [
    TILE_SIZES.SQUARE_8X8,
    TILE_SIZES.SQUARE_12X12,
    TILE_SIZES.SQUARE_20X20,
  ],

  [PHOTO_ORIENTATIONS.PORTRAIT]: [
    TILE_SIZES.RECTANGLE_8X11,
    TILE_SIZES.RECTANGLE_12X16,
    TILE_SIZES.RECTANGLE_20X27,
    TILE_SIZES.RECTANGLE_27X36,
    TILE_SIZES.SPLIT_27X40,
    TILE_SIZES.RECTANGLE_22X44,
  ],
  [PHOTO_ORIENTATIONS.LANDSCAPE]: [
    TILE_SIZES.RECTANGLE_11X8,
    TILE_SIZES.RECTANGLE_16X12,
    TILE_SIZES.RECTANGLE_27X20,
    TILE_SIZES.RECTANGLE_36X27,
    TILE_SIZES.SPLIT_40X27,
    TILE_SIZES.RECTANGLE_44X22,
  ],
}

export function minimumSizeForOrientation({ orientation }) {
  return ART_ORIENTATION_TO_SIZES[orientation][0]
}

export function sizeTextToEnum({ textOfRequest, orientation }) {
  if (!textOfRequest) {
    return null
  }
  if (ART_ORIENTATION_TO_SIZES[orientation].includes(textOfRequest)) {
    return textOfRequest
  }
  const numbers = textOfRequest
    .toLowerCase()
    .split('x')
    .sort(function (a, b) {
      return orientation === PHOTO_ORIENTATIONS.LANDSCAPE ? b - a : a - b
    })
    .join('X')
  const sizeString = `${
    orientation === PHOTO_ORIENTATIONS.SQUARE ? 'SQUARE' : 'RECTANGLE'
  }_${numbers}`
  return ART_ORIENTATION_TO_SIZES[orientation].includes(sizeString)
    ? sizeString
    : null
}

export const getArtsetSizeChooserSizes = (artset) => {
  return artset.sizeChoosingScene.sets.map((set) => set.size).filter(Boolean)
}

export const isExtraLargeSet = (setData) =>
  setData.tiles.some((tile) => isXL(tile.size))

export const getOrientationBySize = (tileSize) => {
  if (ART_ORIENTATION_TO_SIZES[PHOTO_ORIENTATIONS.SQUARE].includes(tileSize)) {
    return PHOTO_ORIENTATIONS.SQUARE
  } else if (
    ART_ORIENTATION_TO_SIZES[PHOTO_ORIENTATIONS.LANDSCAPE].includes(tileSize)
  ) {
    return PHOTO_ORIENTATIONS.LANDSCAPE
  } else {
    return PHOTO_ORIENTATIONS.PORTRAIT
  }
}

export const SECTIONS_POSITIONS_MAPPING = 'Sections Positions Mapping'

const SEARCH_URL = '/art/search'
const ART_SET_BASE_URL = '/art/sets'

const ART_SET_URL = `${ART_SET_BASE_URL}/:artSetId`
const CATEGORY_SETS_URL = '/art/categories/:categoryName/sets'
const SET_PREVIEW_WORKSPACE_URL = '/art/set/workspace'

// TODO: Should be deprecated
const ART_SET_VIEW_URL = `${SET_BASE_URL}/:artsetId`
const ARTIST_URL = '/art/artist'

export const ART_ROUTES = {
  [ART_SCREENS.HOME]: '/art',

  [ART_SCREENS.ONBOARDING]: '/art/getstarted',
  [ART_SCREENS.PRODUCT_ONBOARDING]: '/art/product/getstarted',

  [ART_SCREENS.DISCOVERY]: DISCOVERY_URL,
  [ART_SCREENS.CATEGORY]: `${DISCOVERY_URL}/:category`,
  [ART_SCREENS.ARTIST]: `${ARTIST_URL}/:artistName`,
  [ART_SCREENS.SEARCH]: SEARCH_URL,

  [ART_SCREENS.SET_PREVIEW_WORKSPACE]: SET_PREVIEW_WORKSPACE_URL,

  [ART_SCREENS.ARTWORK_PRODUCT_PREVIEW]: ARTWORK_VIEW_URL,
  [ART_SCREENS.ARTWORK_FRAME_SELECTION]: `${ARTWORK_VIEW_URL}/${ART_VIEW_SUB_ROUTES.FRAME_SELECTION}`,
  [ART_SCREENS.ARTWORKS_SELECTION]: `${ARTWORK_VIEW_URL}/${ART_VIEW_SUB_ROUTES.ARTWORKS_SELECTION}`,
  [ART_SCREENS.LAYOUT_SELECTION]: `${ARTWORK_VIEW_URL}/${ART_VIEW_SUB_ROUTES.LAYOUT_SELECTION}`,
  [ART_SCREENS.ARTWORK_SIZE_SELECTION]: `${ARTWORK_VIEW_URL}/${ART_VIEW_SUB_ROUTES.SIZE_SELECTION}`,
  [ART_SCREENS.ARTWORK_PRODUCT_DETAILS]: `${ARTWORK_VIEW_URL}/${ART_VIEW_SUB_ROUTES.PRODUCT_FLOW}`,

  // DEPRECATED ART SET
  [ART_SCREENS.ART_SET_PRODUCT_PREVIEW]: ART_SET_VIEW_URL,
  [ART_SCREENS.ART_SET_SIZE_SELECTION]: `${ART_SET_VIEW_URL}/${ART_VIEW_SUB_ROUTES.SIZE_SELECTION}`,
  [ART_SCREENS.ART_SET_FRAME_SELECTION]: `${ART_SET_VIEW_URL}/${ART_VIEW_SUB_ROUTES.FRAME_SELECTION}`,
  [ART_SCREENS.ART_SET_PRODUCT_DETAILS]: `${ART_SET_VIEW_URL}/${ART_VIEW_SUB_ROUTES.PRODUCT_FLOW}`,

  // NEW AND GOOD ART SET
  [ART_SCREENS.ART_SET]: ART_SET_URL,
  [ART_SCREENS.CATEGORY_SETS]: CATEGORY_SETS_URL,
  [ART_SCREENS.ART_SET_PRODUCT]: `${ART_SET_URL}/${ART_VIEW_SUB_ROUTES.PRODUCT_FLOW}`,
}

export const ART_FLOWS = {
  DISCOVERY: 'Discovery',
  CATEGORY: 'Category',
  PRODUCT: 'Product',
  ARTIST: 'Artist',
  SEARCH: 'Search',
}
