import React, { Component } from 'react'
import './BasicDialogButton.scss'
import classNames from 'classnames'

class BasicDialogButton extends Component {
  render() {
    const { testId } = this.props

    const buttonClass = classNames('dialog-button', {
      highlighted: this.props.highlighted,
      gray: this.props.gray,
      'no-border': this.props.noBorder,
      disabled: this.props.disabled,
      'with-icon': this.props.withIcon,
      'vertical-icon': this.props.verticalIcon,
      green: this.props.green,
      bold: this.props.bold,
    })

    if (this.props.loading) {
      return (
        <div className={buttonClass}>
          <div className="spinner" />
        </div>
      )
    }

    return (
      <div
        className={buttonClass}
        onClick={this.props.action}
        data-testid={testId}
      >
        {this.props.children}
      </div>
    )
  }
}

export default BasicDialogButton
