import React, { useEffect, useState } from 'react'
import * as S from '../EmbeddedLogin.styles'
import useApi from 'api/apiProvider'
import { passwordlessLogin, passwordlessStart } from 'api/account.api'
import { useAuth } from 'services/AuthProvider/AuthProvider'
import { analytics, EVENT_NAMES } from 'services/Analytics/Analytics'
import { extractApiError } from 'utils/ApiUtils'
import { _VARIANTS as VARIANTS } from 'mixtiles-web-common/ui/TextInput/TextInput.styles'
import DotsLoader from '../../DotsLoader/DotsLoader'
import { translateManager as t } from '../../../services/TranslateManager'
import UserManager, {
  CAPTURE_EMAIL_SOURCE,
} from '../../../services/UserManager'
import { experimentManager } from '../../../services/ExperimentManager/ExperimentManager'
import { useNavigate } from '@remix-run/react'

function EmbeddedLoginCodeDialog({
  showError,
  setShowCode,
  email,
  onLoginProcessStart,
  isMixtilesPlusFlow,
  redirectTo,
}) {
  const [code, setCode] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [isLoadingResendCode, setIsLoadingResendCode] = useState(false)
  const { finalizeAuth, handleEmbeddedLogin } = useAuth()
  const api = useApi()
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoadingResendCode) {
      setTimeout(() => {
        setIsLoadingResendCode(false)
      }, 6000)
    }
  }, [isLoadingResendCode])

  const handleCodeChanged = (e) => {
    const { value } = e.target
    setCode(value)
  }

  const resendCode = async () => {
    try {
      setIsLoadingResendCode(true)
      await api.call(passwordlessStart({ email }))
      setShowCode(true)
    } catch (e) {
      const error = extractApiError(e) || e
      showError(error)
    }
  }

  const handleCodeSubmit = async (e) => {
    setLoading(true)
    analytics.track(EVENT_NAMES.LOGIN_EMAIL_CODE_ENTERED)
    e.preventDefault()
    try {
      const res = await api.call(passwordlessLogin({ email, code }))
      await finalizeAuth(res)
      await handleEmbeddedLogin()
      onLoginProcessStart?.()
      await UserManager.setUserEmail({
        email,
        source: CAPTURE_EMAIL_SOURCE.login,
        // We don't want to refetch experiments after mixtiles plus login because it's too late in the flow
        refetchExperiments: !isMixtilesPlusFlow,
      })
      if (!experimentManager.isEnabled('straight-to-photos') || !redirectTo) {
        window.location.reload()
      } else {
        const [pathname, search] = redirectTo.split('?')
        navigate({
          pathname,
          search,
        })
      }
    } catch (e) {
      setCode('')
      const error = extractApiError(e) || e
      showError(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <S.Content>
      <S.CodeTitle>{t.get('login.email_code.code_sent')}</S.CodeTitle>
      <S.CodeSubtitle>
        {t.get('login.email_code.code_sent_detailed', { email })}
      </S.CodeSubtitle>
      <S.CodeForm onSubmit={handleCodeSubmit}>
        <S.LoginInput
          data-testid="embedded-login-code-input"
          autoFocus
          variant={VARIANTS.white}
          type="number"
          inputMode="numeric"
          placeholder="Enter Code"
          value={code}
          required
          onChange={handleCodeChanged}
        />
        <S.Continue
          data-testid="embedded-login-code-submit-button"
          type="submit"
          loading={isLoading}
          disabled={isLoading}
        >
          {t.get('new_email_capture.continue')}
        </S.Continue>
      </S.CodeForm>
      {isLoadingResendCode ? (
        <DotsLoader />
      ) : (
        <S.ResendCodeButton onClick={resendCode}>
          {t.get('login.email_code.resend_code')}
        </S.ResendCodeButton>
      )}
    </S.Content>
  )
}

export default EmbeddedLoginCodeDialog
