import { styled } from 'styled-components'
import Illustration from 'mixtiles-web-common/elements/Illustration/Illustration'
import { ILLUSTRATIONS } from 'mixtiles-web-common/consts/illustrations.const'
import {
  onSmallScreen,
  onMediumScreen,
  onExtraSmallScreen,
} from 'mixtiles-web-common'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  ${onSmallScreen()} {
    min-width: 400px;
  }

  ${onMediumScreen()} {
    width: 475px;
  }
`

export const DiscountIllustration = styled(Illustration).attrs({
  name: ILLUSTRATIONS.WALL_DESIGNS_TILES.LADY_WITH_4_FRAMES,
})`
  width: 190px;
  margin: 30px auto 8px auto;

  ${onSmallScreen()} {
    margin: 36px auto 0 auto;
  }
`

export const Title = styled.div`
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black};
`

export const Subtitle = styled.div`
  font-size: 18px;
  line-height: 23px;
  margin: 12px auto 36px;
  color: ${({ theme }) => theme.colors.black};
  max-width: 270px;
  text-align: center;

  ${onSmallScreen()} {
    max-width: 400px;
  }
`

export const ButtonContainer = styled.div`
  margin-bottom: 29px;

  ${onExtraSmallScreen()} {
    margin-bottom: 56px;
  }
`
