import { useState } from 'react'

export default function useIllustration(
  Illustration: (props: { onLoad: () => void }) => JSX.Element
) {
  const [isLoaded, setIsLoaded] = useState(false)

  return {
    illustration: <Illustration onLoad={() => setIsLoaded(true)} />,
    isIllustrationLoaded: isLoaded,
  }
}
