import { styled, css, type DefaultTheme } from 'styled-components'

interface MarginedProps {
  marginTop?: number
  marginBottom?: number
  marginLeft?: number
  marginRight?: number
  maxWidth?: number
  theme: DefaultTheme
  color?: keyof DefaultTheme['colors']
}

export const margined = css<MarginedProps>`
  margin-top: ${({ marginTop }) => marginTop || 0}px;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  margin-left: ${({ marginLeft }) => marginLeft || 0}px;
  margin-right: ${({ marginRight }) => marginRight || 0}px;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'inherit')};
`

const MarginedDiv = styled.div<MarginedProps>`
  ${({ theme, color }) =>
    color &&
    css`
      color: ${theme.colors[color] || color};
    `}
  ${margined}
`

export default MarginedDiv
