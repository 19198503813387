import { styled, css } from 'styled-components'
import { Popper } from '@mui/material'

export const Popover = styled(Popper)<{ $visible: boolean; $bottom: string }>`
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(50px);
  border-radius: 10px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  font-size: 17px;
  line-height: 130%;
  padding: 12px 16px;
  white-space: nowrap;
  z-index: 2000;

  top: inherit !important;
  left: 50% !important;
  bottom: ${({ $bottom }) => $bottom};

  visibility: hidden;
  transform: translate(-50%, 50%) scale(1.05);
  opacity: 0;
  transition:
    visibility 0s 0.3s,
    transform 0s 0.3s,
    opacity 0.3s;
  ${({ $visible }) =>
    $visible &&
    css`
      visibility: visible;
      transform: translate(-50%, 50%) scale(1);
      opacity: 1;
      transition:
        transform 0.3s,
        opacity 0.3s;
    `}
`
