import { styled } from 'styled-components'

export const Container = styled.div``

export const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  a,
  div {
    font-size: 14px;
    cursor: pointer;
    width: fit-content;
  }
`

export const Link = styled.a`
  text-decoration: none;
  color: unset;
`

export const LanguageContainer = styled.div`
  margin-top: 24px;

  .flag {
    display: none;
  }

  #language_selector_wrapper {
    .trigger {
      margin: 10px 0 30px 0;
    }
  }
`
