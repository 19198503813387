import { BookTextFonts, BookTextStyle } from '@mixtiles/web-backend-shared'

import mixtilesFontPath from '../../../media/fonts/MixtilesSans-500Medium.ttf'
import castoroPath from '../../../media/fonts/Castoro-Regular.ttf'
import caveatPath from '../../../media/fonts/Caveat-Regular.ttf'
import BebasNeuePath from '../../../media/fonts/BebasNeue-Regular.ttf'

export const DefaultTextStyle = BookTextStyle.Minimalist

export const DefaultFontHeight = 0.5

type Font = {
  id: BookTextStyle
  size: string
  weight: number
  fontFamily: string
  url?: string
}

export const styles: Font[] = [
  {
    id: BookTextStyle.Minimalist,
    size: '13.5px',
    weight: BookTextFonts[BookTextStyle.Minimalist].fontWeight,
    fontFamily: BookTextFonts[BookTextStyle.Minimalist].fontFamily,
  },
  {
    id: BookTextStyle.Classic,
    size: '14px',
    weight: BookTextFonts[BookTextStyle.Classic].fontWeight,
    fontFamily: BookTextFonts[BookTextStyle.Classic].fontFamily,
    url: 'https://fonts.googleapis.com/css2?family=Castoro:ital@0;1&display=swap',
  },
  {
    id: BookTextStyle.Handwriting,
    size: '17px',
    weight: BookTextFonts[BookTextStyle.Handwriting].fontWeight,
    fontFamily: BookTextFonts[BookTextStyle.Handwriting].fontFamily,
    url: 'https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap',
  },
  {
    id: BookTextStyle.BOLD,
    size: '21px',
    weight: BookTextFonts[BookTextStyle.BOLD].fontWeight,
    fontFamily: BookTextFonts[BookTextStyle.BOLD].fontFamily,
    url: 'https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap',
  },
]

export const fonts = {
  [BookTextStyle.Minimalist]: {
    font: mixtilesFontPath,
    fontSize: 0.0122,
  },
  [BookTextStyle.Classic]: {
    font: castoroPath,
    fontSize: 0.0122,
  },
  [BookTextStyle.Handwriting]: {
    font: caveatPath,
    fontSize: 0.0161,
  },
  [BookTextStyle.BOLD]: {
    font: BebasNeuePath,
    fontSize: 0.0366,
  },
}
