import { useEffect } from 'react'

export default function BrowserThemeColor({ color }: { color: string }) {
  useEffect(() => {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]')
    if (!metaThemeColor) {
      return
    }

    const currentColor = metaThemeColor.getAttribute('content')
    metaThemeColor.setAttribute('content', color)
    return () => {
      if (currentColor) {
        metaThemeColor.setAttribute('content', currentColor)
      }
    }
  }, [color])

  return <></>
}
