import React, { Component } from 'react'
import './DummySubmit.scss'

class DummySubmit extends Component {
  render() {
    return <input className="DummySubmit" type="submit" />
  }
}

export default DummySubmit
