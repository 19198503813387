import React from 'react'
import {
  Button as MuiButton,
  type ButtonProps as MuiButtonProps,
  alpha,
} from '@mui/material'
import { styled, css } from 'styled-components'
import { lighten } from 'polished'
import Spinner from '../../components/Spinner/Spinner'
import { ART_THEME } from '../Theme/artTheme'
import { PHOTOS_THEME } from '../Theme/photosTheme'

type ButtonProps = MuiButtonProps & {
  testId?: string
  loading?: boolean
}

export const BUTTON_SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
}

export const BUTTON_VARIANTS = {
  contained: 'contained',
  outlined: 'outlined',
}

const sizes = {
  [BUTTON_SIZES.small]: css`
    height: 28px;
    font-size: 10px;
  `,
  [BUTTON_SIZES.medium]: css`
    height: 37px;
  `,
  [BUTTON_SIZES.large]: css`
    height: 51px;
  `,
}

const variants = {
  [BUTTON_VARIANTS.outlined]: css`
    background: white;
    color: ${({ theme }) => theme.colors.primary};

    ${({ theme }) =>
      theme.name === ART_THEME &&
      css`
        border: 2px solid rgba(38, 31, 35, 0.2);

        &:hover {
          background: ${({ theme }) => theme.colors.hoverGray}
          border: solid 2px ${theme.colors.gray};
        }
      `}
  `,
  [BUTTON_VARIANTS.contained]: css`
    background: ${({ theme }) => theme.colors.primary};
  `,
}

const StyledMuiButton = styled(MuiButton)<ButtonProps>`
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;

  // Art theme button
  ${({ theme, variant }) =>
    theme.name === ART_THEME &&
    css`
      ${variant === BUTTON_VARIANTS.outlined &&
      css`
        border: solid 1px ${theme.colors.gray};
      `}

      &:disabled {
        opacity: 0.3;
        background: ${theme.colors.primary};
        color: ${theme.colors.white};
      }

      &:hover {
        background: ${lighten(0.1, theme.colors.primary)};
      }
    `}

  // Photos theme button
  ${({ theme, color, disabled }) =>
    theme.name === PHOTOS_THEME &&
    css`
      &:hover {
        box-shadow: ${!disabled &&
        `0 3px 12px ${
          color === 'primary'
            ? alpha(theme.colors.primary, 0.3)
            : alpha(theme.colors.secondaryDark, 0.3)
        }`};
      }
    `}

  ${({ size }) => sizes[size || BUTTON_SIZES.large]};
  ${({ variant }) => variants[variant!]};
`

function Button({
  children,
  loading,
  disabled,
  variant = 'contained',
  color = 'primary',
  testId,
  ...otherProps
}: ButtonProps) {
  return (
    <StyledMuiButton
      variant={variant}
      color={color}
      disabled={disabled}
      data-testid={testId}
      {...otherProps}
    >
      {loading ? <Spinner white /> : children}
    </StyledMuiButton>
  )
}

export default Button
