import React from 'react'
import * as S from './AlertPopup.styles'
import { GrowTransition } from 'components/Transitions/Transitions'
import useScreenSize from '../../hooks/useScreenSize'
import { ILLUSTRATIONS } from 'mixtiles-web-common/consts/illustrations.const'

type Props = {
  open: boolean
  title: string
  breakTitle?: boolean
  subtitle?: string
  buttonText: string
  onClick: () => void
  titleTestId?: string
}

export function AlertPopup({
  open,
  title,
  subtitle,
  buttonText,
  onClick,
  titleTestId,
  breakTitle = false,
}: Props) {
  const isMediumScreen = useScreenSize('md')
  const Content = (
    <S.Container>
      <S.ErrorIllustration
        name={ILLUSTRATIONS.MISC.MAN_WITH_EXCLAMATION_MARK}
      />
      <S.Title data-testid={titleTestId} $breakTitle={breakTitle}>
        {title}
      </S.Title>
      {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
      <S.ActionButton onClick={onClick}>{buttonText}</S.ActionButton>
    </S.Container>
  )

  if (isMediumScreen) {
    return (
      <S.Dialog
        disableEscapeKeyDown
        open={open}
        TransitionComponent={GrowTransition}
      >
        {Content}
      </S.Dialog>
    )
  } else {
    return (
      <S.BottomDrawer anchor="bottom" open={open}>
        {Content}
      </S.BottomDrawer>
    )
  }
}

export default AlertPopup
