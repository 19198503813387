import * as queryString from 'query-string'
import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import omit from 'lodash/omit'
import { ALL_UTM_PARAMS } from '../services/Analytics/platforms/utm'

/**
 * Omits a query param from a location object.
 */
export function omitLocationParam(location, paramName) {
  const allParams = new URLSearchParams(queryString.parse(location.search))
  allParams.delete(paramName)

  return {
    ...location,
    search: allParams.toString(),
  }
}

export function createLocation({
  location,
  state,
  keepUrlParams = false,
  endWithSlash = false,
}) {
  state = state || {}

  location = parseLocation(location, keepUrlParams)
  location.state = {
    ...state,
  }

  if (endWithSlash && location.pathname[location.pathname.length - 1] !== '/') {
    location.pathname += '/'
  }

  return location
}

export function getSearchParams(query, keepAllParams = false) {
  const location = window.location
  const allParams = queryString.parse(location.search)
  let params = keepAllParams ? allParams : pick(allParams, ALL_UTM_PARAMS)
  if (query) {
    params = { ...params, ...query }
  }

  return params
}

export function getQueryString(params) {
  return isEmpty(params) ? undefined : `?${queryString.stringify(params)}`
}

export function parseLocation(location, keepUrlParams = false) {
  let pathname
  let params
  let hash

  if (isString(location)) {
    pathname = location
    params = getSearchParams(null, keepUrlParams)
  } else {
    ;({ pathname, hash } = location)
    const { query } = location
    pathname = pathname || window.location.pathname
    params = getSearchParams(query, keepUrlParams)
  }

  // These params will always be omitted between page navigations
  params = omit(params, ['promo'])

  return { pathname, hash, search: getQueryString(params) }
}
