import pick from 'lodash/pick'
import {
  DISCOUNT_TYPE_BUNDLE,
  DISCOUNT_TYPE_BUYOVER,
  DISCOUNT_TYPE_BUYXGETY,
  DISCOUNT_TYPE_PERCENT,
  DISCOUNT_TYPE_SHIPPING_BUY_OVER,
  DISCOUNT_TYPE_UNIT,
  promoCodeManager,
} from '../../services/PromoCodeManager'
import {
  hashTilePricingProduct,
  MATERIAL_TYPES,
  SUMMARY_ITEM_TYPES,
  TILE_SIZES,
} from '@mixtiles/web-backend-shared'

import { roundedDecimalSumBy } from '../../utils/utils'
import { isValidGiftCard } from '../../pages/GiftCard/utils'
import { PRODUCT_TYPES } from '../../services/ProductTypeState'
import isEqual from 'lodash/isEqual'
import { getPricing } from '../../services/PricingUtils'
import { MINIMUM_FOR_FREE_SHIPPING_PRICING_TYPE } from '../../config/pricing'

export const NEW_PRICING_SUPPORTED_PRODUCT_TYPES = [
  PRODUCT_TYPES.CLASSIC,
  PRODUCT_TYPES.GIFT_CARD,
  PRODUCT_TYPES.BUSINESS,
  PRODUCT_TYPES.PHOTO_BOOK,
]

const PRICING_FEATURE_PREFIX = 'PRICING'
export const PRICING_ERROR_CONTEXT = 'pricing'
const CENTERPIECE_SKUS = [
  {
    tileSize: TILE_SIZES.RECTANGLE_25X8,
    materialType: MATERIAL_TYPES.FRAMELESS,
    frameColor: 'none',
  },
  {
    tileSize: TILE_SIZES.RECTANGLE_25X8,
    materialType: MATERIAL_TYPES.CANVAS,
    frameColor: 'none',
  },
]

const SQUARE_8X8_SKUS = [
  {
    tileSize: TILE_SIZES.SQUARE_8X8,
    materialType: MATERIAL_TYPES.CLASSIC,
  },
  {
    tileSize: TILE_SIZES.SQUARE_8X8,
    materialType: MATERIAL_TYPES.CANVAS,
  },
]

const CLASSIC_ORDER_SKUS = [...CENTERPIECE_SKUS, ...SQUARE_8X8_SKUS]

function generateDefaultMinimumOrderData(products, additionalTilesTotalPrice) {
  return {
    tilesToAdd: 0,
    minOrderSize: 0,
    minOrderPrice: 0,
    additionalTilesSize: products.filter(
      (product) =>
        product.priceAfterDiscount > 0 &&
        CENTERPIECE_SKUS.every(
          (centerPieceSku) =>
            centerPieceSku.tileSize !== product.pricingProduct?.tileSize ||
            centerPieceSku.materialType !== product.pricingProduct?.materialType
        )
    ).length,
    additionalTilesTotalPrice,
  }
}

export function formatAddress(address) {
  return {
    country: address.countryId,
    state: address.state,
    city: address.city,
    fullName: address.fullName,
    street: address.street,
    street2: address.address_2,
    zipCode: address.zipCode,
  }
}

export const getPricingAnalyticsData = (pricingSummary) => {
  if (!pricingSummary || Object.keys(pricingSummary).length === 0) {
    return null
  } else {
    return {
      'Discount ID': pricingSummary.discountId,
      'Discount Type': pricingSummary.discountType,
      'Percent Off': pricingSummary.discountPercent,
    }
  }
}

export const isPricingFeature = (feature) => {
  return feature.toUpperCase().startsWith(PRICING_FEATURE_PREFIX)
}

export function getPricingVariantId(feature, variantId) {
  return `${feature}_${variantId}`
}

// Get pricing features on mount of the app (except for the lazy pricing features)
export const getRelevantVariants = (experimentManager) => {
  return experimentManager
    .getAllFeatures()
    .filter(isPricingFeature)
    .filter((feature) => experimentManager.isEnabled(feature))
    .map((feature) =>
      getPricingVariantId(feature, experimentManager.getVariantKey(feature))
    )
}

const getPriceOfItem = (item) => {
  return [
    SUMMARY_ITEM_TYPES.TAX,
    SUMMARY_ITEM_TYPES.CREDITS,
    SUMMARY_ITEM_TYPES.REQUIREMENT,
  ].includes(item.type)
    ? item.price
    : item.priceAfterDiscount
}

export const formatPricingSummary = ({
  orderPriceSummary,
  discountId,
  orderType,
  currency,
  checkoutId,
  promoCode,
  productType,
  originalProducts,
}) => {
  const {
    summaryItems,
    requirements,
    selectedDiscounts,
    key,
    totalCost,
    priceBeforeTax,
    giftsBalances = [],
  } = orderPriceSummary

  const discountableItems = summaryItems.filter((item) =>
    [SUMMARY_ITEM_TYPES.PRODUCT, SUMMARY_ITEM_TYPES.SHIPPING].includes(
      item.type
    )
  )

  const products = discountableItems.filter(
    (item) => item.type === SUMMARY_ITEM_TYPES.PRODUCT
  )
  const taxItems = summaryItems.filter(
    (item) => item.type === SUMMARY_ITEM_TYPES.TAX
  )
  const shippingItem = summaryItems.find(
    (item) => item.type === SUMMARY_ITEM_TYPES.SHIPPING
  )
  const creditItem = summaryItems.find(
    (item) => item.type === SUMMARY_ITEM_TYPES.CREDITS
  )
  const giftCardCreditItems = summaryItems.filter(
    (item) => item.type === SUMMARY_ITEM_TYPES.GIFT_CARD
  )

  const [mixtilesPlusItem] = summaryItems.filter(
    (item) => item.type === SUMMARY_ITEM_TYPES.MIXTILES_PLUS
  )

  const nonCenterpieceProducts = products.filter(
    (product) =>
      !CENTERPIECE_SKUS.find((sku) => isEqual(sku, product.pricingProduct))
  )
  const discountPrice = roundedDecimalSumBy(
    discountableItems,
    (product) => product.priceBeforeDiscount - product.priceAfterDiscount
  )
  const tilesDiscount = roundedDecimalSumBy(
    nonCenterpieceProducts,
    (product) => product.priceBeforeDiscount - product.priceAfterDiscount
  )
  const taxAmount = roundedDecimalSumBy(taxItems, 'price')
  return {
    orderType,
    priceBeforeTax,
    discountPrice,
    totalPrice: totalCost,
    totalItems: products.length,
    totalTiles: nonCenterpieceProducts.length,
    tilePriceBeforeDiscount: roundedDecimalSumBy(
      nonCenterpieceProducts,
      (product) => product.priceBeforeDiscount
    ),
    tilePriceAfterDiscount: roundedDecimalSumBy(
      nonCenterpieceProducts,
      (product) => product.priceAfterDiscount
    ),
    paidTiles: nonCenterpieceProducts.filter(
      (product) => product.priceAfterDiscount > 0
    ).length,
    currency,
    tax: taxAmount,
    taxesDetailed: taxItems.map((taxItem) => ({
      amount: taxItem.price,
      name: taxItem.name,
    })),
    productType,
    priceBeforeAdditions: roundedDecimalSumBy(
      products.filter((product) => product.priceAfterDiscount > 0),
      'priceBeforeDiscount'
    ),
    priceAfterPercentageDiscount: roundedDecimalSumBy(
      products.filter((product) => product.priceAfterDiscount > 0),
      (product) =>
        product.priceAfterPercentageDiscount || product.priceBeforeDiscount
    ),
    tilesDiscount,
    ...getMinimumForOrderData(
      summaryItems,
      requirements,
      products,
      selectedDiscounts
    ),
    ...getCenterpiecesData(products),
    ...getBundlePriceData({ currency, products, selectedDiscounts }),
    discountAutomaticPercent:
      getAutomaticPercentageDiscountData(selectedDiscounts),
    discountPercent: getPercentageDiscountData(selectedDiscounts),
    buyOverDiscountData: getBuyOverDiscountData(selectedDiscounts, currency),
    buyXGetYDiscountData: getBuyXGetYDiscountData(selectedDiscounts),
    shippingBuyOverData: getShippingBuyOverDiscountData(selectedDiscounts),
    ...getFreeTilesData(selectedDiscounts, products),
    summaryItems,
    products,
    originalProducts,
    creditUsed: -(creditItem?.price || 0),
    giftCardCreditItems,
    mixtilesPlusItem,
    giftsBalances: giftsBalances.filter((g) => isValidGiftCard(g)),
    shippingCost: shippingItem?.priceAfterDiscount,
    shippingCostBeforeDiscount: shippingItem?.priceBeforeDiscount,
    isEligibleFreeShipping: shippingItem?.priceAfterDiscount === 0,
    ...getBaseDiscountInfo(selectedDiscounts, discountId, promoCode),
    checkoutId,
    discountId,
    validated: true,
    key,
    productTotalPrice: roundedDecimalSumBy(
      summaryItems.filter((item) => item.type === SUMMARY_ITEM_TYPES.PRODUCT),
      getPriceOfItem
    ),
    productTotalPriceBeforeDiscount: roundedDecimalSumBy(
      summaryItems.filter((item) => item.type === SUMMARY_ITEM_TYPES.PRODUCT),
      'priceBeforeDiscount'
    ),
    classicTilesPriceBeforeDiscount: roundedDecimalSumBy(
      summaryItems.filter(
        (item) =>
          item.type === SUMMARY_ITEM_TYPES.PRODUCT &&
          CLASSIC_ORDER_SKUS.some(
            (sku) =>
              hashTilePricingProduct(sku) ===
              hashTilePricingProduct(item.pricingProduct)
          )
      ),
      'priceBeforeDiscount'
    ),
    // Intentionally marking that the coupon did apply so the backend will determine if we should redeem the coupon or not
    discountCouponDidApply:
      promoCodeManager.getDiscountCouponDisplayName() !== null,
  }
}

export const getMinimumForOrderData = (
  summaryItems,
  requirements,
  products,
  selectedDiscounts
) => {
  const minOrderRequirement = requirements.details.find(
    (requirement) => requirement.type === 'MIN_FOR_ORDER'
  )

  const bundleDiscount = selectedDiscounts.find(
    (discount) => discount.type === DISCOUNT_TYPE_BUNDLE
  )
  const additionalTilesTotalPrice = roundedDecimalSumBy(
    summaryItems.filter((item) => item.type === SUMMARY_ITEM_TYPES.PRODUCT),
    'priceAfterDiscount'
  )

  const minPriceRequirement = summaryItems.filter(
    (item) => item.type === SUMMARY_ITEM_TYPES.REQUIREMENT
  )

  if (
    !minOrderRequirement ||
    bundleDiscount?.discountRedemption?.applied ||
    minPriceRequirement?.length
  ) {
    return {
      ...generateDefaultMinimumOrderData(products, additionalTilesTotalPrice),
      orderFeePrice: minPriceRequirement?.[0]?.price ?? null,
      minimumOrderPrice: requirements?.details?.[0]?.minPrice ?? null,
    }
  } else {
    const {
      quantityMissing,
      minOrderSize,
      price: minOrderPrice,
    } = minOrderRequirement
    const additionPricingItems = products.filter(
      (product) => !product.requirementData
    )
    return {
      tilesToAdd: quantityMissing,
      minOrderSize,
      minOrderPrice,
      additionalTilesSize: additionPricingItems.length,
      additionalTilesTotalPrice: roundedDecimalSumBy(
        additionPricingItems,
        'priceAfterDiscount'
      ),
    }
  }
}

export const getBundlePriceData = ({
  currency,
  products,
  selectedDiscounts,
}) => {
  let bundlePriceData = {
    bundleTiles: 0,
    bundlePrice: 0,
    howManyToBundlePrice: 0,
    bundlePriceTilesCount: 0,
    totalBundlePrice: 0,
    tileSizesList: [],
    bundleSizesMapping: {},
  }

  const bundleDiscount = selectedDiscounts.find(
    (discount) => discount.type === DISCOUNT_TYPE_BUNDLE
  )
  if (bundleDiscount) {
    const { quantity, price } = bundleDiscount.bundleData
    const { quantityForNextDiscount, totalBundlePrice, applied } =
      bundleDiscount.discountRedemption
    const { tileSize, materialType } = bundleDiscount.pricingProduct
    const bundleProducts = products.filter(
      (product) => product.bundleIndex >= 0
    )
    bundlePriceData = {
      bundleProductPrice:
        bundleProducts.length > 0
          ? {
              value: bundleProducts[0].priceBeforeDiscount,
              currency,
            }
          : undefined,
      bundleTiles: quantity,
      bundlePrice: price,
      howManyToBundlePrice: quantityForNextDiscount,
      bundlePriceTilesCount: bundleProducts.length,
      totalBundlePrice,
      tileSizesList: [tileSize],
      materialType,
      applied,
    }
  }

  return {
    bundleDiscountData: pick(bundlePriceData, [
      'bundleTiles',
      'bundlePrice',
      'tileSizesList',
    ]),
    bundlePriceData,
  }
}

export const getCenterpiecesData = (products) => {
  const centerpieceProducts = products.filter((product) =>
    CENTERPIECE_SKUS.find((sku) => isEqual(sku, product.pricingProduct))
  )
  const centerpiecesPriceAfterDiscount = roundedDecimalSumBy(
    centerpieceProducts,
    'priceAfterDiscount'
  )
  const centerpiecePriceBeforeDiscount = roundedDecimalSumBy(
    centerpieceProducts,
    'priceBeforeDiscount'
  )
  return {
    centerpiecesTotalPrice: centerpiecePriceBeforeDiscount,
    centerpiecesDiscount:
      centerpiecePriceBeforeDiscount - centerpiecesPriceAfterDiscount,
    centerpiecesCount: centerpieceProducts.length,
  }
}

export const getFreeTilesData = (selectedDiscounts, products) => {
  const freeTilesDiscount = selectedDiscounts.find(
    (discount) => discount.type === DISCOUNT_TYPE_UNIT
  )
  if (freeTilesDiscount) {
    const { appliedCreditsData } = freeTilesDiscount.discountRedemption
    const formattedAppliedCredits = appliedCreditsData.map((credit) => ({
      amount: credit.quantity,
      used: credit.used,
      sizes: credit.products,
    }))
    const freeTilesAvailable = roundedDecimalSumBy(
      formattedAppliedCredits,
      'amount'
    )
    const freeTilesUsed = roundedDecimalSumBy(formattedAppliedCredits, 'used')
    return {
      freeTilesData: {
        freeTilesCreditTotal: freeTilesAvailable,
        tileCreditsData: formattedAppliedCredits,
        freeTilesCreditUsed: freeTilesUsed,
      },
      freeTiles: products.filter((product) => product.priceAfterDiscount === 0)
        .length,
      unusedFreeTiles: freeTilesAvailable - freeTilesUsed,
    }
  } else {
    return {
      freeTilesData: {
        freeTilesCreditTotal: 0,
        freeTilesCreditUsed: 0,
        tilesCreditsData: [],
      },
      unusedFreeTiles: 0,
      freeTiles: 0,
    }
  }
}

export const getBuyOverDiscountData = (selectedDiscounts, currency) => {
  const buyOverDiscountObj = selectedDiscounts.find(
    (discount) => discount.type === DISCOUNT_TYPE_BUYOVER
  )
  if (!buyOverDiscountObj) {
    return null
  }

  const { buyOverPrice, buyOverDiscountPercentage } =
    buyOverDiscountObj.buyOverData
  const { missingAmountForDiscount, applied } =
    buyOverDiscountObj.discountRedemption
  return {
    buyOverPrice,
    buyOverDiscount: buyOverDiscountPercentage,
    missingAmountForDiscount,
    applied,
    bundleCurrency: currency,
  }
}

export const getAutomaticPercentageDiscountData = (selectedDiscounts) => {
  const percentageDiscount = selectedDiscounts.find(
    (discount) =>
      discount.type === DISCOUNT_TYPE_PERCENT && discount.isAutomatic
  )

  return percentageDiscount?.percentageData?.percentOff
}

export const getPercentageDiscountData = (selectedDiscounts) => {
  const percentageDiscount = selectedDiscounts.find(
    (discount) => discount.type === DISCOUNT_TYPE_PERCENT
  )
  if (percentageDiscount) {
    return percentageDiscount.discountRedemption?.percentOff
  }

  return null
}

export const getBuyXGetYDiscountData = (selectedDiscounts) => {
  const buyXGetYDiscount = selectedDiscounts.find(
    (discount) => discount.type === DISCOUNT_TYPE_BUYXGETY
  )
  if (buyXGetYDiscount) {
    const { buy, get } = buyXGetYDiscount.buyXGetYData
    const {
      howManyForNextFree,
      usedCredit,
      unusedCredit,
      applied,
      amountSaved,
    } = buyXGetYDiscount.discountRedemption
    return {
      buy,
      get,
      howManyForNextFree,
      usedCredit,
      unusedCredit,
      applied,
      amountSaved,
    }
  } else {
    return null
  }
}

export const getShippingBuyOverDiscountData = (selectedDiscounts) => {
  const shippingBuyOverDiscount = selectedDiscounts.find(
    (discount) => discount.type === DISCOUNT_TYPE_SHIPPING_BUY_OVER
  )
  return shippingBuyOverDiscount?.shippingBuyOverData || null
}

export const getBaseDiscountInfo = (
  selectedDiscounts,
  discountId,
  promoCode
) => {
  if (discountId && discountId !== '') {
    const discount = selectedDiscounts.find(
      (discount) => discount.discountId === discountId
    )
    return {
      discountType: discount?.type,
      promoCode,
    }
  } else {
    return {
      discountType: promoCodeManager.getDiscountCouponType(),
      promoCode: promoCodeManager.getDiscountCouponCode(),
    }
  }
}

export const getAdditionalShippingPrice = ({ tilesCount }) => {
  const countryPricing = getPricing()
  if (
    countryPricing.pricingType === MINIMUM_FOR_FREE_SHIPPING_PRICING_TYPE &&
    tilesCount < countryPricing.minTilesForFreeShipping &&
    !promoCodeManager.getTilesCreditsAmount()
  ) {
    return countryPricing.shippingCost
  }
  return 0
}
