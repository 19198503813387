import {
  MATERIAL_TYPES,
  TILE_SIZES,
  hashTilePricingProduct,
} from '@mixtiles/web-backend-shared'
import memoize from 'lodash/memoize'

const _canvasItem = (tileSize) =>
  hashTilePricingProduct({
    tileSize,
    materialType: MATERIAL_TYPES.CANVAS,
  })

export const CANVAS_PRICING = memoize(() => {
  return {
    [_canvasItem(TILE_SIZES.RECTANGLE_6X8)]: { regularTilePrice: 39 },
    [_canvasItem(TILE_SIZES.RECTANGLE_8X6)]: { regularTilePrice: 39 },
    [_canvasItem(TILE_SIZES.SQUARE_8X8)]: { regularTilePrice: 45 },
    [_canvasItem(TILE_SIZES.RECTANGLE_11X8)]: { regularTilePrice: 49 },
    [_canvasItem(TILE_SIZES.RECTANGLE_8X11)]: { regularTilePrice: 49 },
    [_canvasItem(TILE_SIZES.SQUARE_12X12)]: { regularTilePrice: 59 },
    [_canvasItem(TILE_SIZES.RECTANGLE_16X12)]: { regularTilePrice: 65 },
    [_canvasItem(TILE_SIZES.RECTANGLE_12X16)]: { regularTilePrice: 65 },
    [_canvasItem(TILE_SIZES.RECTANGLE_16X20)]: { regularTilePrice: 89 },
    [_canvasItem(TILE_SIZES.RECTANGLE_20X16)]: { regularTilePrice: 89 },
    [_canvasItem(TILE_SIZES.SQUARE_20X20)]: { regularTilePrice: 109 },
    [_canvasItem(TILE_SIZES.RECTANGLE_20X27)]: { regularTilePrice: 125 },
    [_canvasItem(TILE_SIZES.RECTANGLE_27X20)]: { regularTilePrice: 125 },
    [_canvasItem(TILE_SIZES.RECTANGLE_27X36)]: { regularTilePrice: 159 },
    [_canvasItem(TILE_SIZES.RECTANGLE_36X27)]: { regularTilePrice: 159 },
    [_canvasItem(TILE_SIZES.RECTANGLE_25X8)]: { regularTilePrice: 55 },
    [_canvasItem(TILE_SIZES.RECTANGLE_22X44)]: { regularTilePrice: 189 },
    [_canvasItem(TILE_SIZES.RECTANGLE_44X22)]: { regularTilePrice: 189 },
  }
})
