import * as S from '../EmbeddedLogin.styles'
import React, { useState } from 'react'
import { useAuth } from '../../../services/AuthProvider/AuthProvider'
import { analytics, EVENT_NAMES } from 'services/Analytics/Analytics'
import { translateManager as t } from 'services/TranslateManager'
import TermsOfUse from '../TermsOfUse'
import Divider from '../../../pages/HomePage/Components/Divider/Divider'
import { EmbeddedLoginForm } from './EmbeddedLoginForm'
import useScreenSize from '../../../hooks/useScreenSize'
import { experimentManager } from '../../../services/ExperimentManager/ExperimentManager'
import { isAndroid } from 'react-device-detect'
import { Link } from '@remix-run/react'

function EmbeddedLoginDialog({
  showError,
  setShowCode,
  email,
  setEmail,
  source,
  redirectTo,
  isMixtilesPlusFlow,
  onLoginProcessStart,
  onDismiss,
}) {
  const [emailSelected, setEmailSelected] = useState(false)
  const { authorize } = useAuth()
  const isMediumScreen = useScreenSize('md')

  const handleSocialLogin = async ({
    loginType,
    eventName,
    redirectUri = null,
  }) => {
    analytics.track(eventName, { type: loginType })
    try {
      onLoginProcessStart?.()
      await authorize(loginType, redirectUri)
    } catch (e) {
      showError(e)
    }
  }
  const handleGoogleLogin = async () => {
    await handleSocialLogin({
      loginType: 'google-oauth2',
      eventName: EVENT_NAMES.LOGIN_SOCIAL_LOGIN_GOOGLE,
      redirectUri: experimentManager.isEnabled('straight-to-photos')
        ? redirectTo
        : null,
    })
  }

  const handleAppleLogin = async () => {
    await handleSocialLogin({
      loginType: 'apple',
      eventName: EVENT_NAMES.LOGIN_SOCIAL_LOGIN_APPLE,
      redirectUri: experimentManager.isEnabled('straight-to-photos')
        ? redirectTo
        : null,
    })
  }

  const getTitle = () => {
    if (source === 'cta') {
      return t.get('login.start_your_design_journey')
    }
    if (isMixtilesPlusFlow) {
      return 'Log in or Sign up to Join Mixtiles+'
    }
    return t.get('login.login_or_sign_up')
  }

  const loginForm = (
    <EmbeddedLoginForm
      showError={showError}
      email={email}
      setEmail={setEmail}
      setShowCode={setShowCode}
    />
  )

  return (
    <S.Content>
      {emailSelected && !isMediumScreen ? (
        loginForm
      ) : (
        <>
          <S.Title $isMixtilesPlusFlow={isMixtilesPlusFlow}>
            {getTitle()}
          </S.Title>
          {isMediumScreen && (
            <>
              {loginForm}
              <S.Ors>
                <S.OrLineLeft />
                <S.Or>or</S.Or>
                <S.OrLineRight />
              </S.Ors>
            </>
          )}
          <S.SocialButtons>
            <S.SocialButton onClick={handleGoogleLogin}>
              <S.SocialButtonContent>
                <S.IconContainer>
                  <S.Google />
                </S.IconContainer>
                <S.SocialButtonText>
                  {t.get('login.continue_with')} Google
                </S.SocialButtonText>
              </S.SocialButtonContent>
            </S.SocialButton>
            {!isAndroid && (
              <S.SocialButton onClick={handleAppleLogin}>
                <S.SocialButtonContent>
                  <S.IconContainer>
                    <S.Apple />
                  </S.IconContainer>
                  <S.SocialButtonText>
                    {t.get('login.continue_with')} Apple
                  </S.SocialButtonText>
                </S.SocialButtonContent>
              </S.SocialButton>
            )}
            {!isMediumScreen && (
              <S.SocialButton onClick={() => setEmailSelected(true)}>
                <S.SocialButtonContent>
                  <S.IconContainer>
                    <S.Letter />
                  </S.IconContainer>
                  <S.SocialButtonText>
                    {t.get('login.continue_with')} Email
                  </S.SocialButtonText>
                </S.SocialButtonContent>
              </S.SocialButton>
            )}
          </S.SocialButtons>
          {experimentManager.getVariantKey('straight-to-photos') ===
            'treatment-with-skip' && source === 'cta' ? (
            <>
              <Divider transparent margin={10} />
              <Link to={redirectTo}>
                <S.Skip>{t.get('general.skip')}</S.Skip>
              </Link>
              <Divider transparent margin={14} />
            </>
          ) : (
            <Divider transparent margin={20} />
          )}
          <TermsOfUse onDismiss={onDismiss} />
        </>
      )}
    </S.Content>
  )
}

export default EmbeddedLoginDialog
