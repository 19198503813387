import StorageManager from './StorageManager'
import { MIXTILES_ART_URL, MIXTILES_PHOTOS_URL } from '../config/config'
import SessionStorageManager from './SessionStorageManager'
import { logger } from './logger'
import { mixtilesAxios } from '../utils/ApiUtils'
import { PRODUCT_TYPES, productTypeState } from './ProductTypeState'
import { analytics } from './Analytics/Analytics'
import { remixNavigationManager } from 'services/remixNavigationManager'

const LOGIN_STATE_KEY = 'loginState'
export const ACTIVE_ORDERS_COUNT_KEY = 'activeOrdersCount'
export const LOGIN_POPUP_SHOWN_KEY = 'loginPopupShown'
export const IS_NEW_ACCOUNT_KEY = 'isNewAccount'
export const MISSING_EMAIL_ERROR = 'missingEmail'
export const IS_LOGIN_FLOW_PARAM = 'isLoginFlow'
export const DID_PERFORM_LOGIN = 'didPerformLogin'

export const getAuthRequestConfig = (accessToken) =>
  accessToken ? { headers: { Authorization: `Bearer ${accessToken}` } } : {}

const getLoginSource = () => {
  const { url, options } = getLocationBeforeLogin()
  return options.source || `Other (${url.pathname})`
}

export const loginReport = async ({ token, email, isEmbeddedLogin }) => {
  try {
    const config = getAuthRequestConfig(token)
    const response = await mixtilesAxios.get('v1/auth/loginSuccess', config)
    const { activeOrdersCount, analyticsInfo, migrationDone } =
      response && response.data
    StorageManager.set(
      ACTIVE_ORDERS_COUNT_KEY,
      migrationDone ? activeOrdersCount : 0
    )
    StorageManager.set(DID_PERFORM_LOGIN, true)
    if (analyticsInfo) {
      const { isNewUser, authMethod, previousOrdersCount, isAccountVerified } =
        analyticsInfo
      StorageManager.set(IS_NEW_ACCOUNT_KEY, isNewUser)
      const eventName = isNewUser ? 'User Created an Account' : 'User logged in'
      const source = getLoginSource()
      const eventData = {
        'Login Method': authMethod,
        'Number of Previous Orders': previousOrdersCount,
        Email: email,
        'Is Account Verified': isAccountVerified,
        Source: source,
        'Is Embedded Login': isEmbeddedLogin,
      }
      analytics.track(eventName, eventData)
      return isAccountVerified
    }
  } catch (e) {
    logger.error('failed to handle login success', e)
  }
  return true
}

// TODO: THIS SHOULDN'T BE HERE! orders have nothing to do with login need to destructure and re-organize
export const getOrdersForAccount = async (accessToken) => {
  const config = getAuthRequestConfig(accessToken)
  const response = await mixtilesAxios.get('v5/myOrders', config)
  const orders = response && response.data && response.data.orders
  const { activeOrders } = orders
  StorageManager.set(ACTIVE_ORDERS_COUNT_KEY, activeOrders.length)
  return orders
}

export const sendVerificationEmail = async (accessToken) => {
  const config = getAuthRequestConfig(accessToken)
  await mixtilesAxios.post('/v1/auth/resendVerificationEmail', {}, config)
}

export const increaseActiveOrderForAccount = () => {
  const ordersCount = StorageManager.getWithDefaultValue(
    ACTIVE_ORDERS_COUNT_KEY,
    0
  )
  StorageManager.set(ACTIVE_ORDERS_COUNT_KEY, ordersCount + 1)
}

export const saveStateBeforeLogin = ({ source = null, targetUrl = null }) => {
  const currentState = {
    url: { pathname: window.location.pathname },
    options: { source },
    targetUrl,
  }
  SessionStorageManager.set(LOGIN_STATE_KEY, currentState)
}

export const saveQueryParamsBeforeLogin = (queryParams) => {
  const { url, options, targetUrl } = getLocationBeforeLogin()
  url.search = queryParams
  const currentState = {
    url,
    options,
    targetUrl,
  }
  SessionStorageManager.set(LOGIN_STATE_KEY, currentState)
}

export const getDefaultLocationBeforeLogin = () => {
  switch (productTypeState.getProductType()) {
    case PRODUCT_TYPES.ART:
      return MIXTILES_ART_URL
    default:
      return MIXTILES_PHOTOS_URL
  }
}

export const getLocationBeforeLogin = () => {
  const pathname = getDefaultLocationBeforeLogin()
  const defaultValue = {
    url: { pathname, query: {} },
    options: {},
    targetUrl: null,
  }
  return SessionStorageManager.getWithDefaultValue(
    LOGIN_STATE_KEY,
    defaultValue
  )
}

export const redirectAfterLogin = ({
  isVerified = false,
  loginError = null,
}) => {
  const { url, options, targetUrl } = getLocationBeforeLogin()
  if (targetUrl) {
    const [pathname, search] = targetUrl.split('?')
    url.pathname = pathname
    url.search = search
  }

  if (loginError) {
    url.query = { ...url.query, loginError }
  } else if (!isVerified) {
    url.query = { ...url.query, verifyAccount: true }
  }

  remixNavigationManager.navigate(url, { state: options, replace: true })
}

export function didPerformLogin() {
  return !!StorageManager.get(DID_PERFORM_LOGIN)
}
