import StorageManager from './StorageManager'
import { OtefProjectViewStore } from './otefProjectViewStore'

const GIFT_NOTE_KEY = 'giftNote'

class GiftNoteManager {
  getGiftNote() {
    if (OtefProjectViewStore.isEnabled()) {
      return (
        'Dear family,\n\n' +
        'We hope these Mixtiles can give a little sense of home.\n' +
        'Our thoughts are with you,\n\n' +
        'Mixtiles'
      )
    }
    return StorageManager.get(GIFT_NOTE_KEY)
  }

  setGiftNote(giftNote) {
    StorageManager.set(GIFT_NOTE_KEY, giftNote)
  }

  clearData() {
    StorageManager.remove(GIFT_NOTE_KEY)
  }
}

export const giftNoteManager = new GiftNoteManager()
