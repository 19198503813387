import { COUNTRY_PRICING, EU_COUNTRIES } from '../config/pricing'
import * as countryService from './countryService'

class CountryManager {
  // TODO: This needs to move out of here
  getPricingCountry(inputCountry) {
    const country = inputCountry || this.getCountry()
    return country && country.toLowerCase() in COUNTRY_PRICING ? country : 'US'
  }

  getCountry() {
    return countryService.getCountry()
  }

  getState() {
    return countryService.getState()
  }

  isClientFromEU() {
    return EU_COUNTRIES.includes(this.getCountry().toUpperCase())
  }

  isClientFromGB() {
    return this.getCountry().toUpperCase() === 'GB'
  }
}

export const countryManager = new CountryManager()
