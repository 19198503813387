import { styled } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
`

export const Text = styled.span`
  font-size: 17px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.secondaryDark};
`

export const Image = styled.img`
  width: 140px;
`
