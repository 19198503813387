import React from 'react'
import { styled, css } from 'styled-components'
import { ELEMENT_SIZE, MarginedDiv } from 'mixtiles-web-common'

const smallVariant = css`
  font-weight: 400;
  font-size: 14px;
  text-decoration-line: underline;
`

const StyledMarginedDiv = styled(MarginedDiv)`
  display: inline-block;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  color: ${({ color, theme }) => color || theme.colors.gray};
  ${({ size }) => size === ELEMENT_SIZE.small && smallVariant};
`

function LinkButton({ onClick, color, size, children, ...otherProps }) {
  return (
    <StyledMarginedDiv
      onClick={onClick}
      color={color}
      size={size}
      {...otherProps}
    >
      {children}
    </StyledMarginedDiv>
  )
}

export default LinkButton
