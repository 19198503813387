import { mixtilesAxios } from '../utils/ApiUtils'

export const PATH = 'mixtiles-plus/info'

export const getMixtilesPlusData = async (
  variantIds: string[],
  pricingCountry: string,
  email?: string
) => {
  const response = await mixtilesAxios.get(PATH, {
    params: {
      variantIds,
      pricingCountry,
      email,
    },
  })
  return response.data
}
