import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import auth0 from 'auth0-js'
import useOnMount from '../../hooks/useOnMount'
import {
  getDefaultLocationBeforeLogin,
  loginReport,
  saveStateBeforeLogin,
} from '../LoginUtills'
import { logger } from 'services/logger'
import { jwtDecode } from 'jwt-decode'
import { mixtilesAxios } from '../../utils/ApiUtils'
import { LOGIN_CALLBACK_URL } from '../../config/config'
import { useKeys } from 'services/KeysProvider'
import { isClient, isServer } from 'utils/runtimeUtils'
import { useTranslation } from 'react-i18next'
import { useLanguage } from 'services/LangProvider'

export const AuthContext = createContext({
  isAuthenticated: false,
  user: {},
  isLoading: false,
})

function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthContextProvider')
  }

  return context
}

function AuthProviderImpl({ children }) {
  const {
    loginWithRedirect,
    isLoading: isAuth0Loading,
    isAuthenticated: isAuth0Authenticated,
    getAccessTokenSilently: getAuth0AccessToken,
    user: auth0User,
    logout: auth0Logout,
    error,
  } = useAuth0()

  const [auth0Client, setAuth0Client] = useState(null)
  const [isEmbededLoginAuthenticated, setIsEmbeddedLoginAuthenticated] =
    useState(false)
  const embeddedLoginUser = useRef(null)
  const tokenData = useRef(null)
  const [isEmbeddedLoginLoading, setIsEmbeddedLoginLoading] = useState(false)

  useOnMount(() => {
    setIsEmbeddedLoginLoading(true)
    initAuth0()
  })

  const initProvider = async () => {
    try {
      const response = await mixtilesAxios.get('v1/auth/token')
      const currentTokenData = response.data
      if (currentTokenData) {
        await finalizeAuth(currentTokenData)
        tokenData.current = currentTokenData
        setIsEmbeddedLoginAuthenticated(true)
      }
    } catch (e) {
    } finally {
      setIsEmbeddedLoginLoading(false)
    }
  }

  useEffect(() => {
    if (auth0Client) {
      initProvider()
    }
  }, [auth0Client])

  useEffect(() => {
    ;(async () => {
      const idToken = await getIdToken()
      if (isEmbededLoginAuthenticated && !embeddedLoginUser.current) {
        embeddedLoginUser.current = await getUserInfo(idToken)
        setIsEmbeddedLoginLoading(false)
      }
    })()
  }, [isEmbededLoginAuthenticated])

  const setSession = (authResult, profile) => {
    tokenData.current = authResult
    embeddedLoginUser.current = profile
  }

  const authorize = async (connection, redirectTo = null) => {
    const options = {
      connection,
      prompt: 'select_account',
    }

    saveStateBeforeLogin({ targetUrl: redirectTo })
    return loginWithRedirect(options)
  }

  const clearAuthData = async () => {
    await mixtilesAxios.post('v1/auth/logout')
  }

  const logout = (returnToUrl) => {
    const pathname = getDefaultLocationBeforeLogin()
    const returnTo = returnToUrl || window.location.origin + pathname
    clearAuthData().then(() => {
      if (isAuth0Authenticated) {
        auth0Logout({ returnTo })
      } else {
        auth0Client.logout({
          clientID: window.KEYS.auth0clientId,
          returnTo,
        })
      }
      embeddedLoginUser.current = null
    })
  }

  const initAuth0 = () => {
    const { auth0domain, auth0audience, auth0clientId } = window.KEYS
    // Initialize a new instance of the Auth0 application.
    const webAuthConfig = {
      domain: auth0domain,
      clientID: auth0clientId,
      audience: auth0audience,
      responseType: 'token id_token',
    }
    const auth0Client = new auth0.WebAuth(webAuthConfig)
    setAuth0Client(auth0Client)
  }

  const finalizeAuth = async (authResult) => {
    const user = await getUserInfo(authResult.idToken)
    setSession(authResult, user)
  }

  const getUserInfo = async (idToken) => {
    return jwtDecode(idToken)
  }

  const handleEmbeddedLogin = async () => {
    const token = await getAccessToken()
    const email = embeddedLoginUser?.current?.email
    const isAccountVerified = await loginReport({
      token,
      email,
      isEmbeddedLogin: true,
    })

    if (!isAccountVerified) {
      logger.error(
        'account is not verified, this should never happen in embedded login'
      )
    }
    return isAccountVerified
  }

  const getAccessToken = async () => {
    if (isAuth0Authenticated) {
      return getAuth0AccessToken()
    }
    return tokenData?.current?.accessToken || null
  }

  const getIdToken = async () => {
    return tokenData?.current?.idToken || null
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: isAuth0Authenticated || isEmbededLoginAuthenticated,
        getAccessToken,
        finalizeAuth,
        authorize,
        loginWithRedirect,
        logout,
        handleEmbeddedLogin,
        user: isAuth0Authenticated ? auth0User : embeddedLoginUser?.current,
        isLoading: isAuth0Loading || isEmbeddedLoginLoading,
        error,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function AuthProvider({ children }) {
  const { t } = useTranslation()
  const language = useLanguage()
  const { auth0domain, auth0audience, auth0clientId } = useKeys()

  const languageDictionary = useMemo(() => {
    const contents = JSON.stringify({
      title: t('general.welcome'),
      signUpWithLabel: `${t('login.log_in_with')} %s`,
      passwordlessEmailAlternativeInstructions: t('general.or'),
      emailInputPlaceholder: t('login.email_capture_placeholder'),
      submitLabel: t('new_email_capture.continue'),
    })
    if (isServer()) {
      return Buffer.from(contents).toString('base64')
    }

    return window.btoa(contents)
  }, [])

  return (
    <Auth0Provider
      domain={auth0domain}
      audience={auth0audience}
      clientId={auth0clientId}
      redirectUri={
        isClient() ? window.location.origin + LOGIN_CALLBACK_URL : undefined
      }
      cacheLocation="localstorage"
      ui_locales={language}
      languageDictionary={languageDictionary}
    >
      <AuthProviderImpl>{children}</AuthProviderImpl>
    </Auth0Provider>
  )
}

export { AuthProvider, useAuth }
