import React, { Component } from 'react'
import './CloseButton.scss'
import classNames from 'classnames'
import { ReactComponent as CloseIcon } from './close-icon.svg'

class CloseButton extends Component {
  render() {
    const { circle, full, pink, dark, className, action, dataTestId } =
      this.props
    const closeButtonClass = classNames(
      'CloseButton',
      { circle, full, pink, dark },
      className
    )
    return (
      <div
        className={closeButtonClass}
        onClick={action}
        data-testid={dataTestId}
      >
        <div className="icon-wrapper">
          <CloseIcon />
        </div>
      </div>
    )
  }
}

export default CloseButton
