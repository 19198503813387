import React from 'react'
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from '../../config/config'
import { translateManager as t } from '../../services/TranslateManager'
import * as S from './EmbeddedLogin.styles'

export default function TermsOfUse({ onDismiss }: { onDismiss: () => void }) {
  return (
    <S.Terms>
      {`${t.get('login.terms_of_use.terms_of_use_intro')} `}
      <S.LoginLink to={TERMS_OF_SERVICE_URL}>
        <span onClick={onDismiss}>
          {t.get('login.terms_of_use.terms_of_use')}
        </span>
      </S.LoginLink>{' '}
      {`${t.get('login.terms_of_use.and')} `}
      <S.LoginLink to={PRIVACY_POLICY_URL}>
        <span onClick={onDismiss}>
          {t.get('login.terms_of_use.privacy_policy')}
        </span>
      </S.LoginLink>
    </S.Terms>
  )
}
