import { styled } from 'styled-components'

export const Container = styled.div`
  display: flex;
  gap: 18px;
  margin-left: 26px;
`

export const Avatar = styled.div``

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 3px;
`
