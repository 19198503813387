import './MenuItem.scss'
import React, { type MouseEvent, type MouseEventHandler } from 'react'
import classNames from 'classnames'
import { Badge } from '@mui/material'
import Icon from '../../Icon/Icon'
import { useProductType } from '../../../services/ProductTypesManager'
import * as S from './MenuItem.styles'
import { analytics } from '../../../services/Analytics/Analytics'
import NewBadge from '../../Badge/Badge.styles'
import { type Location } from '@remix-run/router'
import { Link } from '@remix-run/react'
import { PRODUCT_TYPES } from '../../../services/ProductTypeState'

type MenuItemProps = {
  itemId: string
  icon: React.ReactNode
  text: string
  location?: Location
  target?: string
  onClick?: MouseEventHandler
  highlighted?: boolean
  tag?: number
  chevron?: boolean
  isNew?: boolean
  testId?: string
}

export function MenuItem({
  itemId,
  icon,
  text,
  location,
  target = '_self',
  onClick,
  highlighted,
  tag = 0,
  chevron,
  isNew,
  testId,
}: MenuItemProps) {
  const { productType } = useProductType()
  const classes = classNames('MixtilesMenuItem', { highlighted })
  const iconElement = productType === PRODUCT_TYPES.ART ? null : icon

  const handleClick = (e: MouseEvent) => {
    onClick?.(e)
    if (itemId === 'PROMO_CODE') {
      return
    }
    itemId && analytics.track('Menu Item Tapped', { Item: itemId })
  }

  const menuItemContents = (
    <>
      {iconElement}
      <S.Text>{text}</S.Text>
      {tag > 0 && (
        <div className="badge">
          <Badge badgeContent={tag} color="primary" />
        </div>
      )}
      {chevron && <Icon className="chevron-icon" icon="greyChevron" />}
      {isNew && <NewBadge>NEW</NewBadge>}
    </>
  )

  // If location is specified, wrap it in a React Router link
  if (location) {
    return (
      <Link
        style={{ textDecoration: 'none', color: 'unset' }}
        to={location.pathname}
        state={location.state}
        target={target}
        className={classes}
        onClick={handleClick}
        data-testid={testId}
      >
        {menuItemContents}
      </Link>
    )
  } else {
    return (
      <div className={classes} onClick={handleClick} data-testid={testId}>
        {menuItemContents}
      </div>
    )
  }
}
