import { withStyles } from '@mui/styles'
import { Drawer as MuiDrawer, Dialog as MuiDialog } from '@mui/material'
import { styled } from 'styled-components'

export const Drawer = styled(MuiDrawer)(
  ({ $maxHeight, $fullHeight, $noBorderRadius }) => ({
    zIndex: '1602 !important',
    '.MuiDrawer-paper': {
      borderRadius: $noBorderRadius ? '0' : '16px 16px 0 0',
      maxHeight: $maxHeight || '65%',
      height: $fullHeight ? '100%' : 'initial',
    },
  })
)

export const Dialog = withStyles({
  root: {
    zIndex: '1602 !important',
  },
  paper: {
    borderRadius: '24px',
  },
  scrollPaper: {
    alignItems: 'center',
  },
})(MuiDialog)
