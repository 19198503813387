import { styled } from 'styled-components'
import { withStyles } from '@mui/styles'
import { Drawer as MuiDrawer, Dialog as MuiDialog } from '@mui/material'
import { onMediumScreen, onSmallScreen } from 'mixtiles-web-common'
import Button from 'mixtiles-web-common/ui/Button'
import Illustration from 'mixtiles-web-common/elements/Illustration/Illustration'
import { ILLUSTRATIONS } from 'mixtiles-web-common/consts/illustrations.const'

export const BottomDrawer = withStyles({
  root: {
    zIndex: '1600 !important',
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '16px 16px 0 0',
    padding: '40px 0 40px',
    maxHeight: '100%',
  },
})(MuiDrawer)

export const Dialog = withStyles({
  paper: {
    borderRadius: '16px',
    padding: '54px 0 52px',
  },
})(MuiDialog)

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 380px;

  ${onMediumScreen()} {
    width: 380px;
  }
`

type TitleProps = {
  $breakTitle?: boolean
}

export const Title = styled.div<TitleProps>`
  font-size: 24px;
  line-height: 120%;
  font-weight: 700;
  max-width: 302px;
  overflow-wrap: break-word;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  white-space: ${({ $breakTitle }) => $breakTitle && 'pre-line'};
`

export const Subtitle = styled.div`
  font-size: 16px;
  font-style: normal;
  line-height: 130%;
  font-weight: 400;
  max-width: 270px;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  margin-top: 12px;
`

export const ActionButton = styled(Button)`
  min-width: 229px;
  min-height: 50px;
  margin-top: 28px;
  ${onMediumScreen()} {
    min-width: 220px;
    min-height: 52px;
    margin-top: 36px;
  }
`

export const ErrorIllustration = styled(Illustration).attrs({
  name: ILLUSTRATIONS.MISC.MAN_WITH_EXCLAMATION_MARK,
})`
  width: 107px;
  height: 137px;
  margin: 0 141px 33px 142px;

  ${onSmallScreen()} {
    margin: 0 138px 30px 138px;
  }
`
