import { styled, keyframes } from 'styled-components'
import { onMediumScreen } from 'mixtiles-web-common'

const bounce = () => keyframes`
  100% {
    transform: translateY(-9px);
  }
`

export const Loader = styled.div`
  margin: 36px auto 56px auto;
  ${onMediumScreen()} {
    margin: 36px auto 40px auto;
  }
  width: fit-content;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  span:nth-of-type(2) {
    animation-delay: 100ms;
  }
  span:nth-of-type(3) {
    animation-delay: 200ms;
  }
`

export const Dot = styled.span`
  height: 7px;
  width: 7px;
  border-radius: 10px;
  margin-left: 4px;
  background-color: ${({ theme }) => theme.colors.grayDark};
  transform: translateY(0);
  animation: ${bounce} 550ms infinite alternate;
`
