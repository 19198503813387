import { getSelectedSize } from './SizesManager'
import { MIXED_SIZES } from '../pages/PhotoStyler/TileDesignerConsts'
import { getDiscountMaterialType } from './discountMaterial'

export function getBuyXgetYCredit(tilesCount, metadata) {
  let usedCredit = 0
  let unusedCredit = 0
  let howManyForNextFree = null
  let amountOfTimesTheDealWasApplied = 0

  if (metadata != null && getSelectedSize() !== MIXED_SIZES) {
    const { buy, get } = metadata
    amountOfTimesTheDealWasApplied = Math.floor(tilesCount / (buy + get))

    const currentDiscountTilesCount = tilesCount % (buy + get)
    const currentDiscountEnabled = currentDiscountTilesCount >= buy
    const currentDiscountUsed = Math.max(currentDiscountTilesCount - buy, 0)
    usedCredit = amountOfTimesTheDealWasApplied * get + currentDiscountUsed
    unusedCredit = currentDiscountEnabled ? get - currentDiscountUsed : 0

    // we only return howManyForNextFree if it's under the value of the 'buy' in the promo
    howManyForNextFree =
      currentDiscountTilesCount && !currentDiscountEnabled
        ? buy - currentDiscountTilesCount
        : null
  }

  return {
    usedCredit,
    unusedCredit,
    howManyForNextFree,
    amountOfTimesTheDealWasApplied,
  }
}

export function getBundlePriceData(tilesCount, metadata) {
  let bundleTiles = 0
  let bundlePrice = 0
  let howManyToBundlePrice = 0
  let bundlePriceTilesCount = 0
  let totalBundlePrice = 0
  let tileSizesList = []
  let bundleSizesMapping = {}
  const materialType = getDiscountMaterialType(metadata)

  if (metadata) {
    ;({
      bundleTiles,
      bundlePrice,
      tileSizesList,
      bundleSizesMapping = {},
    } = metadata)
    howManyToBundlePrice =
      (bundleTiles - (tilesCount % bundleTiles)) % bundleTiles // the last part is to avoid "12 tiles to 12 for 99"
    const amountOfTimesTheBundleHasBeenApplied = Math.floor(
      tilesCount / bundleTiles
    )
    bundlePriceTilesCount = amountOfTimesTheBundleHasBeenApplied * bundleTiles
    totalBundlePrice = amountOfTimesTheBundleHasBeenApplied * bundlePrice
  }

  return {
    bundleTiles,
    bundlePrice,
    howManyToBundlePrice,
    bundlePriceTilesCount,
    totalBundlePrice,
    tileSizesList,
    bundleSizesMapping,
    materialType,
  }
}
