import storageManager from '../services/StorageManager'
import { MATERIAL_TYPES } from '@mixtiles/web-backend-shared'
import { logger } from '../services/logger'
import { TILES_SOURCE } from '../components/TilesProvider/TilesProvider.consts'
import { MIXED_MATERIALS } from '../pages/PhotoStyler/TileDesignerConsts'
import { getStorageKeyBySource } from '../components/TilesProvider/TilesSourceContext'

export const MATERIAL_TYPE_KEY = 'materialType'

export function loadMaterialType(tilesSource) {
  return storageManager.get(
    getStorageKeyBySource(MATERIAL_TYPE_KEY, tilesSource)
  )
}

export function saveMaterialType(materialType, tilesSource) {
  if (!materialType) {
    return
  } else if (!validateMaterialType(materialType)) {
    logger.warning('Invalid material type: ', materialType)
    return
  }
  storageManager.set(
    getStorageKeyBySource(MATERIAL_TYPE_KEY, tilesSource),
    materialType
  )
}

export function validateMaterialType(materialType, tileSource) {
  if (
    tileSource !== TILES_SOURCE.JUMBLE &&
    materialType === MATERIAL_TYPES.STICKER
  ) {
    return false
  }
  return Object.values(MATERIAL_TYPES).includes(materialType)
}

export function isFramedMaterial(materialType) {
  return [MATERIAL_TYPES.CLASSIC, MATERIAL_TYPES.WIDE_FRAME].includes(
    materialType
  )
}

export function getPricingMaterialType(material) {
  // We do not support mixed materials in the backend currently so when using mixed materials we will use the discounts
  // of the most expensive material
  if (material === MIXED_MATERIALS) {
    return MATERIAL_TYPES.WIDE_FRAME
  }
  return material
}
