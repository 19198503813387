import StorageManager from './StorageManager'
import UserManager from './UserManager'
import { COUNTRIES, REGIONS } from '../config/countries-data'
import { mixtilesAxios } from '../utils/ApiUtils'
import { translateManager as t } from './TranslateManager'
import { analytics } from './Analytics/Analytics'
import isEqual from 'lodash/isEqual'
import { getTaxInfo } from '../config/taxation'
import { logger } from './logger'
import { ADDRESSES_KEY } from '../utils/AddressUtils'
import { isServer } from 'utils/runtimeUtils'

const ADDRESSES_VALIDATION_SKIP_KEY = 'skipAddressValidation'
// Address validation timeout is set because we don't want the user to wait a lot of time
// if the backend responds slowly, and we prefer to enable the user to finish it's order without
// validating the address.
const ADDRESS_VALIDATION_TIMEOUT_MS = 6 * 1000

const TAXES_FOR_ADDRESS_KEY = 'taxesForAddress'

const ERROR_CODES = {
  countryUnsupported: 'E.COUNTRY.UNSUPPORTED',
  engineUnavailable: 'E.ENGINE.UNAVAILABLE',
  queryUnanswerable: 'E.QUERY.UNANSWERABLE',
  addressNotFound: 'E.ADDRESS.NOT_FOUND',
  secondaryInformationInvalid: 'E.SECONDARY_INFORMATION.INVALID',
  secondaryInformationMissing: 'E.SECONDARY_INFORMATION.MISSING',
  houseNumberMissing: 'E.HOUSE_NUMBER.MISSING',
  houseNumberInvalid: 'E.HOUSE_NUMBER.INVALID',
  streetMissing: 'E.STREET.MISSING',
  streetInvalid: 'E.STREET.INVALID',
  boxNumberMissing: 'E.BOX_NUMBER.MISSING',
  boxNumberInvalid: 'E.BOX_NUMBER.INVALID',
  addressInvalid: 'E.ADDRESS.INVALID',
  zipNotFound: 'E.ZIP.NOT_FOUND',
  zipInvalid: 'E.ZIP.INVALID',
  stateZipMismatch: 'E.ZIP.STATE_MISMATCH',
  zipPlus4NotFound: 'E.ZIP.PLUS4.NOT_FOUND',
  addressMultiple: 'E.ADDRESS.MULTIPLE',
  addressInsufficient: 'E.ADDRESS.INSUFFICIENT',
  addressDual: 'E.ADDRESS.DUAL',
  streetMagnet: 'E.STREET.MAGNET',
  cityStateInvalid: 'E.CITY_STATE.INVALID',
  stateInvalid: 'E.STATE.INVALID',
  addressDeliveryInvalid: 'E.ADDRESS.DELIVERY.INVALID',
  timedOut: 'E.TIMED_OUT',
  timeZoneUnavailable: 'E.TIME_ZONE.UNAVAILABLE',
  poBoxInternational: 'E.PO_BOX.INTERNATIONAL',
}

class AddressManager {
  constructor() {
    if (isServer()) return
    this.addressValidationSkipped = StorageManager.get(
      ADDRESSES_VALIDATION_SKIP_KEY
    )
    this.taxForAddress = StorageManager.get(TAXES_FOR_ADDRESS_KEY)
  }

  removeAddressIfNotSupported() {
    const address = this.getCurrentAddress()
    if (address && !(address.countryId in COUNTRIES)) {
      // Saved address is of a country we no longer support, remove it and the tax for it
      this.setCurrentAddress(null)
      this.setTaxForAddress(null)
      analytics.track('Unsupported address removed', {
        country: address.countryId,
      })
      logger.info(
        'Saved address country is no longer supported, removing it.',
        { countryId: address.countryId }
      )
    }
  }

  getCurrentAddress() {
    return StorageManager.get(ADDRESSES_KEY)
  }

  getCurrentNormalizedAddress() {
    return this.normalizeAddress(this.getCurrentAddress())
  }

  shouldValidateAddressInServer() {
    return !this.addressValidationSkipped
  }

  clearData() {
    StorageManager.remove(ADDRESSES_KEY)
    StorageManager.remove(ADDRESSES_VALIDATION_SKIP_KEY)
    StorageManager.remove(TAXES_FOR_ADDRESS_KEY)
  }

  setCurrentAddress(address, persist = true) {
    StorageManager.set(ADDRESSES_KEY, address)

    if (address) {
      if (address.fullName) {
        // TODO: Remove on next iteration
        UserManager.setUserFullName(address.fullName, persist)
      }
    }
  }

  getTaxForAddress() {
    return this.taxesForAddress
  }

  async getTotalTaxRateForAddress() {
    if (!this.getCurrentAddress()) {
      return 0
    }

    let taxForAddress = this.getTaxForAddress()
    if (!taxForAddress || taxForAddress.isEmpty) {
      await this.updateTaxForCurrentAddress()
    }

    taxForAddress = this.getTaxForAddress()
    if (!taxForAddress || taxForAddress.isEmpty) {
      // Fallback if we failed getting taxes from the server
      taxForAddress = getTaxInfo({ address: this.getCurrentAddress() })
      taxForAddress =
        taxForAddress &&
        taxForAddress.map((taxObject) => {
          taxObject.rate = taxObject.percent / 100
          return taxObject
        })
    }

    let taxRate = 0
    if (taxForAddress && !taxForAddress.isEmpty) {
      for (const taxObject of taxForAddress) {
        taxRate += taxObject.rate
      }
    }

    return taxRate
  }

  setTaxForAddress(taxesForAddress) {
    this.taxesForAddress = taxesForAddress
    StorageManager.set(TAXES_FOR_ADDRESS_KEY, taxesForAddress)
  }

  async updateTaxForCurrentAddress() {
    await this._updateTaxFromBackend({
      address: this.normalizeAddress(this.getCurrentAddress()),
    })
  }

  async updateTaxForAddress({ address }) {
    // Don't recalculate if address hasn't changed
    const currentAddress = this.getCurrentAddress()
    if (isEqual(currentAddress, address)) {
      return
    }

    const normalizedAddress = this.normalizeAddress(address)
    await this._updateTaxFromBackend({ address: normalizedAddress })
  }

  async _updateTaxFromBackend({ address }) {
    if (!address) {
      this.setTaxForAddress(null)
      return
    }

    const data = { address }
    try {
      const response = await mixtilesAxios.post(
        'v3/avatax/taxByAddress',
        data,
        { timeout: ADDRESS_VALIDATION_TIMEOUT_MS }
      )
      const { taxes } = response.data
      this.setTaxForAddress(taxes)
    } catch (e) {
      // If the request fails, we want to use the FE's default taxes
      this.setTaxForAddress(null)
      // Status code 400 means address is invalid, we don't want to error on it
      if (!e.response || e.response.status !== 400) {
        logger.error('Failed Receiving Tax from BE', e)
      }
    }
  }

  shouldFetchAddress() {
    return !this.getCurrentAddress()
  }

  setAddressFromServer(address) {
    this.setCurrentAddress(this.denormalizeAddress(address), false)
    this.setSkipAddressValidation(address.shouldValidate)
    this.updateTaxForCurrentAddress()
  }

  // Converts to the local format.
  // This function is the opposite of normalize address
  denormalizeAddress(address) {
    if (!address || !Object.keys(address).length) {
      return null
    }

    return {
      fullName: address.fullName,
      street: address.streetAddress,
      address_2: address.streetAddress2,
      city: address.city,
      state: address.state || '',
      zipCode: address.zip,
      countryId: (
        Object.values(COUNTRIES).find(({ code }) => code === address.country) ||
        {}
      ).id,
      phoneNumber: address.phoneNumber,
    }
  }

  normalizeAddress(address) {
    if (
      !address ||
      !Object.keys(address).length ||
      !(address.countryId.toUpperCase() in COUNTRIES)
    ) {
      return null
    }

    return {
      fullName: address.fullName,
      streetAddress: address.street,
      streetAddress2: address.address_2,
      city: address.city,
      state: address.state || '',
      zip: address.zipCode,
      country: COUNTRIES[address.countryId].code,
      phoneNumber: address.phoneNumber,
      shouldValidate: this.shouldValidateAddressInServer(),
    }
  }

  setSkipAddressValidation(skip) {
    this.addressValidationSkipped = skip
    StorageManager.set(ADDRESSES_VALIDATION_SKIP_KEY, skip)
  }

  isGlobalShippingCountry() {
    const address = this.getCurrentAddress()
    if (!address) {
      return false
    }
    return COUNTRIES[address.countryId].region === REGIONS.GLOBAL
  }

  validateAddress(address) {
    const {
      fullName,
      countryId,
      street,
      address_2: address2,
      city,
      state,
      zipCode,
    } = address

    if (this.isPOBoxAddressOutsideUS(countryId, street)) {
      return Promise.resolve({
        isValid: false,
        allowEditAddress: false,
        validationErrors: [
          this.getMessageForEpostValidationError(
            ERROR_CODES.poBoxInternational
          ),
        ],
      })
    }

    const addressValues = {
      name: fullName,
      country: countryId,
      street1: street,
      street2: address2,
      city,
      state: state || '',
      zip: zipCode,
    }
    return this._callValidateAddress(addressValues)
  }

  isPOBoxAddressOutsideUS(countryId, street) {
    if (!countryId) {
      return false
    }

    if (countryId.toUpperCase() === 'UNITED_STATES') {
      return false
    }

    if (!street) {
      return false
    }
    return (
      !!street.match(/\b(p\.?o\.?\s*)?box\b/i) ||
      !!street.match(/\bp\.?o\.?b\.?\b/i)
    )
  }

  _callValidateAddress(address) {
    const data = {
      ...address,
      requiresOldFreeTiles: true,
    }

    return mixtilesAxios
      .post('v1/address/validate', data, {
        timeout: ADDRESS_VALIDATION_TIMEOUT_MS,
      })
      .then((response) => {
        const { isValid, validationErrors } = response.data
        if (isValid) {
          return { isValid: true, allowEditAddress: true }
        }

        analytics.track('Address Validation Failed', {
          Error: JSON.stringify(validationErrors),
        })
        const validationErrorMessages = validationErrors
          .map((error) => this.getMessageForEpostValidationError(error.code))
          .filter((message) => message !== '')
        if (validationErrorMessages.length === 0) {
          validationErrorMessages.push(
            t.get('general.address_form.validation_errors.address.invalid')
          )
        }
        return {
          isValid,
          allowEditAddress: true,
          validationErrors: validationErrorMessages,
        }
      })
  }

  getMessageForEpostValidationError(errorCode) {
    switch (errorCode) {
      case ERROR_CODES.countryUnsupported:
        return t.get(
          'general.address_form.validation_errors.country.unsupported'
        )
      case ERROR_CODES.addressInvalid:
        return t.get('general.address_form.validation_errors.address.invalid')
      case ERROR_CODES.addressNotFound:
        return t.get('general.address_form.validation_errors.address.not_found')
      case ERROR_CODES.secondaryInformationInvalid:
        return t.get(
          'general.address_form.validation_errors.secondary_information.invalid'
        )
      case ERROR_CODES.secondaryInformationMissing:
        return t.get(
          'general.address_form.validation_errors.secondary_information.missing'
        )
      case ERROR_CODES.houseNumberMissing:
        return t.get(
          'general.address_form.validation_errors.house_number.missing'
        )
      case ERROR_CODES.houseNumberInvalid:
        return t.get(
          'general.address_form.validation_errors.house_number.invalid'
        )
      case ERROR_CODES.streetMissing:
        return t.get('general.address_form.validation_errors.street.missing')
      case ERROR_CODES.streetInvalid:
        return t.get('general.address_form.validation_errors.street.invalid')
      case ERROR_CODES.zipNotFound:
        return t.get('general.address_form.validation_errors.zip.not_found')
      case ERROR_CODES.stateZipMismatch:
        return t.get(
          'general.address_form.validation_errors.zip.state_mismatch'
        )
      case ERROR_CODES.zipInvalid:
        return t.get('general.address_form.validation_errors.zip.invalid')
      case ERROR_CODES.cityStateInvalid:
        return t.get(
          'general.address_form.validation_errors.city_state.invalid'
        )
      case ERROR_CODES.stateInvalid:
        return t.get('general.address_form.validation_errors.state.invalid')
      case ERROR_CODES.poBoxInternational:
        return t.get('general.address_form.validation_errors.street.po_box')
      default:
        return ''
    }
  }
}

export const addressManager = new AddressManager()
