import { styled } from 'styled-components'
import { onLargeScreen } from 'mixtiles-web-common'

import { VSignIcon } from '../../../icons'

// add border only to the top, left, and bottom
export const QualityGuaranteeContainer = styled.div`
  display: flex;
  width: min-content;
  box-sizing: border-box;
  border-radius: 16px;
  border: 1px solid #eae9e7;
  padding: 24px 0px 24px 24px;
  width: calc(100% - 24px);
  flex-direction: row;
  justify-content: space-between;
  margin: 27px 12px;
`

export const QualityGuaranteeImageContainer = styled.div`
  display: flex;
  min-width: 75px;
  max-width: 75px;
`

export const QualityGuaranteeTextContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`
export const QualityGuaranteeTitleContainer = styled.div`
  display: flex;
  gap: 8px;
  align-self: stretch;
`

export const QualityGuaranteeIcon = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
`

export const QualityGuaranteeTitle = styled.div`
  padding-top: 2px;
  color: #2b0514;
  font-feature-settings: 'case' on;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`

export const QualityGuaranteeDescription = styled.div`
  color: #472c35;
  font-feature-settings: 'case' on;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.01em;

  ${onLargeScreen()} {
    font-size: 14px;
  }
`

export const QualityGuaranteeVSignIcon = styled(VSignIcon)`
  height: 20px;
  width: 20px;
`

export const QualityGuaranteeMessagesIcon = styled.img`
  height: 70px;
  width: 70px;
  opacity: 0.5;
  padding-left: 20px;
`
