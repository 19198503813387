import { styled } from 'styled-components'
import React, { type ReactNode } from 'react'
import MarginedDiv from '../MarginedDiv/MarginedDiv'

export interface FlexProps {
  center?: boolean
  baseline?: boolean
  column?: boolean
  row?: boolean
  wrap?: boolean
  justify?: string
  align?: string
  children: ReactNode
}

const FlexContainer = styled(MarginedDiv)<FlexProps>`
  display: flex;
  ${({ center }) =>
    center &&
    `
    justify-content: center;
    align-items: center;
  `}
  ${({ baseline }) =>
    baseline &&
    `
    align-items: baseline;
  `}
  ${({ column }) =>
    column &&
    `
    flex-direction: column;
  `}
  ${({ row }) =>
    row &&
    `
    flex-direction: row;
  `}
  ${({ wrap }) =>
    wrap &&
    `
    flex-wrap: wrap;
  `}
  ${({ justify }) =>
    justify &&
    `
    justify-content: ${justify};
  `}
  ${({ align }) =>
    align &&
    `
    align-items: ${align};
  `}
`

function Flex({ children, ...otherProps }: FlexProps) {
  return <FlexContainer {...otherProps}>{children}</FlexContainer>
}

export default Flex
