import { styled, css } from 'styled-components'
import RadioButton from '../../RadioButton/RadioButton'

export const Coupon = styled.div<{
  $last?: boolean
  $disabled: boolean
}>`
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 14px;
  width: 100%;
  padding-top: 4px;
  padding-bottom: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  border-right: solid 1px ${({ theme }) => theme.colors.grayAccent};
  border-left: solid 1px ${({ theme }) => theme.colors.grayAccent};
  box-sizing: border-box;
  ${(props) =>
    props.$last &&
    css`
      border-radius: 0 0 10px 10px;
      border-bottom: solid 1px ${({ theme }) => theme.colors.grayAccent};
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
    `}
`

export const CouponRadioButton = styled(RadioButton)<{ $disabled: boolean }>`
  height: fit-content;
  padding-left: 25px;

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

export const CouponBody = styled.div<{ $disabled: boolean }>`
  display: flex;
  flex-direction: column;
  height: 40px;

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

export const CouponTitle = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 135%;
`
export const CouponSubtitle = styled.div`
  color: ${({ theme }) => theme.colors.grayDark};
  font-size: 15px;
  font-weight: 400;
  line-height: 135%;
`
