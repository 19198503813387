import * as S from './MixtilesPlusData.styles'
import { MixtilesPlusInfo } from './components/MixtilesPlusInfo'
import dateformat from 'dateformat'

interface MixtilesPlusDataProps {
  onDismiss: () => void
  open: boolean
  validUntil: Date
  percentageDiscount: number
}

export function MixtilesPlusData({
  onDismiss,
  open,
  validUntil,
  percentageDiscount,
}: MixtilesPlusDataProps) {
  return (
    <S.Popup
      open={open}
      onClose={onDismiss}
      maxHeight="initial"
      noBorderRadius
      fullHeight
    >
      <S.Content>
        <S.HeaderContainer>
          <S.HeaderText>My Mixtiles+</S.HeaderText>
          <div onClick={onDismiss}>
            <S.CloseButton />
          </div>
        </S.HeaderContainer>
        <S.MixtilesPlusValidUntilContainer>
          <S.MixtilesPlusValidUntilText>
            {`Offer valid until ${dateformat(validUntil, 'mmmm d, yyyy')}`}
          </S.MixtilesPlusValidUntilText>
        </S.MixtilesPlusValidUntilContainer>
        <S.MixtilesPlusInfoWrapper>
          <MixtilesPlusInfo
            text={`Get extra ${percentageDiscount}% off on all orders `}
            imageSrc="https://res.cloudinary.com/mixtiles/image/upload/v1721110522/assets/mixtilesPlus/Big_percentage_sign_with_two_ladies_1_1_1.svg"
          />
          <S.Separator />
          <MixtilesPlusInfo
            text="Free shipping on all orders"
            imageSrc="https://res.cloudinary.com/mixtiles/image/upload/v1721110522/assets/mixtilesPlus/Delivery_boy_01_1_1.svg"
          />
        </S.MixtilesPlusInfoWrapper>
      </S.Content>
    </S.Popup>
  )
}
