import * as S from './Illustration.styles'
import { CLOUDINARY_BASE_URL } from '../../consts/cloudinary.const.js'

const ILLUSTRATIONS_PATH = 'assets/illustrations'

interface IllustrationProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  name: string
  ext?: string
}

export default function Illustration({
  name,
  ext = 'png',
  ...props
}: IllustrationProps) {
  const optimization = ext === 'svg' ? '' : 'f_auto,q_auto:good'
  const url = `${CLOUDINARY_BASE_URL}/${optimization}/${ILLUSTRATIONS_PATH}/${name}.${ext}`
  return <S.Image alt={name} src={url} {...props} />
}
