export const MENU_ITEMS = {
  HELP_CENTER: 'HELP_CENTER',
  BLOG: 'BLOG',
  PROMO_CODE: 'PROMO_CODE',
  MY_ORDERS: 'MY ORDERS',
  GIFT_CARD: 'GIFT CARD',
  REFERRAL: 'REFERRAL',
  ART: 'ART',
  JUMBLE: 'JUMBLE',
  CHAT_WITH_US: 'TALK_TO_US',
  MIXTILES_CLASSIC: 'FRAME_YOUR_PHOTOS',
  DISCOVER_ARTWORKS: 'DISCOVER_ARTWORKS',
  TAKE_THE_QUIZ: 'TAKE_THE_QUIZ',
  CATEGORIES: 'CATEGORIES',
  ROOMS: 'ROOMS',
  GALLERY_WALLS: 'GALLERY_WALLS',
  PHOTO_BOOK: 'PHOTO_BOOK',
  BUSINESS: 'BUSINESS',
}
