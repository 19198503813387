import { styled } from 'styled-components'
import Button from 'mixtiles-web-common/ui/Button'

export const LoginStyledBox = styled.div<{ $isLoggedIn: boolean }>`
  padding: 0 0 ${($isLoggedIn) => (!$isLoggedIn ? 0 : '4px')};
`
export const GuestContentBox = styled.div`
  white-space: pre-line;
`
export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0 20px;
`
export const UserPhoto = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  box-sizing: border-box;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  img {
    width: 40px;
    height: auto;
    object-fit: cover;
  }
`

export const Username = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.black};
`

export const LoginMessage = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black};
  max-width: 220px;
`

export const Content = styled.div`
  margin-left: 18px;
`

export const Cta = styled(Button)`
  margin-top: 14px;
`
