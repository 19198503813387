import React, { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import * as S from './EmbeddedLogin.styles'
import MixtilesDialog from '../MixtilesDialog/MixtilesDialog'
import EmbeddedLoginContent from './EmbeddedLoginContent'
import { useAuth } from '../../services/AuthProvider/AuthProvider'
import storageManager from '../../services/StorageManager'
import {
  IS_NEW_ACCOUNT_KEY,
  LOGIN_POPUP_SHOWN_KEY,
  saveStateBeforeLogin,
} from '../../services/LoginUtills'
import { SNACKBAR_VARIANTS } from '../../elements/Snackbar/Snackbar.consts'
import { translateManager as t } from '../../services/TranslateManager'
import { ORDER_DRAFT_TILES_STORAGE_KEY } from '../TilesProvider/TilesProvider.consts'
import { analytics, EVENT_NAMES } from '../../services/Analytics/Analytics'
import useScreenSize from '../../hooks/useScreenSize'
import BrowserThemeColor from '../ThemeColor/BrowserThemeColor'
import { photosTheme as theme } from 'mixtiles-web-common'

function EmbeddedLogin({
  onDismiss,
  open = false,
  isMixtilesPlusFlow = false,
  onLoginProcessStart,
  source,
  redirectTo,
}) {
  const isMediumScreen = useScreenSize('md')
  const { isAuthenticated } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (open) {
      saveStateBeforeLogin({ source })
      analytics.track(EVENT_NAMES.LOGIN_OR_SIGN_UP_DIALOG_OPENED, { source })
    }
  }, [open])

  useEffect(() => {
    if (isAuthenticated) {
      onDismiss()
      if (!storageManager.get(LOGIN_POPUP_SHOWN_KEY)) {
        storageManager.set(LOGIN_POPUP_SHOWN_KEY, true)
        if (
          storageManager.getWithDefaultValue(ORDER_DRAFT_TILES_STORAGE_KEY, [])
            .length <= 0
        ) {
          const message = storageManager.get(IS_NEW_ACCOUNT_KEY)
            ? t.get('login.register_successfully')
            : t.get('login.login_successfully')
          enqueueSnackbar('', {
            variant: SNACKBAR_VARIANTS.COMMON,
            title: message,
            name: 'Embedded Login',
          })
        }
      }
    }
  }, [isAuthenticated])

  const embeddedLoginContent = (
    <EmbeddedLoginContent
      isMixtilesPlusFlow={isMixtilesPlusFlow}
      onLoginProcessStart={onLoginProcessStart}
      source={source}
      redirectTo={redirectTo}
      onDismiss={onDismiss}
    />
  )

  return (
    <>
      {isMediumScreen ? (
        <MixtilesDialog open={open} onDismiss={onDismiss} maxWidth="387px">
          {embeddedLoginContent}
        </MixtilesDialog>
      ) : (
        <S.BottomDrawer anchor="bottom" open={open} onClose={onDismiss}>
          {open && <BrowserThemeColor color={theme.colors.dialogGray} />}
          {embeddedLoginContent}
        </S.BottomDrawer>
      )}
    </>
  )
}

export default EmbeddedLogin
