import StorageManager from './StorageManager'
import { analytics, EVENT_NAMES } from './Analytics/Analytics'
import { updateAccountApi } from '../api/account.api'
import { triggerApi } from '../api/apiProvider'
import { logger } from './logger'
import * as userEmail from './userEmail'
import { getCountrySource, getIPAddress, getLocale } from './countryService'
import { ServerSideAnalyticsPlatform } from './Analytics/ServerSideAnalytics'
import { setFacebookAdvancedMatching } from './Analytics/platforms/facebook'
import { userState } from './User/userState'
import { mixtilesAxios } from '../utils/ApiUtils'
import { translateManager } from './TranslateManager'
import { quicklookSupportExists } from '../utils/browserUtils'
import { getUserBrowserProperties } from '../utils/utils'
import { Subject } from 'rxjs'
import { experimentManager } from './ExperimentManager/ExperimentManager'
import { isServer } from 'utils/runtimeUtils'
import { PRODUCT_TYPES, productTypeState } from './ProductTypeState'
import axios from 'axios'
import { getAmplitudeDeviceId } from './Analytics/platforms/amplitude'

const USER_FULL_NAME_KEY = 'userName'
const ART_USER_KEY = 'Art User'
export const LAST_VISIT_DATE_KEY = 'lastVisitDate'
export const CAPTURE_EMAIL_SOURCE = {
  login: 'Login',
  onboarding: 'Onboarding',
  checkoutAddress: 'Checkout Address',
  checkoutPayment: 'Checkout Payment',
  checkoutGiftCard: 'Checkout Gift Card',
  artOnboarding: 'Art Onboarding',
  artProductPage: 'Art Product Page',
  crmLead: 'CRM Lead',
  orderCompleted: 'Order Completed',
}

const onEmailChange = new Subject()
const onUserChange = new Subject()

export const getUserToken = () => UserManager.getUser().username

let initialUserParams

class UserManager {
  static init() {
    if (isServer()) return
    const email = UserManager.getUserEmail()
    if (email) {
      setFacebookAdvancedMatching(email)
    }
  }

  static setIsNewEmail(isNewEmail) {
    // This actually indicates if this email is new in the current session
    // and will be used for the create new order analytics
    this._isNewEmail = isNewEmail

    const email = this.getUserEmail()
    if (isNewEmail && email) {
      analytics.track(
        'New Email Captured',
        { Email: email },
        { serverSideAnalyticsPlatforms: [ServerSideAnalyticsPlatform.Facebook] }
      )
    }
  }

  static isNewEmail() {
    return this._isNewEmail
  }

  static getUser() {
    return userState.getUser()
  }

  static subscribeToExperimentFetch(callback) {
    return onEmailChange.subscribe(callback)
  }

  static subscribeToUserChange(callback) {
    return onUserChange.subscribe(callback)
  }

  static clearData() {
    userEmail.clearUserEmail()
    StorageManager.remove(USER_FULL_NAME_KEY)
  }

  static initializeUserProperties() {
    const { countryCode, country } = getLocale()
    analytics.setCountryCode(countryCode)
    analytics.setUserProperties({
      'User Country': country,
      'User Country Source': getCountrySource(),
      'User IP Address': getIPAddress(),
      'User Language': translateManager.getLanguage(),
      'AR Quick Look Support': quicklookSupportExists(),
      ...getUserBrowserProperties(),
    })
  }

  static onUUidSet(unifiedUidToUse, isExistingUser) {
    const localDataToSet = {
      ...userState.getUser(),
      isExistingUser,
      unifiedUid: unifiedUidToUse,
    }
    userState.setUser(localDataToSet)

    onUserChange.next(localDataToSet)
  }

  static async setUserEmail({
    email,
    source,
    onboardingFlow,
    refetchExperiments = true,
  }) {
    const previousEmail = UserManager.getUserEmail()
    const currUser = userState.getUser()
    if (!email) {
      return
    }

    logger.info('setUserEmail func triggered', {
      email,
      previousEmail,
      currUser,
    })

    analytics.track(EVENT_NAMES.SET_USER_EMAIL_TRIGGERED, {
      Email: email,
      previousEmail,
      currUser,
    })

    // in case we never set the unified Uid before and we still have the same email to be set as the local storage
    // we want to force the update of the unifiedUid
    if (email === previousEmail && currUser.unifiedUid) {
      return
    }

    email = email.toLowerCase()

    let response
    userEmail.setUserEmail(email)
    const amplitudeDeviceId = getAmplitudeDeviceId()
    try {
      response = await mixtilesAxios.post('v1/user/userCapturedData', {
        email,
        amplitudeDeviceId,
      })
      const isExistingUser = !response.data.isNewEmail
      logger.info('captured user email', response.data)
      let unifiedUidToUse
      if (response.data.firstUnifiedUid) {
        unifiedUidToUse = response.data.firstUnifiedUid
        if (!currUser.unifiedUid) {
          logger.info(
            'current user does not have unifiedUid, updating it with firstUnifiedUid',
            { currUser: currUser.username, unifiedUidToUse }
          )
          await mixtilesAxios.post('v1/user/updateUnifiedUid', {
            email,
            unifiedUid: unifiedUidToUse,
          })
          UserManager.onUUidSet(unifiedUidToUse, isExistingUser)
        }

        if (refetchExperiments) {
          await experimentManager.fetch(unifiedUidToUse)
          analytics.track(EVENT_NAMES.STICKY_EXPERIMENTS_APPLIED, {
            'First Username': response.data.firstUsername,
            'Unified UID': unifiedUidToUse,
            'Previous Email': previousEmail,
            'New Email': email,
          })
        }
        analytics.setUserProperties({
          'Initial User ID': response.data.firstUsername,
        })
      } else {
        const user = UserManager.getUser()
        unifiedUidToUse = user.unifiedUid || user.suggestedUnifiedUid
        await mixtilesAxios.post('v1/user/updateUnifiedUid', {
          email,
          unifiedUid: unifiedUidToUse,
        })
        logger.info(
          'User is new, created unifiedUid and updated it in user object',
          {
            currUser: currUser.username,
            createdUnifiedUid: unifiedUidToUse,
          }
        )
        UserManager.onUUidSet(unifiedUidToUse, isExistingUser)
      }
      const user = UserManager.getUser()
      await axios.post('/set-session', { unifiedUid: unifiedUidToUse })
      UserManager.setIsNewEmail(response.data.isNewEmail)

      await analytics.identifyUser(user.unifiedUid)

      analytics.setUserEmail({
        email,
        trackEventName:
          productTypeState.getProductType() === PRODUCT_TYPES.ART
            ? '[Art] Email Captured'
            : 'Web Email Captured',
        isNewEmail: UserManager.isNewEmail(),
        trackProperties: {
          Source: source,
          'First Username': response.data.firstUsername,
          'Onboarding Flow': onboardingFlow,
        },
      })
      onEmailChange.next(email)
    } catch (e) {
      logger.error('Failed to capture user email', e)
    }
  }

  static getUserEmail() {
    return userEmail.getUserEmail()
  }

  static setUserFullName(name, persist = true) {
    StorageManager.set(USER_FULL_NAME_KEY, name)
    analytics.setUserFullName(name)
    if (persist) {
      triggerApi(updateAccountApi({ fullName: name }))
    }
  }

  static getUserFullName() {
    return StorageManager.get(USER_FULL_NAME_KEY)
  }

  static getUserSessionToken() {
    return UserManager.getUser().sessionToken
  }

  static getUserAttributions = async () => {
    try {
      const { data } = await mixtilesAxios.get('/v4/userAttributions')
      return data || {}
    } catch (error) {
      logger.error('Failed to fetch user attributions from server', error)
      return {}
    }
  }

  static getInitialUserParams = async () => {
    if (!initialUserParams) {
      initialUserParams = (await UserManager.getUserAttributions())
        .initialParams
    }
    return initialUserParams || {}
  }

  static markArtUser() {
    StorageManager.set(ART_USER_KEY, true)
  }

  static isArtUser() {
    return !!StorageManager.get(ART_USER_KEY)
  }

  static getLastVisitDate() {
    return StorageManager.get(LAST_VISIT_DATE_KEY)
  }

  static updateLastVisitDate() {
    StorageManager.set(LAST_VISIT_DATE_KEY, Date.now())
  }

  static getDaysSinceLastVisit() {
    const lastVisitDate = UserManager.getLastVisitDate()
    if (!lastVisitDate) return 0
    const today = new Date()
    const lastVisit = new Date(lastVisitDate)
    const diffTime = Math.abs(today - lastVisit)
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  }
}

UserManager.init() // TODO: reconsider

export default UserManager
