import * as S from './CreditBox.styles'
import React from 'react'
import { ReactComponent as CreditIcon } from '../../Art/components/ArtMenu/icons/credit.svg'
import { useAccount } from '../Account/AccountProvider'
import { convertUSDPriceToCurrency, formatPrice } from '../../utils/utils'
import { Flex } from 'mixtiles-web-common'
import { getCurrency } from '../../services/PricingUtils'

export default function CreditBox({ isArt }) {
  const { isAuthenticated, isLoading, account } = useAccount()
  const creditExist = account?.credit && account?.credit !== 0
  if (!creditExist || !isAuthenticated || isLoading) {
    return ''
  }
  const currencyCode = getCurrency()
  const convertedCredit = convertUSDPriceToCurrency(
    account.credit,
    currencyCode
  )
  const formattedCredits = formatPrice(
    convertedCredit,
    currencyCode,
    false,
    false
  )
  return (
    <S.StyledBox $isArt={isArt}>
      <Flex data-testid="menu-logged-in-content">
        <S.Content $isArt={isArt}>
          <CreditIcon />
        </S.Content>
        <S.Text> You have {formattedCredits} in credits </S.Text>
      </Flex>
    </S.StyledBox>
  )
}
