import { transformImageUrl } from '../../services/CloudinaryService'
import { getFrameTransformationParams } from '@mixtiles/web-backend-shared'
import { type Crop } from '../../utils/cropUtils.types'
import { calculatePhotoThumbDimensions } from '../PhotoBank/PhotoBank.utils'

export const extractThumbDimensionsFromUrl = (url: string) => {
  if (url === undefined) {
    return {}
  }
  const regex = /c_thumb,w_\d+,h_\d+/g
  const match = url.match(regex)
  if (!match) {
    return {}
  }

  const parts = match[0].split(',')
  const width = parseInt(parts[1].split('w_')[1])
  const height = parseInt(parts[2].split('h_')[1])
  return {
    width,
    height,
  }
}

export const getVaryingResolutionImageUrls = ({
  url,
  width,
  height,
}: {
  url: string
  width: number
  height: number
}) => {
  const thumbDimensions = calculatePhotoThumbDimensions(width, height)
  const minimalResUrl = transformImageUrl(
    url,
    undefined,
    thumbDimensions.minimal,
    true
  )
  const lowResUrl = transformImageUrl(url, undefined, thumbDimensions.low, true)
  const highResUrl = transformImageUrl(
    url,
    undefined,
    thumbDimensions.high,
    true
  )

  return {
    minimalResUrl,
    lowResUrl,
    highResUrl,
  }
}

export function getImageWithFrameTransformation({
  imageUrl,
  cropParams,
  materialType,
  frameColor,
  tileSize,
}: {
  imageUrl: string
  cropParams: Crop
  materialType: string
  frameColor: string
  tileSize: string
}) {
  const transformedImageUrl = transformImageUrl(imageUrl, cropParams)

  // Add frame transformation to image
  const urlParts = transformedImageUrl.split(imageUrl)
  let url = urlParts[0]
  const { innerSize, name, offset } = getFrameTransformationParams({
    materialType,
    frameColor,
    tileSize,
  })
  if (innerSize) {
    url += `c_scale,h_${innerSize.height},q_auto,w_${innerSize.width}`
  }

  if (name) {
    url += `/l_whiteBackgroundFrames-${name}.png`
  }

  if (offset) {
    url += `/fl_layer_apply,g_center,x_${offset.x},y_${offset.y}`
  }

  return `${url}/${imageUrl}`
}
