import * as S from './Coupon.styles'
import React from 'react'

type Props = {
  title: string
  subtitle: string
  onClick?: () => void
  value: string
  selectedValue: string
  isLastCoupon?: boolean
  isDisabled?: boolean
  dataTestId?: string
}

function Coupon({
  title,
  subtitle,
  onClick,
  value,
  selectedValue,
  isLastCoupon,
  isDisabled = false,
  dataTestId,
}: Props) {
  return (
    <S.Coupon
      $last={isLastCoupon}
      data-testid={dataTestId}
      onClick={onClick}
      $disabled={isDisabled}
    >
      <S.CouponRadioButton
        value={value}
        selectedValue={selectedValue}
        onChange={() => {}}
        $disabled={isDisabled}
      />
      <S.CouponBody $disabled={isDisabled}>
        <S.CouponTitle>{title}</S.CouponTitle>
        <S.CouponSubtitle>{subtitle}</S.CouponSubtitle>
      </S.CouponBody>
    </S.Coupon>
  )
}

export default Coupon
