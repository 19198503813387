import React from 'react'
import { translateManager as t } from '../../services/TranslateManager'
import BasicDialogContent from '../MixtilesDialog/BasicDialogContent/BasicDialogContent'
import BasicDialogButton from '../MixtilesDialog/BasicDialogButton/BasicDialogButton'
import MixtilesDialog from '../MixtilesDialog/MixtilesDialog'

function EmbeddedLoginErrorDialog({ show, error, talkToUsPressed, onDismiss }) {
  return (
    <MixtilesDialog
      open={show}
      fullScreen={false}
      onDismiss={onDismiss}
      transition="grow"
    >
      <BasicDialogContent
        icon="warning"
        title={error?.title}
        text={error?.message}
      />
      <BasicDialogButton action={talkToUsPressed}>
        {t.get('general.talk_to_us')}
      </BasicDialogButton>
      <BasicDialogButton action={onDismiss}>
        {t.get('general.ok')}
      </BasicDialogButton>
    </MixtilesDialog>
  )
}

export default EmbeddedLoginErrorDialog
