import React, { useMemo } from 'react'
import * as S from './LoginBox.styles'
import { SIZES } from 'mixtiles-web-common/ui/Button'
import { translateManager as t } from '../../services/TranslateManager'

function getLoginMessage() {
  const SIGN_UP_MESSAGE = t.get('login.sign_up')
  const loginMessage = t
    .get('login.login_message', {
      v1: SIGN_UP_MESSAGE,
    })
    .split(SIGN_UP_MESSAGE)

  return loginMessage.map((msg, idx) => (
    <span key={idx}>
      {msg}
      {idx < loginMessage.length - 1 && <b>{SIGN_UP_MESSAGE}</b>}
    </span>
  ))
}

export default function GuestContent({ handleLoginClicked = () => {} }) {
  const onLoginClicked = async () => {
    handleLoginClicked()
  }

  const loginMessage = useMemo(getLoginMessage, [])
  return (
    <>
      <S.GuestContentBox data-testid="menu-not-logged-in-content">
        <S.LoginMessage>{loginMessage}</S.LoginMessage>
        <S.Cta
          size={SIZES.sm}
          onClick={onLoginClicked}
          data-testid="menu-login-button"
        >
          {t.get('login.login_or_sign_up')}
        </S.Cta>
      </S.GuestContentBox>
    </>
  )
}
