import React from 'react'
import './BasicDialogContent.scss'
import Icon from '../../Icon/Icon'

function BasicDialogContent({ icon, title, image, text, subtext, testId }) {
  return (
    <div className="basic-dialog" data-testid={testId}>
      <div className="dialog-content">
        {icon ? <Icon className="dialog-icon" icon={icon} /> : null}
        <div className="dialog-title">{title}</div>
        {image ? (
          <img className="dialog-image" src={image} alt="Dialog" />
        ) : null}
        {text ? <div className="dialog-text">{text}</div> : null}
        {subtext ? <div className="dialog-subtext">{subtext}</div> : null}
      </div>
    </div>
  )
}

export default BasicDialogContent
