import * as S from './QualityGuaranteeElement.styles'
import GuaranteeMessagesIcon from '../../../icons/assets/GuaranteeMessages.png'

function GuaranteeElement() {
  return (
    <S.QualityGuaranteeContainer>
      <S.QualityGuaranteeTextContainer>
        <S.QualityGuaranteeTitleContainer>
          <S.QualityGuaranteeVSignIcon />
          <S.QualityGuaranteeTitle>Quality Guarantee</S.QualityGuaranteeTitle>
        </S.QualityGuaranteeTitleContainer>
        <S.QualityGuaranteeDescription>
          World-class printing & materials. Any issues? Get a refund
        </S.QualityGuaranteeDescription>
      </S.QualityGuaranteeTextContainer>
      <S.QualityGuaranteeImageContainer>
        <S.QualityGuaranteeMessagesIcon src={GuaranteeMessagesIcon} />
      </S.QualityGuaranteeImageContainer>
    </S.QualityGuaranteeContainer>
  )
}

export default GuaranteeElement
