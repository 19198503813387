import { COUNTRY_PRICING } from '../config/pricing'
import { countryManager } from './CountryManager'
import { logger } from './logger'
import { PRODUCT_TYPES, productTypeState } from './ProductTypeState'

export function getPricing() {
  const lowercaseCountryPricing = countryManager
    .getPricingCountry()
    .toLowerCase()
  if (!COUNTRY_PRICING[lowercaseCountryPricing]) {
    logger.error('Error - no Country Pricing countryPricing', null, {
      lowercaseCountryPricing,
    })
    return null
  }
  return COUNTRY_PRICING[lowercaseCountryPricing]
}

export function compareByPriceAttr({ price: priceA }, { price: priceB }) {
  if (priceA > priceB) return 1
  if (priceB > priceA) return -1
  return 0
}

export function getCurrency() {
  if (productTypeState.getProductType() === PRODUCT_TYPES.ART) {
    return 'USD'
  }

  return getPricing().currency
}
