import StorageManager from '../../../services/StorageManager'

const SKIP_PRICING_WINDOW_KEY = 'skipPricingWindow_20220620'

export function skipPricingPopup() {
  StorageManager.set(SKIP_PRICING_WINDOW_KEY, true)
}

export function shouldSkipPricingWindow() {
  return StorageManager.get(SKIP_PRICING_WINDOW_KEY)
}
