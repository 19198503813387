import cc from '../locale/custom/countries.json'
import omit from 'lodash/omit'
import { isClient } from 'utils/runtimeUtils'

const uiLang = isClient()
  ? window.__remixContext?.state?.loaderData?.root?.language || 'en'
  : 'en'

export const REGIONS = {
  UNITED_STATES: 'United States',
  WESTERN_EUROPE: 'Western Europe',
  EASTERN_EUROPE: 'Eastern Europe',
  CANADA: 'Canada',
  AUSTRALIA: 'Australia',
  SOUTH_AMERICA: 'South America',
  CENTRAL_AMERICA: 'Central America',
  ISRAEL: 'Israel',
  GLOBAL: 'Global',
  ASIA: 'Asia',
}

/* This sets the fields display order.
   Only fields relevant to the selected country will be shown, followed by Country select field. */
export const ADDRESS_INPUT_FIELDS = [
  'fullName',
  'street',
  'address_2',
  'city',
  'state',
  'zipCode',
  'phoneNumber',
]

const COUNTRY_INPUTS = {
  UNITED_STATES: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    state: 'general.address_form.form_fields.state',
    zipCode: 'general.address_form.form_fields.zip',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  CANADA: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    state: 'general.address_form.form_fields.province',
    zipCode: 'general.address_form.form_fields.postal',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  GERMANY: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    zipCode: 'general.address_form.form_fields.postal',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  UNITED_KINGDOM: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    zipCode: 'Postcode',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  ISRAEL: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    zipCode: 'general.address_form.form_fields.zip',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  AUSTRIA: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    zipCode: 'general.address_form.form_fields.postal',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  NETHERLANDS: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    zipCode: 'Postcode',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  SWEDEN: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    zipCode: 'Postnummer',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  DENMARK: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    zipCode: 'Postnumre',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  AUSTRALIA: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    state: 'general.address_form.form_fields.state_or_territory',
    zipCode: 'Postcode',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  SPAIN: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    zipCode: 'general.address_form.form_fields.postal',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  FRANCE: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    zipCode: 'general.address_form.form_fields.postal',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  BELGIUM: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    zipCode: 'general.address_form.form_fields.postal',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  ITALY: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    zipCode: 'general.address_form.form_fields.postal',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  IRELAND: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    state: 'general.address_form.form_fields.country',
    zipCode: 'Eircode',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  POLAND: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    zipCode: 'general.address_form.form_fields.postal',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  JAPAN: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    state: 'general.address_form.form_fields.state_or_province',
    zipCode: '郵便番号',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  FINLAND: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    zipCode: 'general.address_form.form_fields.postal',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  GLOBAL: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    state: 'general.address_form.form_fields.state_or_province',
    zipCode: 'general.address_form.form_fields.postal',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
}

export const COUNTRIES = {
  UNITED_STATES: {
    id: 'UNITED_STATES',
    name: cc[uiLang].US,
    code: 'US',
    region: REGIONS.UNITED_STATES,
    inputs: COUNTRY_INPUTS.UNITED_STATES,
  },
  CANADA: {
    id: 'CANADA',
    name: cc[uiLang].CA,
    code: 'CA',
    region: REGIONS.CANADA,
    inputs: COUNTRY_INPUTS.CANADA,
  },
  GERMANY: {
    id: 'GERMANY',
    name: cc[uiLang].DE,
    code: 'DE',
    region: REGIONS.WESTERN_EUROPE,
    inputs: COUNTRY_INPUTS.GERMANY,
  },
  UNITED_KINGDOM: {
    id: 'UNITED_KINGDOM',
    name: cc[uiLang].GB,
    code: 'GB',
    region: REGIONS.WESTERN_EUROPE,
    inputs: COUNTRY_INPUTS.UNITED_KINGDOM,
  },
  ISRAEL: {
    id: 'ISRAEL',
    name: cc[uiLang].IL,
    code: 'IL',
    region: REGIONS.ISRAEL,
    inputs: COUNTRY_INPUTS.ISRAEL,
  },
  AUSTRIA: {
    id: 'AUSTRIA',
    name: cc[uiLang].AT,
    code: 'AT',
    region: REGIONS.WESTERN_EUROPE,
    inputs: COUNTRY_INPUTS.AUSTRIA,
  },
  NETHERLANDS: {
    id: 'NETHERLANDS',
    name: cc[uiLang].NL,
    code: 'NL',
    region: REGIONS.WESTERN_EUROPE,
    inputs: COUNTRY_INPUTS.NETHERLANDS,
  },
  SWEDEN: {
    id: 'SWEDEN',
    name: cc[uiLang].SE,
    code: 'SE',
    region: REGIONS.WESTERN_EUROPE,
    inputs: COUNTRY_INPUTS.SWEDEN,
  },
  DENMARK: {
    id: 'DENMARK',
    name: cc[uiLang].DK,
    code: 'DK',
    region: REGIONS.WESTERN_EUROPE,
    inputs: COUNTRY_INPUTS.DENMARK,
  },
  AUSTRALIA: {
    id: 'AUSTRALIA',
    name: cc[uiLang].AU,
    code: 'AU',
    region: REGIONS.AUSTRALIA,
    inputs: COUNTRY_INPUTS.AUSTRALIA,
  },
  SPAIN: {
    id: 'SPAIN',
    name: cc[uiLang].ES,
    code: 'ES',
    region: REGIONS.CENTRAL_AMERICA,
    inputs: COUNTRY_INPUTS.SPAIN,
  },
  FRANCE: {
    id: 'FRANCE',
    name: cc[uiLang].FR,
    code: 'FR',
    region: REGIONS.WESTERN_EUROPE,
    inputs: COUNTRY_INPUTS.FRANCE,
  },
  BELGIUM: {
    id: 'BELGIUM',
    name: cc[uiLang].BE,
    code: 'BE',
    region: REGIONS.WESTERN_EUROPE,
    inputs: COUNTRY_INPUTS.BELGIUM,
  },
  ITALY: {
    id: 'ITALY',
    name: cc[uiLang].IT,
    code: 'IT',
    region: REGIONS.WESTERN_EUROPE,
    inputs: COUNTRY_INPUTS.ITALY,
  },
  IRELAND: {
    id: 'IRELAND',
    name: cc[uiLang].IE,
    code: 'IE',
    region: REGIONS.WESTERN_EUROPE,
    inputs: COUNTRY_INPUTS.IRELAND,
  },
  POLAND: {
    id: 'POLAND',
    name: cc[uiLang].PL,
    code: 'PL',
    region: REGIONS.EASTERN_EUROPE,
    inputs: COUNTRY_INPUTS.POLAND,
  },
  JAPAN: {
    id: 'JAPAN',
    name: cc[uiLang].JP,
    code: 'JP',
    region: REGIONS.ASIA,
    inputs: COUNTRY_INPUTS.JAPAN,
  },
  FINLAND: {
    id: 'FINLAND',
    name: cc[uiLang].FI,
    code: 'FI',
    region: REGIONS.WESTERN_EUROPE,
    inputs: COUNTRY_INPUTS.FINLAND,
  },
}

export const GLOBAL_COUNTRIES = {
  // AF: 'Afghanistan',
  AL: cc[uiLang].AL,
  // DZ: 'Algeria',
  // AD: cc[uiLang]['AD'], Andorra
  AO: cc[uiLang].AO,
  AI: cc[uiLang].AI,
  AG: cc[uiLang].AG,
  AR: cc[uiLang].AR,
  // AM: 'Armenia',
  AW: cc[uiLang].AW,
  AU: cc[uiLang].AU,
  // AZ: 'Azerbaijan',
  BO: cc[uiLang].BO,
  BS: cc[uiLang].BS,
  BH: cc[uiLang].BH,
  BD: cc[uiLang].BD,
  BB: cc[uiLang].BB,
  // BY: 'Belarus',
  BZ: cc[uiLang].BZ,
  BJ: cc[uiLang].BJ,
  BM: cc[uiLang].BM,
  BT: cc[uiLang].BT,
  BQ: cc[uiLang].BQ,
  BA: cc[uiLang].BA,
  BW: cc[uiLang].BW,
  // BR: cc[uiLang].BR, Brazil
  VG: cc[uiLang].VG,
  BN: cc[uiLang].BN,
  BG: cc[uiLang].BG,
  BF: cc[uiLang].BF,
  // MM: cc[uiLang]['MM'], Myanmar
  BI: cc[uiLang].BI,
  // KH: cc[uiLang]['KH'],
  // CM: 'Cameroon',
  // CV: 'Cape Verde',
  KY: cc[uiLang].KY,
  // CF: 'Central African Republic',
  TD: cc[uiLang].TD,
  CL: cc[uiLang].CL,
  // CN: 'China',
  CO: cc[uiLang].CO,
  // KM: 'Comoros',
  // CD: 'Congo, Democratic Republic of the',
  CG: cc[uiLang].CG,
  CR: cc[uiLang].CR,
  // CI: 'Cote d’Ivoire (Ivory Coast)',
  HR: cc[uiLang].HR,
  // CU: 'Cuba',
  CW: cc[uiLang].CW,
  CY: cc[uiLang].CY,
  CZ: cc[uiLang].CZ,
  // DJ: 'Djibouti',
  DM: cc[uiLang].DM,
  DO: cc[uiLang].DO,
  EC: cc[uiLang].EC,
  EG: cc[uiLang].EG,
  SV: cc[uiLang].SV,
  GQ: cc[uiLang].GQ,
  ER: cc[uiLang].ER,
  EE: cc[uiLang].EE,
  SZ: cc[uiLang].SZ,
  ET: cc[uiLang].ET,
  // FO: 'Faroe Islands',
  // FJ: cc[uiLang]['FJ'],
  GF: cc[uiLang].GF,
  PF: cc[uiLang].PF,
  GA: cc[uiLang].GA,
  GM: cc[uiLang].GM,
  // GE: 'Georgia',
  // GH: cc[uiLang]['GH'],
  GI: cc[uiLang].GI,
  GR: cc[uiLang].GR,
  // GL: 'Greenland',
  GD: cc[uiLang].GD,
  GP: cc[uiLang].GP,
  GT: cc[uiLang].GT,
  // GN: 'Guinea',
  // GW: 'Guinea-Bissau',
  GY: cc[uiLang].GY,
  HT: cc[uiLang].HT,
  HN: cc[uiLang].HN,
  HK: cc[uiLang].HK,
  HU: cc[uiLang].HU,
  IS: cc[uiLang].IS,
  // IN: cc[uiLang]['IN'],
  ID: cc[uiLang].ID,
  JM: cc[uiLang].JM,
  // JP: 'Japan',
  JO: cc[uiLang].JO,
  // KZ: 'Kazakhstan',
  KE: cc[uiLang].KE,
  // KI: 'Kiribati',
  KR: cc[uiLang].KR,
  // XK: 'Kosovo',
  // KW: cc[uiLang]['KW'], // Kuwait has shipping issues
  // KG: 'Kyrgyzstan',
  // LA: cc[uiLang]['LA'], // Lao People's Democratic Republic
  LV: cc[uiLang].LV,
  LS: cc[uiLang].LS,
  // LR: 'Liberia',
  // LY: 'Libya',
  LI: cc[uiLang].LI,
  LT: cc[uiLang].LT,
  LU: cc[uiLang].LU,
  MC: cc[uiLang].MC,
  MO: cc[uiLang].MO,
  // MK: 'Macedonia (Republic of)',
  MG: cc[uiLang].MG,
  MW: cc[uiLang].MW,
  MY: cc[uiLang].MY,
  MV: cc[uiLang].MV,
  ML: cc[uiLang].ML,
  MT: cc[uiLang].MT,
  MQ: cc[uiLang].MQ,
  // MR: 'Mauritania',
  MU: cc[uiLang].MU,
  MX: cc[uiLang].MX,
  // MD: 'Moldova',
  // MN: cc[uiLang]['MN'],
  ME: cc[uiLang].ME,
  MS: cc[uiLang].MS,
  MA: cc[uiLang].MA,
  MZ: cc[uiLang].MZ,
  NA: cc[uiLang].NA,
  // NR: 'Nauru',
  // NP: 'Nepal',
  // NC: cc[uiLang]['NC'],  New Caledonia
  NZ: cc[uiLang].NZ,
  NI: cc[uiLang].NI,
  NE: cc[uiLang].NE,
  NG: cc[uiLang].NG,
  NO: cc[uiLang].NO,
  OM: cc[uiLang].OM,
  PK: cc[uiLang].PK,
  PA: cc[uiLang].PA,
  // PG: cc[uiLang]['PG'], Papua New Guinea
  PY: cc[uiLang].PY,
  PE: cc[uiLang].PE,
  PH: cc[uiLang].PH,
  PN: cc[uiLang].PN,
  PR: cc[uiLang].PR,
  PT: cc[uiLang].PT,
  QA: cc[uiLang].QA,
  RE: cc[uiLang].RE,
  RO: cc[uiLang].RO,
  // RU: 'Russia',
  RW: cc[uiLang].RW,
  // SH: 'Saint Helena',
  KN: cc[uiLang].KN,
  LC: cc[uiLang].LC,
  PM: cc[uiLang].PM,
  VC: cc[uiLang].VC,
  // WS: 'Samoa',
  SM: cc[uiLang].SM,
  // ST: 'Sao Tome and Principe',
  SA: cc[uiLang].SA,
  SN: cc[uiLang].SN,
  RS: cc[uiLang].RS,
  // SC: cc[uiLang]['SC'],
  // SL: 'Sierra Leone',
  SG: cc[uiLang].SG,
  SX: cc[uiLang].SX,
  SK: cc[uiLang].SK,
  SI: cc[uiLang].SI,
  // SB: 'Solomon Islands',
  ZA: cc[uiLang].ZA,
  LK: cc[uiLang].LK,
  // SD: 'Sudan',
  // SR: 'Suriname',
  CH: cc[uiLang].CH,
  TW: cc[uiLang].TW,
  // TJ: 'Tajikistan',
  TZ: cc[uiLang].TZ,
  TH: cc[uiLang].TH,
  TL: cc[uiLang].TL,
  // TG: cc[uiLang]['TG'],
  // TO: 'Tonga',
  TT: cc[uiLang].TT,
  // TN: 'Tunisia',
  // TR: cc[uiLang]['TR'],
  // TM: 'Turkmenistan',
  TC: cc[uiLang].TC,
  // TV: 'Tuvalu',
  UG: cc[uiLang].UG,
  // UA: 'Ukraine',
  AE: cc[uiLang].AE,
  UY: cc[uiLang].UY,
  // UZ: 'Uzbekistan',
  // VU: cc[uiLang]['VU'],
  VA: cc[uiLang].VA,
  VE: cc[uiLang].VE,
  // VN: cc[uiLang]['VN'],
  // WF: cc[uiLang]['WF'],
  // YE: 'Yemen',
  ZM: cc[uiLang].ZM,
  // ZW: 'Zimbabwe'
}

export const UNITED_KINGDOM = 'GB'
export const NON_GDPR_MARKETING_CONSENT_COUNTRIES = ['CA']

const COUNTRIES_WITHOUT_ZIP = [
  'AE',
  'AG',
  'AN',
  'AO',
  'AW',
  'BF',
  'BI',
  'BJ',
  'BO',
  'BS',
  'BW',
  'BZ',
  'CD',
  'CW',
  'DM',
  'ER',
  'GA',
  'GD',
  'GM',
  'GQ',
  'GY',
  'HK',
  'JM',
  'KN',
  'LC',
  'ML',
  'MO',
  'MS',
  'PA',
  'QA',
  'RW',
  'TD',
  'TL',
  'TT',
  'UG',
]

function _getGlobalCountryInputs(countryCode) {
  if (COUNTRIES_WITHOUT_ZIP.includes(countryCode)) {
    return omit(COUNTRY_INPUTS.GLOBAL, ['zipCode'])
  } else {
    return COUNTRY_INPUTS.GLOBAL
  }
}

for (const countryCode in GLOBAL_COUNTRIES) {
  const countryName = GLOBAL_COUNTRIES[countryCode]

  const inputs = _getGlobalCountryInputs(countryCode)

  COUNTRIES[countryName.toUpperCase()] = {
    id: countryName.toUpperCase(),
    name: countryName,
    code: countryCode,
    region: REGIONS.GLOBAL,
    inputs,
  }
}

const countryList = Object.keys(COUNTRIES).map((key) => COUNTRIES[key])
export const COUNTRY_CODES = countryList.map((c) => c.code)
export const getDefaultCountryId = () =>
  COUNTRY_CODES.includes(window.KEYS.ipCountry)
    ? countryList.find((c) => c.code === window.KEYS.ipCountry).id
    : COUNTRIES.UNITED_STATES.id
export const EURO_COUNTRIES = [
  'DE',
  'AT',
  'IE',
  'NL',
  'FI',
  'ES',
  'FR',
  'BE',
  'IT',
]
