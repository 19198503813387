import * as S from './DotsLoader.styles'

export default function DotsLoader() {
  return (
    <S.Loader>
      <S.Dot />
      <S.Dot />
      <S.Dot />
    </S.Loader>
  )
}
