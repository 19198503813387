import { styled } from 'styled-components'
import CloseButtonComp from '../../../TopBar/CloseButton/CloseButton'
import { onMediumScreen } from 'mixtiles-web-common'
import PopupComp from '../../../Popup/Popup'

export const Popup = styled(PopupComp)`
  height: 100%;
  border-radius: 0;
`

export const Content = styled.div`
  display: flex;
  width: 100%;
  ${onMediumScreen()} {
    width: 450px;
    min-height: 653px;
  }
  flex-direction: column;
  flex: 1;
`

export const MixtilesPlusInfoWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 44px;
  align-items: center;
  justify-content: center;
`

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayMedium};
`

export const HeaderText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondaryDark};
`

export const CloseButton = styled(CloseButtonComp)`
  padding: 0;
`

export const MixtilesPlusValidUntilContainer = styled.div`
  padding: 8px 12px;
  background: ${({ theme }) => theme.colors.grayBright};
`

export const MixtilesPlusValidUntilText = styled.span`
  color: ${({ theme }) => theme.colors.secondaryDark};
  font-size: 14px;
  font-weight: 400;
`

export const Separator = styled.div`
  width: 244px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayMedium};
`
