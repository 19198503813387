import * as S from './PageOverlay.styles'
import BrowserThemeColor from '../ThemeColor/BrowserThemeColor'
import { photosTheme as theme } from 'mixtiles-web-common'
import { type PropsWithChildren } from 'react'

export default function PageOverlay({ children }: PropsWithChildren) {
  return (
    <>
      <BrowserThemeColor color={theme.colors.grayBackgroundWhite} />
      <S.Overlay>{children}</S.Overlay>
    </>
  )
}
