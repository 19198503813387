import { GLOBAL_COUNTRIES } from './countries-data'
import { convertUSDPriceToCurrency } from '../utils/utils'

export const MINIMUM_FOR_DISCOUNT_PRICING_TYPE = 'MINIMUM_FOR_DISCOUNT'
export const MINIMUM_FOR_FREE_SHIPPING_PRICING_TYPE =
  'MINIMUM_FOR_FREE_SHIPPING'
export const FLAT_PRICING_TYPE = 'FLAT'
export const MINIMUM_ORDER_PRICING_TYPE = 'MINIMUM_ORDER'

export const EU_COUNTRIES = [
  'BE',
  'GR',
  'LT',
  'PT',
  'BG',
  'ES',
  'LU',
  'RO',
  'CZ',
  'FR',
  'HU',
  'SI',
  'DK',
  'HR',
  'MT',
  'SK',
  'DE',
  'IT',
  'NL',
  'FI',
  'EE',
  'CY',
  'AT',
  'SE',
  'IE',
  'LV',
  'PL',
]

const DEFAULT_CONVERSION_DIFF = 1.3

// This is the added calculation for the difference of the shipment between countries and the US
export const CONVERSION_DIFF = {
  CAD: 1.11,
  GBP: 1.095,
  EUR: 1.095,
  ILS: 1.05,
  USD: 1,
}

export const CURRENCIES = {
  USD: 'USD',
  CAD: 'CAD',
  GBP: 'GBP',
  EUR: 'EUR',
  ILS: 'ILS',
}

export const usdToCurrency = (usd, currency) =>
  Math.round(
    convertUSDPriceToCurrency(usd, currency) *
      (CONVERSION_DIFF[currency] || DEFAULT_CONVERSION_DIFF)
  )

export const COUNTRY_PRICING = {
  us: {
    pricingType: FLAT_PRICING_TYPE,
    trackingId: 'USD 11 Per Tile',
    minOrderSize: 0,
    minOrderPrice: 0,
    additionalTilePrice: 11,
    currency: 'USD',
    needsClarification: false,
    vatPercentIncludedInPrice: 0,
    pricingId: 'WEB_US_11_FLAT', // only for web
  },
  ca: {
    pricingType: FLAT_PRICING_TYPE,
    trackingId: 'CAD 15 Per Tile',
    minOrderSize: 0,
    minOrderPrice: 0,
    additionalTilePrice: 15,
    currency: 'CAD',
    needsClarification: true,
    vatPercentIncludedInPrice: 0,
    pricingId: 'WEB_CA_15_FLAT', // only for web
  },
  gb: {
    pricingType: FLAT_PRICING_TYPE,
    trackingId: 'GBP 11 Per Tile',
    minOrderSize: 0,
    minOrderPrice: 0,
    additionalTilePrice: 11,
    currency: 'GBP',
    needsClarification: false,
    vatPercentIncludedInPrice: 20,
    pricingId: 'WEB_GB_11_FLAT', // only for web
  },
  de: {
    pricingType: FLAT_PRICING_TYPE,
    trackingId: 'EUR 15 Per Tile',
    minOrderSize: 0,
    minOrderPrice: 0,
    additionalTilePrice: 15,
    currency: 'EUR',
    needsClarification: false,
    vatPercentIncludedInPrice: 19,
  },
  il: {
    pricingType: FLAT_PRICING_TYPE,
    trackingId: 'ILS 49 Per Tile',
    minOrderSize: 0,
    minOrderPrice: 0,
    additionalTilePrice: 49,
    currency: 'ILS',
    needsClarification: false,
    vatPercentIncludedInPrice: 17,
  },
  ie: {
    pricingType: FLAT_PRICING_TYPE,
    trackingId: 'EUR 15 Per Tile',
    minOrderSize: 0,
    minOrderPrice: 0,
    additionalTilePrice: 15,
    currency: 'EUR',
    needsClarification: true,
    vatPercentIncludedInPrice: 23,
  },
  at: {
    pricingType: FLAT_PRICING_TYPE,
    trackingId: 'EUR 15 Per Tile',
    minOrderSize: 0,
    minOrderPrice: 0,
    additionalTilePrice: 15,
    currency: 'EUR',
    needsClarification: true,
    vatPercentIncludedInPrice: 20,
  },
  nl: {
    pricingType: FLAT_PRICING_TYPE,
    trackingId: 'EUR 15 Per Tile',
    minOrderSize: 0,
    minOrderPrice: 0,
    additionalTilePrice: 15,
    currency: 'EUR',
    needsClarification: false,
    vatPercentIncludedInPrice: 21,
  },
  se: {
    pricingType: FLAT_PRICING_TYPE,
    trackingId: 'SEK 169 Per Tile',
    minOrderSize: 0,
    minOrderPrice: 0,
    additionalTilePrice: 169,
    currency: 'SEK',
    needsClarification: true,
    vatPercentIncludedInPrice: 25,
  },
  dk: {
    pricingType: FLAT_PRICING_TYPE,
    trackingId: 'DKK 99 Per Tile',
    minOrderSize: 0,
    minOrderPrice: 0,
    additionalTilePrice: 99,
    currency: 'DKK',
    needsClarification: true,
    vatPercentIncludedInPrice: 25,
  },
  fi: {
    pricingType: FLAT_PRICING_TYPE,
    trackingId: 'EUR 15 Per Tile',
    minOrderSize: 0,
    minOrderPrice: 0,
    additionalTilePrice: 15,
    currency: 'EUR',
    needsClarification: false,
    vatPercentIncludedInPrice: 24,
  },
  au: {
    pricingType: FLAT_PRICING_TYPE,
    trackingId: 'AUD 14 Per Tile',
    minOrderSize: 0,
    minOrderPrice: 0,
    additionalTilePrice: 14,
    currency: 'AUD',
    needsClarification: true,
    vatPercentIncludedInPrice: 10,
  },
  // mx: {
  //   pricingType: FLAT_PRICING_TYPE,
  //   trackingId: 'MXN 200 Per Tile',
  //   minOrderSize: 0,
  //   minOrderPrice: 0,
  //   additionalTilePrice: 200,
  //   currency: 'MXN',
  //   needsClarification: true,
  //   vatPercentIncludedInPrice: 16
  // },
  // ar: {
  //   pricingType: FLAT_PRICING_TYPE,
  //   trackingId: 'ARS 200 Per Tile',
  //   minOrderSize: 0,
  //   minOrderPrice: 0,
  //   additionalTilePrice: 200,
  //   currency: 'ARS',
  //   needsClarification: true,
  //   vatPercentIncludedInPrice: 21
  // },
  // in: {
  //   pricingType: FLAT_PRICING_TYPE,
  //   trackingId: 'INR 700 Per Tile',
  //   minOrderSize: 0,
  //   minOrderPrice: 0,
  //   additionalTilePrice: 700,
  //   currency: 'INR',
  //   needsClarification: false,
  //   vatPercentIncludedInPrice: 12
  // },
  es: {
    pricingType: FLAT_PRICING_TYPE,
    trackingId: 'EUR 15 Per Tile',
    minOrderSize: 0,
    minOrderPrice: 0,
    additionalTilePrice: 15,
    currency: 'EUR',
    needsClarification: false,
    vatPercentIncludedInPrice: 19,
    pricingId: 'WEB', // only for web
  },
  // cl: {
  //   pricingType: FLAT_PRICING_TYPE,
  //   trackingId: 'CLP 7100 Per Tile',
  //   minOrderSize: 0,
  //   minOrderPrice: 0,
  //   additionalTilePrice: 7100,
  //   currency: 'CLP',
  //   needsClarification: true,
  //   vatPercentIncludedInPrice: 21
  // },
  fr: {
    pricingType: FLAT_PRICING_TYPE,
    trackingId: 'EUR 15 Per Tile',
    minOrderSize: 0,
    minOrderPrice: 0,
    additionalTilePrice: 15,
    currency: 'EUR',
    needsClarification: false,
    vatPercentIncludedInPrice: 20,
    pricingId: 'WEB', // only for web
  },
  be: {
    pricingType: FLAT_PRICING_TYPE,
    trackingId: 'EUR 15 Per Tile',
    minOrderSize: 0,
    minOrderPrice: 0,
    additionalTilePrice: 15,
    currency: 'EUR',
    needsClarification: false,
    vatPercentIncludedInPrice: 21,
  },
  it: {
    pricingType: FLAT_PRICING_TYPE,
    trackingId: 'EUR 15 Per Tile',
    minOrderSize: 0,
    minOrderPrice: 0,
    additionalTilePrice: 15,
    currency: 'EUR',
    needsClarification: false,
    vatPercentIncludedInPrice: 22,
  },
  pl: {
    pricingType: FLAT_PRICING_TYPE,
    trackingId: 'PLN 69 Per Tile',
    minOrderSize: 0,
    minOrderPrice: 0,
    additionalTilePrice: 69,
    currency: 'PLN',
    needsClarification: false,
    vatPercentIncludedInPrice: 23,
  },
  jp: {
    pricingType: MINIMUM_ORDER_PRICING_TYPE,
    trackingId: 'JPY 6300 For Three Tiles + JPY 1800 Per Tile',
    minOrderSize: 3,
    minOrderPrice: 6300,
    additionalTilePrice: 1800,
    currency: 'JPY',
    needsClarification: false,
    vatPercentIncludedInPrice: 8,
  },
}

const globalPricing = {
  pricingType: MINIMUM_ORDER_PRICING_TYPE,
  trackingId: '$58 For Three Tiles + $17 Per Tile',
  minOrderSize: 3,
  minOrderPrice: 58,
  additionalTilePrice: 17,
  currency: 'USD',
  needsClarification: true,
  vatPercentIncludedInPrice: 0,
}

// Add global countries to pricing config
for (const countryCode in GLOBAL_COUNTRIES) {
  COUNTRY_PRICING[countryCode.toLowerCase()] = globalPricing
}

// Set VAT for EU countries without specific pricing
for (const country of EU_COUNTRIES.map((country) => country.toLowerCase())) {
  // skips countries that already has their pricing (with the correct VAT)
  if (COUNTRY_PRICING[country].vatPercentIncludedInPrice > 0) {
    continue
  }
  // EU countries not in the list above, have the vat of the country the order is sent from (MT, GR are sent from US)
  // Currently they are all from germany

  // Perform shallow copy to globalPricing so changing finland VAT won't affect all
  COUNTRY_PRICING[country] = { ...COUNTRY_PRICING[country] }
  COUNTRY_PRICING[country].vatPercentIncludedInPrice = ['mt', 'cy'].includes(
    country
  )
    ? 0
    : COUNTRY_PRICING.de.vatPercentIncludedInPrice
}

export const CENTERPIECE_PRICE_USD = 19
export const COUNTRY_TO_CENTERPIECE_PRICE = {
  gb: 29,
  de: 35,
  il: 99,
}

export function calculateCenterpiecePrice(country) {
  if (COUNTRY_TO_CENTERPIECE_PRICE[country]) {
    return COUNTRY_TO_CENTERPIECE_PRICE[country]
  }
  return Math.floor(
    convertUSDPriceToCurrency(
      CENTERPIECE_PRICE_USD,
      COUNTRY_PRICING[country].currency
    )
  )
}

// Add centerpiece price according to the country's currency
Object.keys(COUNTRY_PRICING).forEach((country) => {
  COUNTRY_PRICING[country].centerpiecePrice = calculateCenterpiecePrice(country)
})

export const PHOTO_BOOK_DEFAULT_PRICES = {
  BOOK_8X8: 49,
  BOOK_10X10: 79,
  BOOK_12X12: 99,
}
