import { createContext, useContext } from 'react'

export const LangContext = createContext<string | null>(null)

export const useLanguage = () => {
  const lang = useContext(LangContext)

  if (!lang) {
    throw new Error('Language not found in KeysContext')
  }

  return lang
}
