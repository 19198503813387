import { normalizeState } from '../utils/AddressUtils'
import { COUNTRIES } from './countries-data'
import { TAX_TYPES } from '@mixtiles/web-backend-shared'

const CANADA_TAXES_PER_PROVINCE = [
  { provinceCode: 'ab', taxes: [{ name: TAX_TYPES.IMPORT_FEE, percent: 5 }] },
  { provinceCode: 'bc', taxes: [{ name: TAX_TYPES.IMPORT_FEE, percent: 12 }] },
  { provinceCode: 'mb', taxes: [{ name: TAX_TYPES.IMPORT_FEE, percent: 13 }] },
  { provinceCode: 'nb', taxes: [{ name: TAX_TYPES.IMPORT_FEE, percent: 15 }] },
  { provinceCode: 'nl', taxes: [{ name: TAX_TYPES.IMPORT_FEE, percent: 15 }] },
  { provinceCode: 'nt', taxes: [{ name: TAX_TYPES.IMPORT_FEE, percent: 5 }] },
  { provinceCode: 'ns', taxes: [{ name: TAX_TYPES.IMPORT_FEE, percent: 15 }] },
  { provinceCode: 'nu', taxes: [{ name: TAX_TYPES.IMPORT_FEE, percent: 5 }] },
  { provinceCode: 'on', taxes: [{ name: TAX_TYPES.IMPORT_FEE, percent: 13 }] },
  { provinceCode: 'pe', taxes: [{ name: TAX_TYPES.IMPORT_FEE, percent: 15 }] },
  {
    provinceCode: 'qc',
    taxes: [{ name: TAX_TYPES.IMPORT_FEE, percent: 14.975 }],
  },
  { provinceCode: 'sk', taxes: [{ name: TAX_TYPES.IMPORT_FEE, percent: 11 }] },
  { provinceCode: 'yt', taxes: [{ name: TAX_TYPES.IMPORT_FEE, percent: 5 }] },
]
// This is on comment because we can't collect those taxes so we collect import fee instead
// the import fee was the sum of the taxes of each province
// const CANADA_TAXES_PER_PROVINCE = [
//   {
//     provinceCode: 'ab',
//     taxes: [
//       {
//         name: 'GST',
//         percent: 5
//       }
//     ]
//   },
//   {
//     provinceCode: 'bc',
//     taxes: [
//       {
//         name: 'GST',
//         percent: 5
//       },
//       {
//         name: 'PST',
//         percent: 7
//       }
//     ]
//   },
//   {
//     provinceCode: 'mb',
//     taxes: [
//       {
//         name: 'GST',
//         percent: 5
//       },
//       {
//         name: 'PST',
//         percent: 8
//       }
//     ]
//   },
//   {
//     provinceCode: 'nb',
//     taxes: [
//       {
//         name: 'HST',
//         percent: 15
//       }
//     ]
//   },
//   {
//     provinceCode: 'nl',
//     taxes: [
//       {
//         name: 'HST',
//         percent: 15
//       }
//     ]
//   },
//   {
//     provinceCode: 'nt',
//     taxes: [
//       {
//         name: 'GST',
//         percent: 5
//       }
//     ]
//   },
//   {
//     provinceCode: 'ns',
//     taxes: [
//       {
//         name: 'HST',
//         percent: 15
//       }
//     ]
//   },
//   {
//     provinceCode: 'nu',
//     taxes: [
//       {
//         name: 'GST',
//         percent: 5
//       }
//     ]
//   },
//   {
//     provinceCode: 'on',
//     taxes: [
//       {
//         name: 'HST',
//         percent: 13
//       }
//     ]
//   },
//   {
//     provinceCode: 'pe',
//     taxes: [
//       {
//         name: 'HST',
//         percent: 15
//       }
//     ]
//   },
//   {
//     provinceCode: 'qc',
//     taxes: [
//       {
//         name: 'GST',
//         percent: 5
//       },
//       {
//         name: 'QST',
//         percent: 9.975
//       }
//     ]
//   },
//   {
//     provinceCode: 'sk',
//     taxes: [
//       {
//         name: 'GST',
//         percent: 5
//       },
//       {
//         name: 'PST',
//         percent: 6
//       }
//     ]
//   },
//   {
//     provinceCode: 'yt',
//     taxes: [
//       {
//         name: 'GST',
//         percent: 5
//       }
//     ]
//   }
// ]
const US_TAXES_PER_STATE = [
  { stateCode: 'AL', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 4 }] },
  { stateCode: 'AR', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 6.5 }] },
  { stateCode: 'AZ', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 5.6 }] },
  { stateCode: 'CA', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 7.25 }] },
  { stateCode: 'CO', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 2.9 }] },
  { stateCode: 'CT', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 6.35 }] },
  { stateCode: 'DC', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 6 }] },
  { stateCode: 'FL', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 6 }] },
  { stateCode: 'GA', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 4 }] },
  { stateCode: 'HI', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 4 }] },
  { stateCode: 'IA', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 6 }] },
  { stateCode: 'ID', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 6 }] },
  { stateCode: 'IL', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 6.25 }] },
  { stateCode: 'IN', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 7 }] },
  { stateCode: 'KS', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 6.5 }] },
  { stateCode: 'KY', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 6 }] },
  { stateCode: 'LA', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 4.45 }] },
  { stateCode: 'MA', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 6.25 }] },
  { stateCode: 'ME', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 5.5 }] },
  { stateCode: 'MD', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 6 }] },
  { stateCode: 'MI', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 6 }] },
  { stateCode: 'MN', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 6.88 }] },
  { stateCode: 'NC', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 4.75 }] },
  { stateCode: 'ND', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 5 }] },
  { stateCode: 'NE', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 5.5 }] },
  { stateCode: 'NJ', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 6.63 }] },
  { stateCode: 'NM', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 5.125 }] },
  { stateCode: 'NV', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 4.6 }] },
  { stateCode: 'NY', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 4 }] },
  { stateCode: 'OH', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 5.75 }] },
  { stateCode: 'OK', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 4.5 }] },
  { stateCode: 'PA', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 6 }] },
  { stateCode: 'RI', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 7 }] },
  { stateCode: 'SC', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 6 }] },
  { stateCode: 'SD', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 4.5 }] },
  { stateCode: 'TN', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 7 }] },
  { stateCode: 'TX', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 6.25 }] },
  { stateCode: 'UT', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 4.85 }] },
  { stateCode: 'VA', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 4.3 }] },
  { stateCode: 'VT', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 6 }] },
  { stateCode: 'WA', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 6.5 }] },
  { stateCode: 'WI', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 5 }] },
  { stateCode: 'WV', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 6 }] },
  { stateCode: 'WY', taxes: [{ name: TAX_TYPES.SALES_TAX, percent: 4 }] },
]

export const AUSTRALIA_TAXES = [{ percent: 10, name: TAX_TYPES.GST }]

export const TAXATION_LOCATION_KEYS = {
  ca: 'provinces',
  us: 'states',
}

export const TAXATION_CODE_KEY = {
  ca: 'provinceCode',
  us: 'stateCode',
}

export const TAXATION = {
  ca: {
    provinces: CANADA_TAXES_PER_PROVINCE,
  },
  us: {
    states: US_TAXES_PER_STATE,
  },
}

export function getTaxInfo({ address }) {
  const country = COUNTRIES[address.countryId]

  // australia has constant 10% GST rate
  if (country.id === COUNTRIES.AUSTRALIA.id) {
    return AUSTRALIA_TAXES
  }
  const countryCode = country.code.toLowerCase()
  const taxesPerCountry = TAXATION[countryCode]
  if (!taxesPerCountry) {
    // country doesn't have taxes
    return []
  }
  const locationKey = TAXATION_LOCATION_KEYS[countryCode]
  const taxesPerLocationList = taxesPerCountry[locationKey]
  const code = TAXATION_CODE_KEY[countryCode]
  const state = normalizeState(countryCode, address.state)
  const taxesPerLocation = taxesPerLocationList.find(
    (taxes) => taxes[code].toUpperCase() === state
  )
  if (!taxesPerLocation) {
    // state/province doesn't have taxes
    return []
  }

  return taxesPerLocation.taxes
}
