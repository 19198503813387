import React, { type ChangeEvent } from 'react'
import {
  Container,
  Placeholder,
  Input,
  InvalidMessage,
  _VARIANTS as VARIANTS,
  PlaceholderChild,
} from './TextInput.styles'

interface TextInputProps {
  variant?: keyof typeof VARIANTS
  value?: string
  type?: string
  name?: string
  autoComplete?: string
  autoFocus?: boolean
  onChange?: (value: string) => void
  placeholder?: string
  placeholderChild?: string
  minWidth?: number
  fullWidth?: boolean
  isInvalid?: boolean
  invalidMessage?: string
  testId?: string
}

function TextInput({
  variant = VARIANTS.simple as keyof typeof VARIANTS,
  value,
  type,
  name,
  autoComplete,
  autoFocus,
  onChange,
  placeholder,
  placeholderChild,
  minWidth,
  fullWidth,
  isInvalid,
  invalidMessage,
  testId,
  ...otherProps
}: TextInputProps) {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event.target.value)
  }

  return (
    <>
      <Container
        $variant={variant}
        $minWidth={minWidth}
        $fullWidth={fullWidth}
        $invalid={isInvalid}
      >
        <Placeholder $variant={variant} $hasValue={!!value}>
          {placeholder}
          {placeholderChild && (
            <PlaceholderChild>{placeholderChild}</PlaceholderChild>
          )}
        </Placeholder>
        <Input
          $variant={variant}
          type={type || 'text'}
          name={name}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          value={value || ''}
          onChange={handleChange}
          data-testid={testId}
          {...otherProps}
        />
      </Container>
      {isInvalid && invalidMessage && (
        <InvalidMessage>{invalidMessage}</InvalidMessage>
      )}
    </>
  )
}

export default TextInput
export { VARIANTS }
