import { styled } from 'styled-components'

export const Container = styled.nav``

export const Section = styled.ul`
  margin-block: 0;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Item = styled.li`
  list-style-type: none;
  padding: 0;
`
