import { styled } from 'styled-components'

export const Overlay = styled.div`
  position: fixed;
  z-index: 1402;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.grayBackgroundWhite};
`
