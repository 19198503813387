import {
  type BookMotionRect,
  type Cover,
  type FlipPageParams,
  type Page,
  type PagePhoto,
  type PageSideContent,
} from '../../components/PhotoBook/PhotoBookProvider/PhotoBook.types'
import { type Photo } from '../../components/PhotoBank/PhotoBank.types'
import { isPhotoBookEmpty } from './photoBookSaver'
import { PHOTO_BOOK_HOMEPAGE_URL, PHOTO_BOOK_URL } from '../../config/config'
import { type Crop } from '../../utils/cropUtils.types'

export function getPhotoUrl(photo: Photo | undefined, showHighRes: boolean) {
  if (!photo) {
    return undefined
  }

  if (showHighRes) {
    return photo.highResUrl
  }

  return photo.minimalResUrl
}

export function isClosedOnBack(cover: Cover | null): boolean {
  return (cover?.isFrontOpen && !cover?.isBackOpen) || false
}

export function isClosedOnFront(cover: Cover | null): boolean {
  return (cover?.isBackOpen && !cover?.isFrontOpen) || false
}

export function isBlankPageSide(page: PageSideContent) {
  return page.photos.length === 0
}

export function photosInPages(pages: Page[]) {
  return pages.reduce((acc: PagePhoto[], page) => {
    return [...acc, ...page.front.photos, ...page.back.photos]
  }, [])
}

export function getBookPhotosUids(cover: Cover, pages: Page[]) {
  return [cover.photo.uid, ...photosInPages(pages).map((photo) => photo.uid)]
}

export const getPhotoBookURL = () => {
  return isPhotoBookEmpty() ? PHOTO_BOOK_HOMEPAGE_URL : PHOTO_BOOK_URL
}

export function didUserFlip(source: FlipPageParams['source']): boolean {
  const userActions: FlipPageParams['source'][] = [
    'Swipe',
    'Arrow Key',
    'Arrow Button',
  ]
  return userActions.includes(source)
}

export function isPositionInsideBook(
  x: number,
  y: number,
  bookMotionRect: BookMotionRect
) {
  return (
    x < bookMotionRect.right.get() &&
    x > bookMotionRect.left.get() &&
    y > bookMotionRect.top.get() &&
    y < bookMotionRect.bottom.get()
  )
}

export function isCropMatted(cropParams: Crop): boolean {
  return cropParams.width > 1 && cropParams.height > 1
}
