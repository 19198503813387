import { styled } from 'styled-components'
import { CheckButtonIcon } from '../../icons'

export const Checked = styled(CheckButtonIcon)`
  width: 21px;
`

export const AttachedContainer = styled.div`
  cursor: pointer;
`
