import * as S from './EmbeddedLogin.styles'
import EmbeddedLoginCodeDialog from './Dialogs/EmbeddedLoginCodeDialog'
import EmbeddedLoginDialog from './Dialogs/EmbeddedLoginDialog'
import EmbeddedLoginErrorDialog from './EmbeddedLoginErrorDialog'
import React, { useRef, useState } from 'react'
import { analytics, EVENT_NAMES } from '../../services/Analytics/Analytics'
import { useAuth } from '../../services/AuthProvider/AuthProvider'
import useOnMount from '../../hooks/useOnMount'
import {
  redirectAfterLogin,
  saveQueryParamsBeforeLogin,
} from '../../services/LoginUtills'
import { useResizeDetector } from 'react-resize-detector'
import useScreenSize from '../../hooks/useScreenSize'
import { intercomService } from '../../services/Analytics/platforms/intercom'
import UserManager from '../../services/UserManager'
import Divider from '../../pages/HomePage/Components/Divider/Divider'

export default function EmbeddedLoginContent({
  isMixtilesPlusFlow = false,
  onLoginProcessStart,
  source,
  redirectTo,
  onDismiss,
}) {
  const { isAuthenticated } = useAuth()
  const [email, setEmail] = useState(UserManager.getUserEmail() || '')
  const [showCode, setShowCode] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const contentRef = useRef()
  const { height: contentHeight } = useResizeDetector({
    targetRef: contentRef,
    refreshMode: 'debounce',
    refreshRate: 0,
  })
  const isMediumScreen = useScreenSize('md')

  useOnMount(() => {
    if (isAuthenticated) {
      handleAuthentication()
    }
    saveQueryParamsBeforeLogin(window.location.search)
  })

  const handleAuthentication = () => {
    // isVerified is assumed because in embedded login account are always verified.
    return redirectAfterLogin({ isVerified: true })
  }

  const talkToUsPressed = (screenName) => {
    analytics.track(EVENT_NAMES.TALK_TO_US_TAPPED, {
      'Screen Name': screenName,
    })
    intercomService.show()
  }

  const onDismissErrorDialog = () => {
    setErrorMessage(null)
  }

  const showError = (e) => {
    const error = {
      message: 'Please try again or contact support for help',
      title: 'Login unsuccessful',
    }

    if (e.error === 'invalid_grant' || e.error === 'bad.email') {
      error.message =
        'Either the email or the code you entered is incorrect. Please try to re-enter the code, or re-enter your email and try again.'
      error.title = 'Incorrect email or code'
    } else if (e.error === 'too_many_attempts') {
      error.message = 'Please check your email to unblock your account.'
      error.title = 'Too many incorrect attempts'
    }

    const analyticsError = {
      'Error Code': e.error,
      'Error Message': e.errorDescription,
    }
    if (!e.error) {
      analyticsError.Error = e
    }

    analytics.track(EVENT_NAMES.LOGIN_ERROR, analyticsError)
    setErrorMessage(error)
  }

  const dialogsProps = {
    showError,
    showCode,
    setShowCode,
    email,
    setEmail,
    source,
    redirectTo,
    isMediumScreen,
    isMixtilesPlusFlow,
    onLoginProcessStart,
    onDismiss,
  }

  return (
    <>
      <Divider transparent margin={showCode ? 10 : 20} />
      <S.MainContainer $height={contentHeight}>
        <S.ContentContainer ref={contentRef}>
          {showCode && (
            <S.TopContainer>
              <S.BackButton onClick={() => setShowCode(false)} />
            </S.TopContainer>
          )}
          <S.ViewContainer>
            {showCode ? (
              <EmbeddedLoginCodeDialog {...dialogsProps} />
            ) : (
              <EmbeddedLoginDialog {...dialogsProps} />
            )}
          </S.ViewContainer>
          <EmbeddedLoginErrorDialog
            show={!!errorMessage}
            error={errorMessage}
            onDismiss={onDismissErrorDialog}
            talkToUsPressed={() =>
              talkToUsPressed('Embedded Login Error Dialog')
            }
          />
        </S.ContentContainer>
      </S.MainContainer>
      <Divider
        transparent
        margin={isMediumScreen ? (showCode ? 0 : 16) : showCode ? 0 : 12}
      />
    </>
  )
}
