import MixtilesDialog from '../MixtilesDialog/MixtilesDialog'
import CloseButton from '../TopBar/CloseButton/CloseButton'
import DialogHeader from '../TopBar/DialogHeader'
import { SUPPORTED_LANGUAGES } from '../../locale/Translation.consts'
import getFlagUrl from './getFlagUrl'
import { useTranslation } from 'react-i18next'

export default function LanguageSelectorDialog({
  open,
  onClose,
  currentLanguage,
  onChangeLanguage,
}) {
  const { t } = useTranslation()

  return (
    <MixtilesDialog open={open} onDismiss={() => onClose()}>
      <DialogHeader
        leftComp={<CloseButton action={() => onClose()} />}
        noBottomMargin
        title={t('general.languages.change_language')}
      />
      <div className="language-selector-content">
        <ul className="languages-list">
          {SUPPORTED_LANGUAGES.map((lang) => (
            <li
              key={lang}
              className={currentLanguage === lang ? 'selected' : ''}
              onClick={() => onChangeLanguage(lang)}
            >
              <img
                className="flag"
                src={getFlagUrl(lang)}
                alt={`${lang} flag`}
              />
              <span>{t(`general.languages.${lang}`)}</span>
            </li>
          ))}
        </ul>
      </div>
    </MixtilesDialog>
  )
}
