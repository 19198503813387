import { up } from 'styled-breakpoints'
import { useBreakpoint } from 'styled-breakpoints/react-styled'
import { useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'
import useOnMount from './useOnMount'
import { isServer } from 'utils/runtimeUtils'
import { type DeviceType, useDevice } from 'services/DeviceContext'

// This should stay false forever in the server, and change to true in client only
// Used to prevent a flicker after the first render. Once we hydrated we never want
// to use the server results again.
let clientBased = false

export type ScreenSizeBreakpoint = 'sm' | 'md' | 'lg' | 'xxl'
const breakpointToDeviceTypes: Record<ScreenSizeBreakpoint, DeviceType[]> = {
  sm: ['mobile', 'tablet', 'desktop'],
  md: ['tablet', 'desktop'],
  lg: ['desktop'],
  // We can't detect xxl screen from user agent, so we opt to say it's not xxl on the server
  xxl: [],
}

export default function useScreenSize(breakpoint: ScreenSizeBreakpoint) {
  const device = useDevice()
  const [isClientBased, setIsClientBased] = useState(clientBased)
  const themeContext = useContext(ThemeContext)
  const match = useBreakpoint(up(breakpoint))

  // This makes it so if there is a difference between the browser and the server about
  // whether the screen is large, we will use the server version in the first render.
  // This prevent possible hydration errors, since the client needs to render the exact
  // same thing the server did. On the second render this will flicker to client calculation.
  useOnMount(() => {
    setIsClientBased(true)
    clientBased = true
  })

  if (isServer() || !isClientBased) {
    return breakpointToDeviceTypes[breakpoint].includes(device.type)
  }

  if (match === null) {
    if (!themeContext) throw new Error('Missing themeConext')

    const viewportWidth = window.visualViewport
      ? window.visualViewport.width
      : document.body.clientWidth
    return viewportWidth >= themeContext.breakpoints[breakpoint]
  }

  return match
}
