import { styled } from 'styled-components'
import { onMediumScreen } from 'mixtiles-web-common'
import { XIcon } from '../../../icons'
import SkeletonRow from '../../SkeletonRow/SkeletonRow'
import MixtilesDialog from '../../MixtilesDialog/MixtilesDialog'

export const Dialog = styled(MixtilesDialog)`
  &.MuiDialog-paper {
    background: ${({ theme }) => theme.colors.grayBackgroundWhite};
  }
`

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.grayBackgroundWhite};
  display: flex;
  flex-direction: column;
  ${onMediumScreen()} {
    width: 460px;
  }
`

export const TopBar = styled.div`
  background: ${({ theme }) => theme.colors.grayBackgroundWhite};
  z-index: 2;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayMedium};
  margin-bottom: 20px;
  padding: 15px;
  ${onMediumScreen()} {
    padding: 0 30px;
  }
`

export const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.black};
  ${onMediumScreen()} {
    padding-bottom: 27px;
    padding-top: 27px;
  }
`

export const CloseButton = styled(XIcon)`
  width: 32px;
  height: 32px;
  cursor: pointer;
`

export const DoneButton = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  ${onMediumScreen()} {
    padding-bottom: 29px;
    padding-top: 29px;
  }
`

export const CouponContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min(90%, 400px);
  align-self: center;
`

const CouponCategoryHeader = styled.div`
  margin-top: 10px;
  padding-top: 16px;
  padding-bottom: 7px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px 10px 0 0;
  border-top: solid 1px ${({ theme }) => theme.colors.grayAccent};
  border-right: solid 1px ${({ theme }) => theme.colors.grayAccent};
  border-left: solid 1px ${({ theme }) => theme.colors.grayAccent};
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
`

export const DiscountCouponCategoryHeader = styled(CouponCategoryHeader)`
  padding-bottom: 7px;
`

export const FreeTilesCouponCategoryHeader = styled(CouponCategoryHeader)`
  padding-bottom: 4px;
`

export const CouponCategoryTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  align-self: center;
`

export const Remove = styled.div`
  text-decoration: underline;
  align-self: center;
`

export const PromoCutLine = styled.img`
  width: 100%;
  -webkit-user-drag: none;
`

export const BoldText = styled.span`
  font-size: 16px;
  padding: 0 0 0 20px;
  line-height: 135%;
  font-weight: 700;
`

export const Text = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grayDark};
  padding-left: 20px;
  line-height: 135%;
`

export const Ands = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 20px auto 14px auto;
`

export const AndLineLeft = styled.div`
  margin-right: 25px;
  margin-left: auto;
  width: 134px;
  margin-top: 2%;
  height: 1px;
  background: ${({ theme }) => theme.colors.grayMedium};
`

export const And = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 18.9px;
  color: ${({ theme }) => theme.colors.grayDark};
`

export const AndLineRight = styled.div`
  margin-left: 25px;
  margin-right: auto;
  width: 134px;
  margin-top: 2%;
  height: 1px;
  background: ${({ theme }) => theme.colors.grayMedium};
`

export const AddPromoCode = styled.div`
  text-decoration: underline;
  font-weight: 400;
  line-height: 135%;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.secondaryDark};
  ${onMediumScreen()} {
    margin: 15px 0 30px 0;
  }
  margin: 12px 0 10px 0;
  cursor: pointer;
  align-self: flex-start;
`

export const CouponSkeleton = styled(SkeletonRow)`
  margin: 5.125px 0;
`
