import React, { Component } from 'react'
import { translateManager as t } from '../../services/TranslateManager'
import './LanguageSelector.scss'
import LanguageSelectorDialog from './LanguageSelectorDialog'
import getFlagUrl from './getFlagUrl'

class LanguageSelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      language: t.getLanguage(),
      opened: false,
    }
  }

  changeLanguage(lang) {
    if (!this.state.opened || this.state.language === lang) {
      return false
    }
    this.setState({
      language: lang,
    })
    t.setLanguage(lang)
  }

  render() {
    const { language } = this.state

    return (
      <div id="language_selector_wrapper">
        <div
          className="trigger"
          onClick={() => this.setState({ opened: true })}
        >
          <div className="arrow" />
          <img
            className="flag"
            src={getFlagUrl(language)}
            alt={`${language} flag`}
          />
          <span>{t.get(`general.languages.${language}`)}</span>
        </div>

        <LanguageSelectorDialog
          onClose={() => this.setState({ opened: false })}
          open={this.state.opened}
          currentLanguage={this.state.language}
          onChangeLanguage={(lang) => this.changeLanguage(lang)}
        />
      </div>
    )
  }
}

export default LanguageSelector
