import { styled } from 'styled-components'

export const StyledBox = styled.div`
  padding: ${({ $isArt }) => ($isArt ? '5px 5px 15px 8px' : '10px 5px 15px 0')};
  margin: ${({ $isArt }) => ($isArt ? '15px 13px 5px 12px' : '0 25px 0')};
  border-bottom: ${({ theme }) => theme.colors.grayMedium} 1px solid;
`

export const Content = styled.div`
  margin-top: 1px;
  margin-left: ${({ $isArt }) => ($isArt ? '12px' : '0')};
`

export const Text = styled.div`
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.primary};
  margin-left: 16px;
`
