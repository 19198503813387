import React from 'react'
import { RouteLink } from 'components/RouteLink/RouteLink'
import {
  BUSINESS_PAGE_URL,
  BLOG_URL,
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICE_URL,
} from '../../../config/config'
import { translateManager as t } from '../../../services/TranslateManager'
import * as S from './MenuFooter.styles'
import { useProductType } from '../../../services/ProductTypesManager'
import LanguageSelector from '../../LanguageSelector/LanguageSelector'
import { analytics } from '../../../services/Analytics/Analytics'
import { PRODUCT_TYPES } from '../../../services/ProductTypeState'
import { experimentManager } from '../../../services/ExperimentManager/ExperimentManager'

function MenuFooter() {
  const { productType } = useProductType()

  const handlePrivacyPolicyClicked = () => {
    analytics.track('Menu Item Tapped', { Item: 'PRIVACY_POLICY_LINK' })
  }

  const handleTermsOfUseClicked = () => {
    analytics.track('Menu Item Tapped', { Item: 'TERMS_OF_USE' })
  }

  const handleBusinessClicked = () => {
    analytics.track('Menu Item Tapped', { Item: 'BUSINESS' })
  }

  return (
    <S.Container>
      <S.SubMenu>
        <RouteLink to={BUSINESS_PAGE_URL} onClick={handleBusinessClicked}>
          {t.get('general.menu.for_business')}
        </RouteLink>
        {experimentManager.isEnabled('blog') && (
          <RouteLink to={BLOG_URL}>{t.get('general.menu.our_blog')}</RouteLink>
        )}
        <RouteLink to={PRIVACY_POLICY_URL} onClick={handlePrivacyPolicyClicked}>
          {t.get('general.menu.privacy_policy')}
        </RouteLink>
        <S.Link onClick={() => window.UC_UI.showSecondLayer()}>
          {t.get('homepage.cookies_choices')}
        </S.Link>
        <RouteLink to={TERMS_OF_SERVICE_URL} onClick={handleTermsOfUseClicked}>
          {t.get('general.gdpr_dialog.terms_of_use')}
        </RouteLink>
        <div data-acsb-custom-trigger="true">
          {t.get('general.menu.accessibility_button')}
        </div>
      </S.SubMenu>
      {productType !== PRODUCT_TYPES.ART && (
        <S.LanguageContainer>
          <LanguageSelector />
        </S.LanguageContainer>
      )}
    </S.Container>
  )
}

export default MenuFooter
