import React, { Component, type ReactNode } from 'react'
import './Spinner.scss'
import classNames from 'classnames'

interface SpinnerProps {
  white?: boolean
  grey?: boolean
  classes?: string
  text?: string
}

class Spinner extends Component<SpinnerProps> {
  render(): ReactNode {
    const spinnerClass = classNames(
      'spinner',
      { white: this.props.white, grey: this.props.grey },
      this.props.classes
    )
    return (
      <div className={spinnerClass}>
        <div />
        {this.props.text && <p className="loading-text">{this.props.text}</p>}
      </div>
    )
  }
}

export default Spinner
