import * as pako from 'pako'
import { getUrlParam } from '../utils/UrlUtils'
import { logger } from './logger'

const STRING_LENGTH_COMPRESSION_THRESHOLD = 50000 // Above this size, compress the string before saving to localStorage
const COMPRESSED_STRING_MAGIC = '#compressed#'

class SessionStorageManager {
  static set(key, value) {
    let valueString = null
    if (value != null) {
      // not null or undefined
      valueString = JSON.stringify(value)

      // Compress the string if needed (don't compress experiments or tiles)
      if (valueString.length > STRING_LENGTH_COMPRESSION_THRESHOLD) {
        try {
          const compressedValue =
            COMPRESSED_STRING_MAGIC +
            pako.deflate(valueString, { to: 'string' })
          window.sessionStorage.setItem(key, compressedValue)
          return
        } catch (error) {
          logger.error(
            'Error while compressing value, saving without compression',
            error,
            { key }
          )
        }
      }
    }

    window.sessionStorage.setItem(key, valueString)
  }

  static get(key) {
    let value = window.sessionStorage.getItem(key)
    // Decompress the string if needed
    if (value && value.startsWith(COMPRESSED_STRING_MAGIC)) {
      value = pako.inflate(value.slice(COMPRESSED_STRING_MAGIC.length), {
        to: 'string',
      })
    }

    return value && JSON.parse(value)
  }

  static getWithDefaultValue(key, defaultValue) {
    let value = this.get(key)
    if (value == null) {
      this.set(key, defaultValue)
      value = defaultValue
    }
    return value
  }

  static remove(key) {
    window.sessionStorage.removeItem(key)
    return true
  }

  static setFromUrlParam(param, key, getValueFunction) {
    const paramValue = getUrlParam(param)
    if (paramValue !== undefined) {
      SessionStorageManager.set(
        key,
        getValueFunction ? getValueFunction(paramValue) : paramValue
      )
    }
  }
}

export default SessionStorageManager
