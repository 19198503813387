import { createTheme } from '@mui/material'
import { BREAKPOINTS, generateMuiTheme } from './defaultTheme'

export const PHOTOS_THEME = 'Photos'

export const BRAND_COLORS = {
  primary: '#EB2371',
  primaryAccent: '#D72A6E',
  secondaryDark: '#472C35',
  secondaryPink: '#FFC4D8',
  black: '#2B0514',
  grayDark: '#958289',
  grayMidDark: '#B5A7AC',
  grayMedium: '#EBE7E7',
  grayAccent: '#F2E9E9',
  grayBright: '#FBF6F6',
  grayBackgroundWhite: '#FBF9F9',
  deepBlackEmergencyOnly: '#000000',
  white: '#FFFFFF',
  errorRed: '#D55825',
}

export const theme = {
  name: PHOTOS_THEME,
  breakpoints: BREAKPOINTS,
  colors: {
    ...BRAND_COLORS,
    // TODO: Remove these old colors once finished rebranding all pages
    lightBlack: '#333333',
    darkBlack: '#0F0E0F',
    darkBlack2: '#261F23',
    gray: '#808080',
    gray2: '#7E7E7E',
    gray3: '#EDEDED',
    gray4: '#BFBFBF',
    gray5: '#5E4F55',
    gray6: '#E2E2E2',
    gray7: '#808080',
    gray8: '#BFBFBF', // same as art
    gray9: '#7B7B7B',
    lightGray: '#F7F7F7',
    lightGray2: '#f8f8f8',
    lightGray3: '#fdfdfd',
    pinkText: '#ff0095',
    pinkBackground: '#fcf6f9',
    drawerGray: '#AFAEAE',
    dialogGray: '#7D7C7C',
    darkGray: '#ACACAC',
    darkerGray: '#938F91', // same as art
    mainPink: '#FF0072', // same as pink3 on mixin

    /* New Colors : Rebranding */
    main: '#EB2371',
    grayShade1: '#333333',
    grayShade2: '#808080',
    grayShade4: '#EDEDED',
    brandBlack: '#2B0514',
    hoverGray: '#FFFFFF', // This was added here because we need to have the same properties for the photos palette and art palette because of Typescript.
  },
  brandColorPalette: {
    // TODO: After rebranding, Moments should remove "branchColorPalette" and use the brand color palette from "colors"
    pinkBackground: '#fbf6f6',
    mainPink: '#eb2371',
    darkPink: '#f6efef',
    black: '#2B0514',
    secondaryDark: '#610C2D',
    gray: '#958289',
    darkGray: '#9E9799',
    lightGray: '#F2F2F2',
  },
}

export const muiTheme = createTheme(generateMuiTheme(theme.colors))
