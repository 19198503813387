import StorageManager from '../../services/StorageManager'

export const PASSED_ART_ONBOARDING_SCREEN_KEY = 'Passed Art Onboarding Screen'

class ArtOnboardingManager {
  shouldStartOnboardingProcess() {
    return !StorageManager.get(PASSED_ART_ONBOARDING_SCREEN_KEY)
  }

  finishedOnboarding() {
    StorageManager.set(PASSED_ART_ONBOARDING_SCREEN_KEY, true)
  }

  resetOnboardingStatus() {
    StorageManager.set(PASSED_ART_ONBOARDING_SCREEN_KEY, false)
  }
}

export const artOnboardingManager = new ArtOnboardingManager()
