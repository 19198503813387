import React, { type PropsWithChildren, forwardRef, useRef } from 'react'
import './RadioButton.scss'
import classNames from 'classnames'
import { Radio } from '@mui/material'
import isEqual from 'lodash/isEqual'
import * as S from './RadioButton.styles'

type Props = {
  className?: string
  value: string
  selectedValue: string
  name?: string
  onChange: (value: any) => void
  compare?: (value: string, selectedValue: string) => boolean
}

const RadioButton = forwardRef<HTMLButtonElement, PropsWithChildren<Props>>(
  (
    {
      className,
      value,
      onChange,
      selectedValue,
      name,
      children,
      compare = isEqual,
      ...radioProps
    },
    ref
  ) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const checked = compare(value, selectedValue)

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      if (!checked) {
        onChange(e)
      }
    }

    // Clicking on the attached container should act as though we clicked on the actual radio button
    function onAttachedContainerClick() {
      if (!checked && containerRef.current) {
        const radioInput = containerRef.current.querySelector('input')
        if (radioInput) {
          radioInput.click()
        }
      }
    }

    return (
      <div
        className={classNames('radio-button-container', className)}
        ref={containerRef}
      >
        <Radio
          classes={{ root: 'radio-button' }}
          checked={checked}
          value={value}
          disableRipple
          checkedIcon={<S.Checked />}
          icon={<span className="unchecked" />}
          ref={ref}
          onChange={handleChange}
          name={name || JSON.stringify(value)}
          {...radioProps}
        />
        <S.AttachedContainer onClick={onAttachedContainerClick}>
          {children}
        </S.AttachedContainer>
      </div>
    )
  }
)

export default RadioButton
