import React, { useState, useEffect } from 'react'
import * as S from './PromoCodeForm.styles'
import './PromoCodeForm.scss'
import {
  DISCOUNT_TYPE_BUYXGETY,
  DISCOUNT_TYPE_GIFT_CARD,
  PROMO_CODE_ERRORS_MESSAGES,
  promoCodeManager,
} from '../../../services/PromoCodeManager'
import DummySubmit from '../../DummySubmit/DummySubmit'
import { translateManager as t } from '../../../services/TranslateManager'
import { logger } from '../../../services/logger'
import { withApi } from '../../../api/apiProvider'
import TextInput, { VARIANTS } from 'mixtiles-web-common/ui/TextInput'

import { TILE_SIZES, MATERIAL_TYPES } from '@mixtiles/web-backend-shared'
import useBundleDiscount from '../../../hooks/useBundleDiscount'
import AlertPopup from '../../AlertPopup/AlertPopup'
import GiftCardSuccessDialog from '../GiftCardSuccessDialog/GiftCardSuccessDialog'
import { formatPrice } from '../../../utils/utils'

function PromoCodeForm({ onDismiss, onSuccess, onClose, api, source }) {
  const [promoCode, setPromoCode] = useState()
  const [showPromoFailureDialog, setShowPromoFailureDialog] = useState(false)
  const [promoFailureDialogText, setPromoFailureDialogText] = useState('')
  const [indicateInvalidInput, setIndicateInvalidInput] = useState(false)
  const [promoCodeSuccessInfo, setPromoCodeSuccessInfo] = useState({})
  const [showPromoCodeSuccessDialog, setShowPromoCodeSuccessDialog] =
    useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [returnToSelector, setReturnToSelector] = useState(false)
  const {
    isLoading: isLoadingDiscount,
    discount,
    setIsLoading,
  } = useBundleDiscount({
    tileSize: TILE_SIZES.SQUARE_8X8,
    materialType: MATERIAL_TYPES.CLASSIC,
  })

  const handlePromoCodeInputChanged = (value) => {
    const MAX_LENGTH = 30
    if (value.length <= MAX_LENGTH) {
      setPromoCode(value.toUpperCase())
    }
  }

  const handleDone = async () => {
    if (!(promoCode && promoCode.toUpperCase().trim())) {
      setIndicateInvalidInput(true)
      return
    }
    setSubmitting(true)
    try {
      const { discountType, amount, error, code, metadata } =
        await promoCodeManager.addPromoCode({
          code: promoCode,
          api,
          source,
        })
      setIsLoading(true)
      setSubmitting(false)

      if (error) {
        handlePromoFailure(error)
      } else {
        if (discountType === DISCOUNT_TYPE_GIFT_CARD) {
          setShowPromoCodeSuccessDialog(true)
          setPromoCodeSuccessInfo({
            discountType,
            metadata,
          })
        } else {
          setPromoCodeSuccessInfo({
            discountType,
            amount,
            code,
          })
          setReturnToSelector(true)
        }
      }
    } catch (error) {
      logger.error('Failed to add promo-code', error)
      handlePromoFailure(error)
    }
  }

  useEffect(() => {
    if (!returnToSelector || isLoadingDiscount) {
      return
    }
    const discountValue = discount?.value
    const couponData = promoCodeManager.getDiscountCoupon()
    if (
      discountValue &&
      promoCodeSuccessInfo.discountType === DISCOUNT_TYPE_BUYXGETY
    ) {
      couponData.metadata.percentOff = Math.round(
        (discountValue.metadata.get * 100) /
          (discountValue.metadata.buy + discountValue.metadata.get)
      )
      promoCodeManager.editDiscountCoupon(couponData)
    }
    onClose()
  }, [returnToSelector, isLoadingDiscount])

  const handlePromoFailure = (reason) => {
    const key =
      PROMO_CODE_ERRORS_MESSAGES[reason] || PROMO_CODE_ERRORS_MESSAGES.DEFAULT

    setPromoFailureDialogText(t.get(key))
    setShowPromoFailureDialog(true)
  }

  const handleCloseAllPromoDialogs = () => {
    if (showPromoCodeSuccessDialog) {
      onSuccess?.(promoCodeSuccessInfo)
      onDismiss()
    }
    setShowPromoCodeSuccessDialog(false)
    setShowPromoFailureDialog(false)
    setPromoCodeSuccessInfo({})
  }

  /* Handle form submit action (pressing Enter on the final input */
  const handleSubmit = async (event) => {
    event.preventDefault()
    await handleDone()
  }

  const doneButton = submitting ? (
    <div className="done-spinner" />
  ) : (
    <S.DoneButton onClick={handleDone} data-testid="promo-code-done-button">
      {t.get('general.done')}
    </S.DoneButton>
  )

  return (
    <>
      <S.Container className="PromoCodeForm">
        <S.TopBar>
          <S.CloseButton onClick={onClose} />
          <S.Title>{t.get('general.menu.add_promo_code.modal.title')}</S.Title>
          {doneButton}
        </S.TopBar>
        <div className="content">
          <form onSubmit={handleSubmit}>
            <S.Label>
              {t.get('general.menu.add_promo_code.modal.form_title')}
            </S.Label>
            <TextInput
              variant={VARIANTS.white}
              fullWidth
              placeholder={t.get(
                'general.menu.add_promo_code.modal.placeholder'
              )}
              value={promoCode?.toUpperCase()}
              autoFocus
              onChange={handlePromoCodeInputChanged}
              isInvalid={indicateInvalidInput}
              testId="promo-code-input"
            />
            <DummySubmit />
          </form>
        </div>
      </S.Container>
      <AlertPopup
        open={showPromoFailureDialog}
        title={promoFailureDialogText}
        onClick={handleCloseAllPromoDialogs}
        buttonText={t.get('general.ok')}
        titleTestId="promo-code-failure-dialog"
      />
      {promoCodeSuccessInfo.discountType === DISCOUNT_TYPE_GIFT_CARD && (
        <GiftCardSuccessDialog
          open={showPromoCodeSuccessDialog}
          onClose={handleCloseAllPromoDialogs}
          title={t.get('general.promo_code_success_dialog.title.gift_card')}
          subtitle={t.get(
            'general.promo_code_success_dialog.subtitle.gift_card',
            {
              v1: formatPrice(
                promoCodeSuccessInfo.metadata.remainingValue,
                promoCodeSuccessInfo.metadata.currency
              ),
            }
          )}
          onDismiss={handleCloseAllPromoDialogs}
        />
      )}
    </>
  )
}

export default withApi(PromoCodeForm)
