import { createContext, useContext } from 'react'
import type { KeyTypes } from 'server/keys.server'

export type KeysKey = KeyTypes | 'ipCountry' | 'ipAddress' | 'ipState'
export type KeysValue = Record<KeysKey, string>
export const KeysContext = createContext<KeysValue | null>(null)

export const useKeys = () => {
  const keys = useContext(KeysContext)

  if (!keys) {
    throw new Error('Keys not found in KeysContext')
  }

  return keys
}
