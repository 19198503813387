import React from 'react'
import * as S from './MenuItems.styles'
import { MenuItem } from '../MenuItem/MenuItem'
import {
  ArtIcon,
  BookIcon,
  ChatIcon,
  GalleryWallsIcon,
  GiftIcon,
  HeartIcon,
  HistoryIcon,
  PercentageMarkIcon,
  PhotoIcon,
  QuestionMarkIcon,
  ReferralIcon,
} from '../../../icons'
import { translateManager as t } from '../../../services/TranslateManager'
import {
  DISCOVERY_URL,
  GIFT_CARD,
  JUMBLE_URL_PREFIX,
  MY_ORDERS_URL,
  ONBOARDING_URL,
  PHOTO_STYLER_URL,
  REFERRAL,
} from '../../../config/config'
import { ART_ROUTES, ART_SCREENS } from '../../../Art/config/art-consts'
import StorageManager from '../../../services/StorageManager'
import { ACTIVE_ORDERS_COUNT_KEY } from '../../../services/LoginUtills'
import { emailCaptureManager } from '../../../services/EmailCaptureManager'
import { navigationManager } from '../../../services/NavigationManager'
import { MENU_ITEMS } from './MenuItems.consts'
import { QUIZ_STEPS } from '../../../pages/EmailCapturePage/EmailCapturePage.consts'
import { intercomService } from '../../../services/Analytics/platforms/intercom'
import { useExperimentManager } from '../../../services/ExperimentManager/ExperimentManager'
import { getPhotoBookURL } from '../../../services/PhotoBook/photoBookUtils'
import GuarenteeElement from '../QualityGuaranteeElement/QualityGuaranteeElement'
import { getReferralPrice } from '../../../pages/Referral/referralUtils'
import { NavDivider } from '../Divider.styles'
import { isClient } from '../../../utils/runtimeUtils'
import { shouldShowClassicToArt } from '../../ArtPitchSection/ArtPitchSection'

export default function MenuItems({ onDismissMenu, onPromoCodeClick }) {
  const experimentManager = useExperimentManager()
  const isQualityGuaranteeEnabled = experimentManager.isEnabled(
    'quality-guarantee-banner'
  )

  const handleTalkToUsClicked = () => {
    intercomService.show()
    onDismissMenu()
  }

  const handleHelpCenterClick = () => {
    intercomService.showSpace('help')
    onDismissMenu()
  }

  const questionBeforeRedirect = (e, returnToPath) => {
    if (!emailCaptureManager.passedAnyOnboarding()) {
      // Redirect user to onboarding if they should pass it before continuing to the target url
      e.preventDefault()
      navigationManager.setNextOnFinishOnboarding({ pathname: returnToPath })
      navigationManager.goToPage(`${ONBOARDING_URL}/${QUIZ_STEPS.INTRO}`)
    }
  }

  const questionBeforeRedirectJumble = (e, returnToPath) => {
    if (!emailCaptureManager.passedJumbleOnboarding()) {
      // Redirect user to onboarding if they should pass it before continuing to the target url
      e.preventDefault()
      navigationManager.setNextOnFinishOnboarding({ pathname: returnToPath })
      navigationManager.goToPage(`${ONBOARDING_URL}/${QUIZ_STEPS.INTRO}`)
    }
  }

  return (
    <S.Container>
      <S.Section>
        {isQualityGuaranteeEnabled && (
          <S.Item>
            <GuarenteeElement />
          </S.Item>
        )}
        <S.Item>
          <MenuItem
            itemId={MENU_ITEMS.MIXTILES_CLASSIC}
            icon={<PhotoIcon />}
            text={t.get('general.menu.frame_your_photos')}
            location={{ pathname: PHOTO_STYLER_URL }}
            onClick={(e) => questionBeforeRedirect(e, PHOTO_STYLER_URL)}
            testId="frame-your-photos"
          />
        </S.Item>
        {experimentManager.isEnabled('photo-books') && (
          <S.Item>
            <MenuItem
              itemId={MENU_ITEMS.PHOTO_BOOK}
              icon={<BookIcon />}
              text={t.get('product_selection.photo_books_name')}
              location={{
                pathname: getPhotoBookURL(),
                state: { source: 'Menu' },
              }}
              isNew
            />
          </S.Item>
        )}
        {experimentManager.isEnabled('jumble') && (
          <S.Item>
            <MenuItem
              itemId={MENU_ITEMS.JUMBLE}
              icon={<HeartIcon />}
              text="Jumble"
              location={{ pathname: JUMBLE_URL_PREFIX }}
              onClick={(e) =>
                questionBeforeRedirectJumble(e, JUMBLE_URL_PREFIX)
              }
            />
          </S.Item>
        )}
        {experimentManager.isEnabled('gallery-walls') && (
          <S.Item>
            <MenuItem
              itemId={MENU_ITEMS.GALLERY_WALLS}
              icon={<GalleryWallsIcon />}
              text={t.get('pitch.footer.gallery_walls')}
              location={{ pathname: DISCOVERY_URL }}
              onClick={(e) => questionBeforeRedirect(e, DISCOVERY_URL)}
            />
          </S.Item>
        )}
        {isClient() &&
          shouldShowClassicToArt() &&
          !experimentManager.isEnabled('art-in-plus') && (
            <S.Item>
              <MenuItem
                itemId={MENU_ITEMS.ART}
                icon={<ArtIcon />}
                text={t.get('general.menu.browse_art_collection')}
                location={{
                  pathname: ART_ROUTES[ART_SCREENS.HOME],
                  search: '?arrivedFrom=Classic Menu',
                }}
                target="_blank"
                testId="browse-art"
              />
            </S.Item>
          )}
      </S.Section>
      <NavDivider />
      <S.Section>
        <S.Item>
          <MenuItem
            itemId={MENU_ITEMS.GIFT_CARD}
            icon={<GiftIcon />}
            text={t.get('general.menu.buy_gift_card')}
            location={{ pathname: GIFT_CARD }}
            testId="gift-card-menu-item"
          />
        </S.Item>
        <S.Item>
          <MenuItem
            itemId={MENU_ITEMS.PROMO_CODE}
            icon={<PercentageMarkIcon />}
            text={t.get('general.menu.promo_code_manager.promo_codes')}
            onClick={onPromoCodeClick}
            testId="add-promo-menu-item"
          />
        </S.Item>
        <S.Item>
          <MenuItem
            itemId={MENU_ITEMS.MY_ORDERS}
            icon={<HistoryIcon />}
            text={t.get('general.menu.my_orders')}
            location={{ pathname: MY_ORDERS_URL }}
            tag={StorageManager.getWithDefaultValue(ACTIVE_ORDERS_COUNT_KEY, 0)}
            testId="my-orders-menu-item"
          />
        </S.Item>
        {experimentManager.isEnabled('referral') && (
          <S.Item>
            <MenuItem
              itemId={MENU_ITEMS.REFERRAL}
              icon={<ReferralIcon />}
              text={t.get('referral.title', { v1: getReferralPrice() })}
              location={{ pathname: REFERRAL }}
              testId="referral-menu-item"
            />
          </S.Item>
        )}
        <S.Item>
          <MenuItem
            itemId={MENU_ITEMS.HELP_CENTER}
            icon={<QuestionMarkIcon />}
            text={t.get('general.menu.help_center')}
            onClick={handleHelpCenterClick}
            testId="help-center-menu-item"
          />
        </S.Item>
        <S.Item>
          <MenuItem
            itemId={MENU_ITEMS.CHAT_WITH_US}
            icon={<ChatIcon />}
            text={t.get('general.menu.chat_with_us')}
            onClick={handleTalkToUsClicked}
            testId="chat-menu-item"
          />
        </S.Item>
      </S.Section>
    </S.Container>
  )
}
