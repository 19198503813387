import { mixtilesAxios } from '../utils/ApiUtils'
import UserManager from '../services/UserManager'
import { API_METHODS, createApiFunction } from './apiProvider'
import { translateManager as t } from '../services/TranslateManager'
import { LANGUAGES } from '../locale/Translation.consts'
import { GiftCardType } from '@mixtiles/web-backend-shared'

export const PATH = 'giftCards'

export const getGiftCardsBalance = async (
  giftCodes: string[],
  pricingCountry: string
) => {
  const response = await mixtilesAxios.get(`${PATH}/balance`, {
    params: {
      giftCodes,
      pricingCountry,
    },
  })
  return response.data
}

export const sendReferral = (email: string, customerName: string) => {
  const params = {
    language: t.getLanguage() || LANGUAGES.ENGLISH,
    type: GiftCardType.VALUE,
    recipientEmail: email,
    customerName,
    customerEmail: UserManager.getUserEmail(),
  }

  // @ts-ignore
  return createApiFunction(API_METHODS.POST, `${PATH}/referral`, params, true)
}
