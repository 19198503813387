import { styled, keyframes } from 'styled-components'

const cubemove = keyframes`
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  } 50% {
      transform: translateX(42px) translateY(42px) rotate(-179deg);
      -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
    } 50.1% {
        transform: translateX(42px) translateY(42px) rotate(-180deg);
        -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
      } 75% {
          transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
          -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
        } 100% {
            transform: rotate(-360deg);
            -webkit-transform: rotate(-360deg);
          }
`

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const Spinner = styled.div`
  margin: auto auto;
  width: 40px;
  height: 40px;
  position: relative;
`

export const FirstCube = styled.svg`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: ${cubemove} 1.8s infinite ease-in-out;
  animation: ${cubemove} 1.8s infinite ease-in-out;
`

export const SecondCube = styled(FirstCube)`
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
`
