import React from 'react'
import * as S from './SkeletonRow.styles'

function SkeletonRow({ width, height, ...otherProps }) {
  return (
    <S.SkeletonRow
      $width={`${width}px`}
      $height={`${height}px`}
      {...otherProps}
    />
  )
}

export default SkeletonRow
