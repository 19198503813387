import {
  type Dimensions,
  type LocalPhoto,
  type Photo,
  type RemotePhoto,
  type ThumbDimensions,
} from './PhotoBank.types'

const MINIMAL_THUMB_WIDTH = 250
const MAX_THUMB_WIDTH = 700

function getObjectKeysWithEnforcement<T extends object>(obj: {
  [K in keyof Required<T>]: true
}): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[]
}

export const PERSISTED_PHOTO_BANK_FIELDS =
  getObjectKeysWithEnforcement<RemotePhoto>({
    uid: true,
    width: true,
    height: true,
    minimalResUrl: true,
    lowResUrl: true,
    highResUrl: true,
    originalUrl: true,
    hashCode: true,
    uploadedAt: true,
    uploadSrc: true,
    wasUsed: true,
    faces: true,
  })

const convertRemotePhotoToNewPhoto = (remotePhoto: RemotePhoto): Photo => {
  const {
    originalUrl,
    minimalResUrl,
    lowResUrl,
    highResUrl,
    width,
    height,
    uid,
    wasUsed,
    hashCode,
    uploadSrc,
    faces,
  } = remotePhoto

  return {
    width,
    height,
    uid,
    url: originalUrl,
    minimalResUrl,
    lowResUrl,
    highResUrl,
    wasUsed,
    hashCode,
    uploadSrc,
    isLocal: false,
    thumbDimensions:
      originalUrl === highResUrl
        ? {
            minimal: { width, height },
            low: { width, height },
            high: { width, height },
          }
        : calculatePhotoThumbDimensions(width, height),
    faces,
  }
}

export const convertLocalPhotoToNewPhoto = (localPhoto: LocalPhoto): Photo => {
  const {
    width,
    height,
    uid,
    minimalResUrl,
    lowResUrl,
    highResUrl,
    localUrl,
    hashCode,
    uploadSrc,
    faces,
    thumbDimensions,
  } = localPhoto

  return {
    width,
    height,
    uid,
    url: localUrl,
    minimalResUrl,
    lowResUrl,
    highResUrl,
    wasUsed: false,
    isLocal: true,
    hashCode,
    uploadSrc,
    faces,
    thumbDimensions,
  }
}

export const convertRemoteOrLocalPhotosToPhotos = (
  remotePhotos: RemotePhoto[],
  localPhotos: LocalPhoto[]
): Photo[] => {
  const remotePhotosToPhotos = remotePhotos.map(convertRemotePhotoToNewPhoto)
  const localPhotosToPhotos = localPhotos.map(convertLocalPhotoToNewPhoto)

  return [...remotePhotosToPhotos, ...localPhotosToPhotos]
}

export const calculatePhotoThumbDimensions = (
  width: number,
  height: number
): ThumbDimensions => {
  const aspectRatio = width / height
  const getDimensions = (maxWidthOrHeight: number): Dimensions => {
    if (width > height) {
      const newWidth = Math.min(maxWidthOrHeight, width)
      return {
        width: newWidth,
        height: newWidth / aspectRatio,
      }
    }

    const newHeight = Math.min(maxWidthOrHeight, height)
    return {
      width: newHeight * aspectRatio,
      height: newHeight,
    }
  }

  return {
    minimal: getDimensions(MINIMAL_THUMB_WIDTH),
    low: getDimensions(MAX_THUMB_WIDTH),
    high: getDimensions(MAX_THUMB_WIDTH * 3),
  }
}
