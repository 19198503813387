import React from 'react'
import { navigationManager } from '../../services/NavigationManager'
import './ArtPitchSection.scss'
import RetinaImage from '../RetinaImage/RetinaImage'
import Badge from '../Badge/Badge'
import UserManager from '../../services/UserManager'
import { countryManager } from '../../services/CountryManager'
import ART_COUNTRIES from '../../Art/config/art-countries.js'

export function shouldShowClassicToArt() {
  // Everyone who has already been in art, or US users, should see the banners
  return (
    UserManager.isArtUser() ||
    ART_COUNTRIES.includes(countryManager.getCountry())
  )
}

export default function ArtPitchSection() {
  const handleShopNowClicked = () => {
    navigationManager.goToMixtilesArt('Classic Art Pitch Section')
  }

  return (
    <div className="ArtPitchSection">
      <div className="banner-content">
        <div className="banner-photo-div">
          <RetinaImage
            className="banner-photo"
            src="images/art/classicToArt/pitchSectionImage.png"
          />
          <Badge
            className="narrow-screen banner-photo-new-badge"
            fontSize="16px"
          >
            New!
          </Badge>
        </div>

        <div className="text-div">
          <div className="text">
            <div className="title-line">
              <div className="title">Mixtiles Art</div>
              <Badge className="wide-screen" fontSize="16">
                New!
              </Badge>
            </div>
            <div className="subtitle">
              Over 1000 artworks by <br /> the best artists in the world{' '}
            </div>

            <div className="button" onClick={handleShopNowClicked}>
              <div className="wide-screen">Shop Now</div>
              <div className="narrow-screen">Shop Mixtiles Art</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
