import * as S from './MixtilesPlusBox.styles'
import { MixtilesPlusData } from './components/MixtilesPlusData/MixtilesPlusData'
import { useAccount } from '../Account/AccountProvider'
import { useState } from 'react'

export function MixtilesPlusBox() {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { account } = useAccount()
  if (!account?.mixtilesPlus?.isMixtilesPlusUser) {
    return null
  }
  return (
    <S.Container>
      <S.StyledBox onClick={() => setIsModalOpen(true)}>
        <S.Logo />
        <S.IconContainer>
          <S.MixtilesPlusIcon />
        </S.IconContainer>
      </S.StyledBox>
      <MixtilesPlusData
        validUntil={account?.mixtilesPlus?.validUntil}
        percentageDiscount={account?.mixtilesPlus?.percentageDiscount}
        onDismiss={() => setIsModalOpen(false)}
        open={isModalOpen}
      />
    </S.Container>
  )
}
