import React from 'react'
import * as S from './LoginSkeleton.styles'
import SkeletonRow from '../../SkeletonRow/SkeletonRow'

export default function LoginSkeleton() {
  return (
    <S.Container>
      <S.Avatar>
        <SkeletonRow width={38} height={38} />
      </S.Avatar>
      <S.Details>
        <SkeletonRow width={73} height={13} />
        <SkeletonRow width={57} height={13} />
      </S.Details>
    </S.Container>
  )
}
