import { useTheme } from 'styled-components'

// A base component for all icons with some common customization options
export function Icon({ SVGComponent, className, color, ...restProps }) {
  const theme = useTheme()

  let defaultStyle = {}
  if (!className) {
    defaultStyle = {
      width: 32,
      height: 32,
      color: color || theme.colors.black,
    }
  }

  return (
    <SVGComponent style={defaultStyle} className={className} {...restProps} />
  )
}

/*
A simple utility for constructing Icon components
 */
export function createIcon(SVGComponent) {
  return function (props) {
    return <Icon SVGComponent={SVGComponent} {...props} />
  }
}
