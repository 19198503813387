export const ILLUSTRATIONS = {
  GIFTING: {
    COUPLE_WITH_GIFT: 'Gifting/Couple_With_Gift',
    ENVELOPE: 'Gifting/Envelope',
  },
  HANGING_PEEL_NO_NAILS: {
    NO_NAILS_01: 'Hangin_Peel_No_Nails/No_Nails_01',
  },
  HAPPY_SATISFACTION: {
    FACE_GIRL_01: 'Happy_Satisfaction/face_girl_01',
  },
  HOMEPAGE: {
    RATING: 'Homepage/Rating',
    SATISFACTION: 'Homepage/Satisfaction',
    SHIPPING: 'Homepage/Shipping',
    SUPPORT: 'Homepage/Support',
  },
  MOMENTS: {
    OKAY_HAND_HAPPY_WATCH: 'Moments/okay_hand_happy_watch',
  },
  SALES: {
    BIG_PERCENTAGE_SIGN_WITH_TWO_LADIES_1:
      'Sales/Big_Percentage_Sign_With_Two_Ladies_1',
  },
  SHIPPING_PACKAGE: {
    PLANE_AND_EARTH_01: 'Shipping_Package/Plane_And_Earth_01',
  },
  MISC: {
    MAN_WITH_EXCLAMATION_MARK: 'Misc/Man_With_Exclamation_Mark_01',
    DROP_FILES: 'Misc/Drop_files',
  },
  PHONE: {
    PHONE_TAP: 'Phone/phone-tap',
  },
  WALL_DESIGNS_TILES: {
    LADY_WITH_4_FRAMES: 'Wall_Designs_Tiles/Lady_With_4_Frames',
    CAPTURE_WALL: 'Wall_Designs_Tiles/Capture_Wall',
  },
}
