import { styled } from 'styled-components'
import { onMediumScreen } from 'mixtiles-web-common'
import { TagPinkIcon, XIcon } from '../../../icons'

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.grayBackgroundWhite};
  height: 100%;

  ${onMediumScreen()} {
    width: 415px;
  }
`

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayMedium};
  ${onMediumScreen()} {
    padding: 20px;
  }
`

export const Label = styled.div`
  font-size: 15px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 6px;
`

export const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.black};
`

export const TitleSnackbar = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 110%;
`

export const SubtitleSnackbar = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 4px;
`

export const TagPinkSnackbarIcon = styled(TagPinkIcon)`
  width: 38px;
  height: 38px;
`

export const CloseButton = styled(XIcon)`
  width: 32px;
  height: 32px;
  cursor: pointer;
`

export const DoneButton = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`
