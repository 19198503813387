import { styled, keyframes } from 'styled-components'

const skeletonAnimation = keyframes`
  0% {
    background-position: -375px 0;
  }
  100% {
    background-position: 375px 0;
  }
`

export const SkeletonRow = styled.div`
  z-index: 1000;
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  animation-duration: 1.1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${skeletonAnimation};
  animation-timing-function: linear;
  background: #ebe7e7;
  background-image: -webkit-gradient(
    linear,
    left center,
    right center,
    from(#ebe7e7),
    color-stop(0.2, #d3cacd),
    color-stop(0.4, #ebe7e7),
    to(#ebe7e7)
  );
  background-image: -webkit-linear-gradient(
    left,
    #ebe7e7 0%,
    #d3cacd 20%,
    #ebe7e7 40%,
    #ebe7e7 60%,
    #ebe7e7 100%
  );
  background-repeat: no-repeat;
  background-size: 900px 104px;
  position: relative;
  border-radius: 3px;
`
