import storageManager from './StorageManager'
import { logger } from './logger'

const OTEF_PROJECT_VIEW_STORAGE_KEY = 'mixtiles-otef-project-view'

function set(isEnabled: boolean) {
  logger.info('storing basic view', isEnabled)
  storageManager.set(OTEF_PROJECT_VIEW_STORAGE_KEY, isEnabled)
}

function isEnabled() {
  return !!storageManager.get(OTEF_PROJECT_VIEW_STORAGE_KEY)
}

export const OtefProjectViewStore = {
  set,
  isEnabled,
}
