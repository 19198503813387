import PaymentManager from '../../services/PaymentManager'
import { savePaymentConfigApi } from '../../api/paymentConfig.api'
import { analytics } from '../../services/Analytics/Analytics'

export default async function syncPayment(api, currentAccount) {
  const localPaymentInfo = PaymentManager.getPaymentInfo()
  if (currentAccount.paymentConfig && !localPaymentInfo?.stripeCustomerId) {
    analytics.track('Restored payment')
    await PaymentManager.loadStripeCustomerFromAccount(currentAccount)
  }

  if (PaymentManager.shouldLoadCardFromStripe()) {
    await PaymentManager.loadCardFromStripe()
  }

  if (
    localPaymentInfo?.stripeCustomerId &&
    (!currentAccount.paymentConfig ||
      currentAccount.paymentConfig.stripeCustomerId !==
        localPaymentInfo.stripeCustomerId)
  ) {
    await api.call(savePaymentConfigApi(localPaymentInfo))
  }
}
