import React from 'react'

function getImageUrl(icon) {
  return new URL(`/src/images/icons/${icon}.svg`, import.meta.url).href
}

function Icon({ icon, ...props }) {
  return <img {...props} src={getImageUrl(icon)} alt="Icon" />
}

export default Icon
