import StorageManager from './StorageManager'
import { getUrlParam } from '../utils/UrlUtils'
import { artOnboardingManager } from '../Art/services/ArtOnboardingManager'
import { PASSED_EMAIL_CAPTURE_SCREEN_KEY } from '../pages/EmailCapturePage/EmailCapturePage.consts'
import { getTiles } from '../pages/PhotoStyler/tilesSaver'
import { isForcePricingGoogle } from './google'
import { getUserEmail } from './userEmail'
import UserManager from './UserManager'
import { didPerformLogin } from './LoginUtills'
import { isClient } from 'utils/runtimeUtils'
import { experimentManager } from './ExperimentManager/ExperimentManager'

const RECAPTURE_ONBOARDING_DAYS = 180

class EmailCaptureManager {
  constructor() {
    if (isClient() && this.shouldRecaptureEmail()) {
      StorageManager.set(PASSED_EMAIL_CAPTURE_SCREEN_KEY, false)
      artOnboardingManager.resetOnboardingStatus()
    }
  }

  /* More logic from EmailCapturePage could be extracted to here. For now just extract what we need for current features */
  shouldCaptureEmail() {
    if (getUrlParam('utm_source') === 'email') {
      StorageManager.set(PASSED_EMAIL_CAPTURE_SCREEN_KEY, true)
      return false
    }

    if (getUrlParam('sms') === '1') {
      StorageManager.set(PASSED_EMAIL_CAPTURE_SCREEN_KEY, true)
      return false
    }

    if (isForcePricingGoogle()) {
      return false
    }

    const shouldCollectEmail = !getUserEmail() || this.shouldRecaptureEmail()
    return (
      getTiles().length === 0 &&
      shouldCollectEmail &&
      !StorageManager.get(PASSED_EMAIL_CAPTURE_SCREEN_KEY)
    )
  }

  // TRUE if passed photos / art onboarding
  passedAnyOnboarding() {
    return (
      experimentManager.isEnabled('straight-to-photos') ||
      !artOnboardingManager.shouldStartOnboardingProcess() ||
      !this.shouldCaptureEmail()
    )
  }

  passedJumbleOnboarding() {
    return (
      !artOnboardingManager.shouldStartOnboardingProcess() ||
      !this.shouldCaptureEmail()
    )
  }

  shouldRecaptureEmail() {
    return (
      UserManager.getDaysSinceLastVisit() > RECAPTURE_ONBOARDING_DAYS &&
      !didPerformLogin()
    )
  }
}

export const emailCaptureManager = new EmailCaptureManager()
