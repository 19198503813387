import {
  type Caption,
  type PagePhoto,
} from '../../components/PhotoBook/PhotoBookProvider/PhotoBook.types'
import { type BookSize } from '@mixtiles/web-backend-shared'

export const SAVE_PHOTO_KEYS = ['uid', 'cropParams', 'dateTaken'] as const

export type SavePhotoKeys = (typeof SAVE_PHOTO_KEYS)[number] // This creates a union type of the array's values

export type StoredPhoto = Pick<PagePhoto, SavePhotoKeys> | null

export type StoredCover = {
  caption: Caption
  photo: StoredPhoto
}

export type StoredPageSide = {
  photos: StoredPhoto[]
  // can be layout, color, caption in the future
}

export type StoredPage = {
  front: StoredPageSide
  back: StoredPageSide
}

export type StoredPhotoBook = {
  pages: StoredPage[]
  cover: StoredCover
  size: BookSize
  quantity?: number
}
