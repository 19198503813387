import { styled } from 'styled-components'

export const Badge = styled.div`
  width: 35px;
  background: rgba(215, 42, 110, 0.2);
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.primaryAccent};
  font-size: 11px;
  font-weight: 700;
  line-height: 16px;
  height: 16px;
  letter-spacing: 0.5px;
  text-align: center;
  margin-left: 6px;
  padding-top: 1px;
`

export default Badge
