import React from 'react'
import * as S from './ActionPopover.styles'
import useIsLargeScreen from '../../hooks/useIsLargeScreen'

export default function ActionPopover({
  visible,
  message,
}: {
  visible: boolean
  message: string
}) {
  const isLargeScreen = useIsLargeScreen()
  return (
    <S.Popover
      open
      $visible={visible}
      $bottom={isLargeScreen ? '250px' : '50%'}
    >
      {message}
    </S.Popover>
  )
}
