import React, { Suspense, useState } from 'react'
import * as S from './SideMenu.styles'
import MixtilesLogo, { LOGO_SRC } from '../../elements/TopBar/Logo/MixtilesLogo'
import MenuItems from './MenuItems/MenuItems'
import MenuFooter from './MenuFooter/MenuFooter'
import LoginSkeleton from '../LoginBox/LoginSkeleton/LoginSkeleton'
import SplashSpinnerOverlay from '../SplashSpinner/SplashSpinnerOverlay'
import { analytics, EVENT_NAMES } from '../../services/Analytics/Analytics'
import LoginBoxWrapper from '../LoginBox/LoginBoxWrapper'
import PromoCodeDialog from '../PromoCodeDialog/PromoCodeDialog'
import CreditBox from '../LoginBox/CreditBox'
import { MixtilesPlusBox } from '../MixtilesPlusBox/MixtilesPlusBox'
import BrowserThemeColor from '../ThemeColor/BrowserThemeColor'
import { useTheme } from 'styled-components'
import { NavDivider } from './Divider.styles'

export default function SideMenu({ open, onDismiss }) {
  const [showPromoCodeDialog, setShowPromoCodeDialog] = useState(false)
  const theme = useTheme()

  const onLoginStart = () => {
    if (window.screen.width < 1440) {
      onDismiss()
    }
  }

  const handlePromoCodeClick = () => {
    setShowPromoCodeDialog(true)
    analytics.track(EVENT_NAMES.CHANGE_PROMO_CODE_TAPPED, {
      Trigger: 'Side Menu',
    })
    onDismiss()
  }

  return (
    <>
      <S.Drawer
        anchor="left"
        open={open}
        onClose={onDismiss}
        // disableEnforceFocus prop is meant to prevent unexpected behavior when something from the drawer pop up as a modal
        // for example PayPal window shown as a modal in 'in-app browsers' like Facebook App - that prevents user to use Paypal modal (cannot type there).
        // keepMounted is used in order to render the menu for SEO
        // disablePortal prevents rendering of the menu at the end of the <body>, so it will be rendered inside the top bar
        ModalProps={{
          disableEnforceFocus: true,
          keepMounted: true,
          disablePortal: true,
        }}
      >
        {open && <BrowserThemeColor color={theme.colors.dialogGray} />}
        <S.NavContainer>
          <S.TopContainer>
            <S.Logo>
              <MixtilesLogo source={LOGO_SRC.MENU} />
            </S.Logo>
            <S.CloseButton onClick={onDismiss} />
          </S.TopContainer>

          <S.MainNavContainer>
            <Suspense fallback={<LoginSkeleton />}>
              <LoginBoxWrapper source="Side Menu" onLoginStart={onLoginStart} />
            </Suspense>
            <NavDivider />
            <S.NavsContainer>
              <MixtilesPlusBox />
              <CreditBox />
              <MenuItems
                onDismissMenu={onDismiss}
                onPromoCodeClick={handlePromoCodeClick}
              />
            </S.NavsContainer>
            <NavDivider />
            <MenuFooter />
          </S.MainNavContainer>
        </S.NavContainer>
      </S.Drawer>
      <Suspense fallback={<SplashSpinnerOverlay />}>
        {showPromoCodeDialog && (
          <PromoCodeDialog
            open={showPromoCodeDialog}
            onDismiss={() => setShowPromoCodeDialog(false)}
            source="Side Menu"
          />
        )}
      </Suspense>
    </>
  )
}
