import { styled } from 'styled-components'
import { ReactComponent as LogoSVG } from '../../images/dark-logo.svg'
import { ReactComponent as MixtilesPlusIconSVG } from '../../icons/assets/mixtiles-plus-plus.svg'

export const Container = styled.div`
  margin-top: -5px;
`

export const StyledBox = styled.div`
  box-sizing: border-box;
  margin: 0 25px;
  padding: 22px 0 22px;
  border-bottom: ${({ theme }) => theme.colors.grayMedium} 1px solid;
  display: flex;
  gap: 4px;
  cursor: pointer;
`

export const Content = styled.div`
  margin-left: 12px;
  margin-top: 1px;
`

export const Text = styled.div`
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.primary};
  margin-left: 16px;
`

export const MixtilesPlusIcon = styled(MixtilesPlusIconSVG)``

export const IconContainer = styled.div`
  display: flex;
  width: 10px;
  height: 10px;
  color: ${({ theme }) => theme.colors.primary};
`

export const Logo = styled(LogoSVG)``
