import type { RemotePhoto } from './PhotoBank.types'
import StorageManager from '../../services/StorageManager'
import { STORAGE_KEY } from './PhotoBank.consts'
import { getVaryingResolutionImageUrls } from '../PhotoWall/cloudinaryTransformations'

export function loadRemotePhotosFromStorage() {
  const initialPhotos: RemotePhoto[] = StorageManager.get(STORAGE_KEY) || []
  return initialPhotos.map((photo) => {
    const { minimalResUrl, lowResUrl, highResUrl } =
      getVaryingResolutionImageUrls({
        url: photo.originalUrl,
        width: photo.width,
        height: photo.height,
      })
    return {
      ...photo,
      minimalResUrl,
      lowResUrl,
      highResUrl,
    }
  })
}
