import * as S from './MixtilesPlusInfo.styles'

interface MixtilesPlusInfoProps {
  imageSrc: string
  text: string
}

export function MixtilesPlusInfo({ imageSrc, text }: MixtilesPlusInfoProps) {
  return (
    <S.Container>
      <S.Image width="140px" src={imageSrc} />
      <S.Text>{text}</S.Text>
    </S.Container>
  )
}
