import * as S from './MixtilesLogo.styles.js'
import { RouteLink } from 'components/RouteLink/RouteLink'
import { analytics, EVENT_NAMES } from '../../../services/Analytics/Analytics'
import { useExperimentManager } from '../../../services/ExperimentManager/ExperimentManager'

export const LOGO_SRC = {
  TOP_BAR: 'Top Bar',
  MENU: 'Menu',
}

export default function MixtilesLogo({
  source = LOGO_SRC.TOP_BAR,
  color,
}: {
  source?: string
  color?: string
}) {
  const experimentManager = useExperimentManager()
  const onLogoTapped = () => {
    analytics.track(EVENT_NAMES.LOGO_TAPPED, {
      Path: window.location.pathname,
      Source: source,
    })
  }

  const isHalloween = experimentManager.isEnabled('halloween')

  const logo = isHalloween ? (
    <S.LogoHalloween $primaryColor={color} $secondaryColor={color} />
  ) : (
    <S.Logo $color={color} />
  )

  return (
    <RouteLink onClick={onLogoTapped} to="/">
      <S.LogoContainer $halloween={isHalloween}>{logo}</S.LogoContainer>
    </RouteLink>
  )
}
