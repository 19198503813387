import { API_METHODS, createApiFunction } from './apiProvider'
import { type OrderDraft } from '../components/Account/types/OrderDraft.types'

const PATH = 'order-drafts'

export const getOrderDraftByOrderId = (orderId: string) =>
  createApiFunction(API_METHODS.GET, PATH, {
    orderId,
  } as any)

export const saveOrderDraftApi = (orderDraft: OrderDraft) =>
  createApiFunction(API_METHODS.POST, PATH, orderDraft as any, true)
