import React, { Component } from 'react'
import './TopBar.scss'
import classNames from 'classnames'
import logo from '../../images/logo.svg'
import darkLogo from '../../images/dark-logo.svg'

// Legacy component for the header of old dialogs
class DialogHeader extends Component {
  shouldMoveTitleToLeft() {
    // if the screen width is less than 400 px &&
    // there are more than 19 chars in the title &&
    // there is something in the right side of the header
    const centerElement = this.props.children || this.props.title
    return (
      window.innerWidth < 380 &&
      centerElement &&
      centerElement.length > 19 &&
      this.props.rightComp
    )
  }

  render() {
    const {
      title,
      children,
      trademark,
      dark,
      morePadding,
      leftComp,
      noBottomMargin,
      invertColors,
      noBottomShadow,
    } = this.props
    const containerClass = classNames('top-bar-container', {
      'no-bottom-margin': noBottomMargin,
      invert: invertColors,
      'no-bottom-shadow': noBottomShadow,
    })
    return (
      <div className={containerClass}>
        <div
          className={classNames('top-bar ', { 'more-padding': morePadding })}
        >
          <div className="left-comp">{leftComp}</div>
          {trademark ? (
            <div className="logo">
              <img
                className={classNames({
                  dark,
                  clickable: !!this.props.logoOnClick,
                })}
                src={dark ? darkLogo : logo}
                alt=""
                onClick={this.props.logoOnClick}
              />
            </div>
          ) : (
            <div
              className={`title ${this.shouldMoveTitleToLeft() ? 'long' : ''}`}
            >
              {children || title}
            </div>
          )}
          <div className="right-comp">{this.props.rightComp}</div>
        </div>
        <div className="bottom-comp">{this.props.bottomComp}</div>
      </div>
    )
  }
}

export default DialogHeader
