export enum PaymentMethodType {
  CREDIT_CARD = 'creditCard',
  PAYPAL = 'payPal',
  APPLE_PAY = 'applePay',
  GOOGLE_PAY = 'googlePay',
  LINK = 'link',
  AFTERPAY = 'afterpay_clearpay',
  AFFIRM = 'affirm',
  KLARNA = 'klarna',
  AMAZON_PAY = 'amazon_pay',
  CASH_APP = 'cashapp',
}
